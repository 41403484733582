button.approve-btn {
    background-color: #05ab18;
    border: none;
    font-size: 11px;
    border-radius: 3px;
    color: #fff;
    padding: 3px 7px 2px;
    margin-top: 0px;
    margin-left: 3px;
}
button.approve-btn:hover, button.approve-btn:focus, button.approve-btn:active {
    box-shadow: none!important;
    outline: 0px!important;
}
a.approve-btn {
    background-color: #97CB5F;
    border: none;
    font-size: 11px;
    border-radius: 3px;
    color: #fff;
    padding: 3px 7px 3px;
    margin-top: 5px;
    margin-left: 3px;
}
a.approve-btn:focus, a.approve-btn:active, a.approve-btn:hover {
    text-decoration: none;
    box-shadow: none;
    outline: 0;
    color: #fff;
    background-color: #97CB5F;
}
a.approve-btn svg {
    margin-right: 3px;
}
.status-orange {
    background-color: #EF5823!important;
    padding: 2px 3px 2px!important;
    width: 80px!important;
}
/*-- vendor table --*/
.vendor-directory-table table thead tr th {
    padding: 5px 13px!important;
}
.vendor-directory-table table tbody tr td {
    padding: 6px 13px!important;
}
.vendor-directory-table table tbody tr td:first-child {
    width: 150px;
    word-break: break-word;
}
@media only screen and (max-width: 1125px){
    .vendor-directory-table table tbody tr td:last-child{
        width: 170px;
    }
}