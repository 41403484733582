.map-view img {
    width: 235px;
    height: 155px;
    margin-bottom: 15px;
    max-width: none;
}
.map-view h6 {
    font-size: 16.7px;
    color: #555759;
    font-weight: 700;
    width: 235px;
    font-family: 'Lato', sans-serif!important;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.map-view p {
    color: #555759;
    width: 235px;
    margin-bottom: 3px;
    font-size: 13px;
}
.mobile-mapview {
    position: relative;
    background: #fff;
    box-shadow: 0 1px 2px 0 rgba(34,36,38,.15);
    margin: 1rem 0;
    padding: 1em;
    border-radius: .28571429rem;
    border: 1px solid rgba(34,36,38,.15);
}
.mobile-mapview, .mobile-mapview div {
    position: initial;
}
@media only screen and (min-width: 320px) and (max-width: 425px) {
    .mobile-mapview {
        height: 400px!important;
    }
}
@media only screen and (min-width: 1200px) and (max-width: 1400px) {
    .mobile-mapview {
        height: 510px!important;
        overflow: hidden;
    }
}
@media only screen and (min-width: 1500px) and (max-width: 1900px) {
    .mobile-mapview {
        height: 600px!important;
        overflow: hidden;
    }
}
@media only screen and (min-width: 1901px) and (max-width: 1920px){
    .mobile-mapview {
        height: 600px!important;
        overflow: hidden;
    }
}
@media only screen and (min-width: 2030px) and (max-width: 2048px){
    .mobile-mapview {
        height: 600px!important;
        overflow: hidden;
    }
}