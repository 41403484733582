.add-listing-news h1 {
        color: #97CB5F;
        font-size: 16px;
        margin-bottom: 22px;
        margin-top: 8px;
}
.add-listing-news form label.add-listing-lable {
    font-weight: 500;
    width: 14%;
    text-align: right;
    margin-right: 9px;
    font-size: 13px;
    margin-bottom: 17px;
}
.add-listing-news form .form-check-input {
    position: absolute;
    margin-top: .2rem;
    margin-left: -1.1rem;
}
.add-listing-news form span {
    margin-right: 15px;
}
.add-listing-news form input.addtiming {
    width: 60px!important;
    text-align: center;
    border-radius: 3px;
}
.add-listing-news form .form-group .form-control, .add-listing-news form .react-datepicker-wrapper .form-control, .add-listing-news form input.addtiming {
    height: calc(1.5em + .65rem + 2px);
    padding: 5px;
    font-size: 14px;
    background-color: #faf9f9;
    border: 1px solid #cacaca;
}
.add-listing-news form .form-group .form-control:focus, .add-listing-news form .react-datepicker-wrapper .form-control:focus, .add-listing-news form input.addtiming:focus {
    box-shadow: none;
    border: 1px solid #97CB5F;
}
.add-listing-news form .form-group {
    width: 85%;
}
.add-listing-news form .tox {
    width: 85%!important;
    height: 300px!important;
    margin-bottom: 17px;
}
.add-listing-news .mce-content-body {
    font-size: 14px!important;
    margin-bottom: 5px!important;
}
.add-listing-news form .filepond--wrapper {
    width: 250px;
    background-color: #fafafa;
    margin-bottom: 17px;
}
.add-listing-news form .filepond--wrapper .filepond--drop-label {
    width: 250px;
    background-color: #fafafa;
    border: 1px solid #ddd;
    border-radius: 4px;
}
.add-listing-news form .filepond--wrapper .filepond--root {
    margin-bottom: 0px;
}
.add-listing-news form .filepond--wrapper .filepond--drop-label label {
    background-color: #fafafa;
    width: 100%;
    padding: 15px;
    display: table;
    cursor: pointer;
}
.add-listing-news form  a.time-type {
    margin-top: auto;
    margin-bottom: 12px;
    margin-left: 10px;
}
.row.news-add-btns {
    margin-left: 13%;
    margin-top: 17px;
    margin-bottom: 15px;
}
.row.news-add-btns button.save-btn, .row.news-add-btns button.sumbit-btn  {
    background-color: #262262;
    padding: 0px 14px;
    border: 1px solid #262262;
    border-radius: 3px;
    color: #ffffff;
    font-size: 14px;
    margin-right: 10px;
    font-weight: 500;
}
.row.news-add-btns button.sumbit-btn {
    background-color: #99c753!important;
    border: 1px solid #99c753!important;
}
.row.news-add-btns button.save-btn:focus, .row.news-add-btns button.save-btn:active, .row.news-add-btns button.save-btn:hover {
    background-color: #99c753;
    border: 1px solid #262262;
    box-shadow: none;
}
.row.news-add-btns button.sumbit-btn:focus, .row.news-add-btns button.sumbit-btn:active, .row.news-add-btns button.sumbit-btn:hover {
    background-color: #262262;
    border: 1px solid #99c753;
    box-shadow: none;
}
.margin-t-1rem {
    margin-top: 12px;
}
/*-- error--*/
.error-addlisting {
    margin-right: auto;
    position: relative;
    top: -10px;
    color: #dc3545;
    font-size: 80%;
    display: block;
    
}
/* -- img -- */
.articel-img-show .image-resize-articeldetails {
    width: 250px;
    height: 150px;
    margin-bottom: 15px;
    margin-right: 15px;
    border: 2px solid #cacaca;
    border-radius: 4px;
}
.articel-img-show .image-resize-articeldetails img {
    width: 100%;
    border-radius: 2px;
    height: 100%;
}
.articel-img-show .crop-img-news {
    background: #efefef;
    padding: 3px 10px;
    border: 1px solid #ddd;
    border-radius: 3px;
    position: relative;
    top: -3px;
    cursor: pointer;
}
.articel-img-show .crop-img-news:hover {
    background: #cfcece;
}
.articel-img-show .crop-img-news p {
    margin-bottom: 0px;
}
.articel-img-show .crop-img-news:focus {
    outline: 0px!important;
}
.articel-img-show .crop-img-news svg {
    margin-right: 5px;
}
button.accept-btn {
    background-color: #EF5823;
    padding: 5.8px 14px;
    border-radius: 2px;
    color: #ffffff;
    font-size: 13.5px;
    margin-top: -2px;
    margin-left: 8px;
    font-weight: 500;
    border: 1px solid #EF5823;
}
button.accept-btn:hover, button.accept-btn:focus, button.accept-btn:active {
    background-color: #EF5823!important;
    border-color: #EF5823!important;
    outline: 0!important;
    box-shadow: none!important;
}
/*-- margins --*/
.add-margin-L16 {
    margin-left: 164px;
}
.add-margin-L15 {
    margin-left: 150px;
}
.add-margin-L140 {
    margin-left: 140px;
}
.tox-statusbar__branding{
    display: none;
}
.add-listing-news .tox .tox-statusbar {
    display: none;
}
.datetime-artical {
    margin-left: 6px;
    width: 18%;
}
.datetime-artical label {
    width: 45%!important;
    margin-bottom: 5px!important;
}
.datetime-artical .error-addlisting {
    top: 5px!important;
    margin-left: 5px;
    margin-right: 0px;
    left: 95px;
}
.datetime-artical input:focus, .datetime-artical input:active, .datetime-artical input:hover {
    border: 1px solid #99c753;
    outline: 0!important;
}
.error-category1 {
    left: -378px!important;
    top: 20px!important;
}
.error-category2 {
    left: 163px!important;
}
@media only screen and (min-width: 1200px) and (max-width: 1300px){
    .error-category2 {
        left: 154px!important;
    }
}
@media only screen and (min-width: 1500px) and (max-width: 1520px){
    .error-category2 {
        left: 185px!important;
    }
    .datetime-artical .error-addlisting {
        top: 5px!important;
        margin-left: 5px;
        margin-right: 0px;
        left: 13px;
    }
    .add-margin-L140 {
        margin-left: 160px;
    }
}
@media only screen and (min-width: 1521px) and (max-width: 1560px){
    .error-category2 {
        left: 188px!important;
    }
    .add-margin-L140 {
        margin-left: 158px;
    }
    .datetime-artical .error-addlisting {
        top: 5px!important;
        margin-left: 5px;
        margin-right: 0px;
        left: 105px;
    }
}
@media only screen and (min-width: 1916px) and (max-width: 1920px){
    .error-category2 {
        left: 241px!important;
    }
    .add-margin-L140 {
        margin-left: 215px;
    }
}
@media only screen and (min-width: 2030px) and (max-width: 2048px){
    .error-category2 {
        left: 262px!important;
    }
    .add-margin-L140 {
        margin-left: 234px;
    }
}
@media only screen and (min-width: 2550px) and (max-width: 2570px){
    .error-category2 {
        left: 325px!important;
    }
    .add-margin-L140 {
        margin-left: 303px;
    }
}
@media only screen and (min-width: 1400px) and (max-width: 1440px){
    .error-category2 {
        left: 15%!important;
    }
    .add-margin-L140 {
        margin-left: 148px;
    }
}
.row-flex-file {
    display: flex;
    position: relative;
    left: 150px;
}
.image-cropper.modal-dialog{
    max-width: 505px;
}
.image-cropper .modal-header{
    padding: 3px 1rem;
}
.image-cropper .modal-body{
    max-height: 500px;
    overflow-y: auto;
}
.image-cropper .modal-body::-webkit-scrollbar {
    width: 12px;
} 
.image-cropper .modal-body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    border-radius: 10px;
} 
.image-cropper .modal-body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}