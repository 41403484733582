.pop-up-model-ContactUs .popup-contactus .form-group input {
    height: 34px;
    background: #f7f5f5;
    border: 1px solid #cccc;
    border-radius: 2px;
    font-size: 14px;
}
.pop-up-model-ContactUs .modal-header {
    padding: 10px 10px 8px;
}
.pop-up-model-ContactUs .modal-header .modal-title {
    color: #97CB5F;
    font-size: 18px;
    font-weight: 600;
}
.pop-up-model-ContactUs .modal-header button.close {
    color: #EF5823;
}
.pop-up-model-ContactUs .modal-header button.close:focus {
    outline: 0px!important;
    box-shadow: none!important;
}
.pop-up-model-ContactUs .modal-body {
    padding: 10px 12px;
}
.pop-up-model-ContactUs .popup-contactus .form-group .invalid-feedback {
    position: absolute!important;
    margin-top: 0px!important;
    font-size: 10px;
}
.pop-up-model-ContactUs .popup-contactus .form-group textarea {
    background: #f7f5f5;
    border: 1px solid #cccc;
    border-radius: 2px;
    font-size: 14px;
}
.pop-up-model-ContactUs .popup-contactus .form-group {
    width: 100%;
    margin-bottom: 10px;
}
.pop-up-model-ContactUs .popup-contactus .form-group label {
    margin-bottom: .5px;
    font-size: 13px;
    color: #565656!important;
}