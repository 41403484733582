.stepper-box .MuiStepIcon-root {
    width: 34px;
    height: 34px;
    color: #fff;
}
.stepper-box .MuiStepLabel-root .MuiStepLabel-alternativeLabel svg.MuiSvgIcon-root {
    width: 32px;
    height: 32px;
    background: #EF5823;
    border-radius: 50%;
    margin: 0;
}
.stepper-box .MuiStepIcon-completed-cx svg text {
    fill: #fff!important;
}
.stepper-box svg text.MuiStepIcon-text {
    font-size: 12px!important;
    font-weight: 600;
    fill: #b2b2b3;
}
.stepper-box .MuiStepConnector-active .MuiStepConnector-lineHorizontal, .stepper-box .MuiStepConnector-completed .MuiStepConnector-lineHorizontal, .landing-popup .MuiStepConnector-active .MuiStepConnector-lineHorizontal, .landing-popup .MuiStepConnector-completed .MuiStepConnector-lineHorizontal {
    border-color: #EF5823;
}
.stepper-box .MuiStepIcon-text {
    fill: #fff;
}
.stepper-box .Mui-disabled .MuiStepLabel-iconContainer svg.MuiSvgIcon-root {
    border: 2px solid #EF5823;
}
.stepper-box svg.MuiStepIcon-active, .stepper-box .MuiStepIcon-root.MuiStepIcon-completed  {
    color: #EF5823;
}
.stepper-box svg.MuiStepIcon-active text {
    font-size: .80rem;
    font-weight: 500;
    fill: #fff;
}
.stepper-box svg text.MuiStepIcon-text:active {
    fill: #fff;
}
.stepper-box svg.MuiStepIcon-active text {
    fill: #fff;
}
.stepper-box svg.MuiStepIcon-active {
    color: #EF5823;
}
.stepper-box .MuiStep-completed svg {
    color: #EF5823!important;
}
.stepper-box .MuiStep-completed svg text.MuiStepIcon-text {
    fill: #fff;
}
.stepper-box .MuiStepConnector-alternativeLabel {
    top: 14px;
    left: calc(-50% + 15.6px);
    right: calc(50% + 15.6px);
    position: absolute;
    z-index: 1;
}
.displayac-container{
    position: relative;
    width: 50%;
}
.displayac-container .acre-sf-error {
    top: 50px;
    left: 0px;
    text-align: right;
}
.display-containersc{
    display: flex;
}
.display-containerc{
    width: 50%;
}
.stepper-box .MuiStepConnector-lineHorizontal {
    border-top-style: solid;
    border-top-width: 6px;
    border-color: rgb(250 112 51 / 32%);
}
.stepper-box .MuiStep-horizontal {
    padding: 0px;
}
.stepper-box .row.border-bottom-stepper {
    border-bottom: 1px solid #eaeaec;
    margin-bottom: 10px;
}
.stepper-box .MuiStepConnector-lineHorizontal {
    z-index: 2;
}
.stepper-box .MuiStepper-root {
    padding: 5px 5px 10px;
}
.stepper-box .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    margin-top: 10px;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
}
.stepper-box .MuiStepLabel-label {
    color: #a5a5a7;
}
/*-- content --*/
.marginall-auto {
    margin: auto;
}
.stepper-content form .inputwidth .form-group {
    width: 100%;
}
.stepper-content form .form-group input.form-control, .stepper-content form .form-group select{
    height: calc(1.5em + .65rem + 2px);
    padding: 5px;
    border-radius: 2px;
    font-size: 14px;
    background-color: #faf9f9;
    border: 1px solid #cacaca;
}
.stepper-content form .form-group select option {
    background-color: rgb(232, 240, 254) !important;
}
.stepper-content form .form-group textarea {
    padding: 5px;
    font-size: 14px;
    background-color: #faf9f9;
    border: 1px solid #cacaca;
}
.height52 {
    height: 52px!important;
}
.stepper-content form .form-group .form-control:focus{
    box-shadow: none;
    border: 1px solid #97CB5F;
}
.stepper-content form .form-group label, .stepper-content label {
    display: inline-block;
    margin-bottom: .5px;
    font-size: 13px;
    font-weight: 500;
}
.row-flex {
    display: flex;
    margin: 26px 20px 0;
}
.row-flex label {
    position: relative;
    left: -14px;
}
.row-flex p, .row-flex2 span {
    font-size: 13px;
    color: #4c4c4c;
}
.row-flex2 span {
    font-weight: 400;
    padding-left: 5px;
    position: relative;
    top: -1.5px;
}
.row-flex .form-group label {
    color: #4c4c4c;
    font-weight: 400!important;
}
.row-flex2 {
    display: flex;
    margin-bottom: 10px;
}
.row-flex2 .col-md-3, .row-flex .col-md-3 {
    padding: 0px;
}
.stepper-content .row-flex .form-check-input {
        margin-top: .1rem;
        left: 0px;
    top: 2px;
}
.margin-r15 {
    margin-right: 10px;
}
.property-img-admin {
    display: flex;
    margin: 8px 0px;
    position: relative;
}
.property-img-admin .errorMessage
{
    top: 26px;
}
.property-img-admin .display-size {
    height: 80px;
    margin-bottom: 15px;
}
.property-img-admin .display-size img {
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid #dddddd;
}

.or-span-position {
    position: absolute;
    left: 100px;
    color: #4c4c4c;
}
.email-position {
    font-size: 13px;
    color: #565656;
    display: block;
    position: relative;
    left: -110px;
}
.error-top-position {
    top: 122px;
}
.error-top-position2 {
    top: 212px;
}
.error-top-position3 {
    top: 50px;
}
.error-bottom-position {
    top: 49px;
}
.error-bottom-position-4 {
    top: 50px;
    width: max-content;
    left: 16px;
    display: block;
    margin: 0px;
}
.error-bottom-position-6 {
    top: 475px;
    width: max-content;
    left: 121px;
    display: block;
    margin: 0px;
}
.error-bottom-position-7 {
    top: 405px;
    width: max-content;
    left: 121px;
    display: block;
    margin: 0px;
}
/* amenities */
.select-amenities-search.search-resultdropdown.location-search-head.select-new-amenities .dropdown button{
    width: 100%;
    text-align: left;
    height: calc(1.5em + 0.65rem + 2px)!important;
    padding: 5px;
    border-radius: 2px;
    font-weight: normal;
    font-size: 14px !important;
    background-color: #faf9f9;
    border: 1px solid #cacaca;
    box-shadow: none;
    
}
.checked-close.amenities-close .closed-x
{
    padding: 5px;
    width: 20px;
}
.Room-Expansion-new
{
    padding-top: 29px;
}
.stepper-content.property-content .errorMessage.error-top-position
{
    position: relative;
    top: -16px;
}
.acre-sf-error
{
    left: 209px;
}

.cards-new-fiels label
{
    padding: 10px 10px 0px 0px;
    width: 100%;
}
.cards-new-fiels #field-wrapper label
{
    width: auto;
    padding: 0px;
}


/*-- side lable --*/
span.extra-lable {
    background-color: #979797;
    padding: 7px 10px;
    font-size: 15px;
    height: 34px;
    margin-top: 20.5px;
    margin-bottom: 16px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    color: #ffffff;
}
.input-field .form-group input.form-control {
    border-right: none!important;
    border-top-right-radius: 1px;
    border-bottom-right-radius: 1px;
}
.display-acres-lot {
    display: flex;
    position: absolute;
    top: 25px;
    right: 68px;
}
.display-acres-lot .form-group, .display-acres-lot2 .form-group {
    margin: 0px;
}
.display-acres-lot span, .display-acres-lot2 span  {
    position: relative;
    top: 2px;
}
.display-acres-lot2 {
    display: flex;
    position: absolute;
    top: 25px;
    right: 17px;
 }
input.input-size {
    width: 160px;
}
.room-of-expansion {
    margin-left: 5px;
    position: relative;
    top: -2px;
}
.acres1 {
    position: relative;
    top: 2px;
    cursor: pointer;
}
.auction-position {
    display: flex;
    position: absolute;
    top: 29px;
    left: 75px;
    cursor: pointer;
}
.auction-date {
    position: relative;
    top: -9px;
    height: fit-content;
    z-index: 1;
    cursor: pointer;
}
.auction-date .react-datepicker-wrapper {
    width: 100%;
}
.auction-date .errorMessage  {
    position: relative;
    top: -2px;
}
.auction-date input {
    height: calc(1.5em + .65rem + 2px);
    padding: 5px;
    border-radius: 2px;
    font-size: 14px;
    background-color: #faf9f9;
    border: 1px solid #cacaca;
    width: 100%;
}
.description-stepper .form-group textarea {
    height: 135px;
}
.tag-broker-table table {
    margin-bottom: 20px;
    margin-top: 15px;
}
.tag-broker-table thead tr th:nth-child(2) {
    padding-left: 7px!important;
}
.tag-broker-table thead tr th:nth-child(3) {
    padding-left: 1px!important;
}
.tag-broker-table2 table {
    margin-bottom: 20px;
    margin-top: 15px;
}
.tag-broker-table2 thead tr th:nth-child(2) {
    padding-left: 13px!important;
}
.tag-broker-table2 thead tr th:nth-child(3) {
    padding-left: 12px!important;
}
.property-save-add {
    text-align: center;
    margin: 26px 0px;
    font-size: 15px;
    background-color: rgb(220 247 179 / 28%);
    padding: 12px;
    border-radius: 3px;
    color: #4c4c4c;
    font-weight: 500;
    border: 1px solid #94c44b;
}
p.font-italic a {
    font-style: italic;
    padding-left: 4px;
}
.error-tag-select-broker {
    top: 121px;
    left: 16px;
}
.error-tag-select-broker2 {
    top: 140px;
    left: 30px;
}
.sale-position .form-group, .auction-position .form-group {
    margin: 0px;
}
.sale-position {
    display: flex;
    position: absolute;
    top: 29px;
}
.price-error {
    top: 50px;
    width: max-content;
}
.sales-error {
    top: 20px;
    left: -80px;
    width: max-content;
}
.review-listing {
    margin-top: 15px;
}
.review-listing a {
    font-style: italic;
    padding-left: 10px;
}
.new-c-card {
    border-top: 1px solid #ddd;
    margin-bottom: -12px;
    margin-top: 12px;
}
.new-c-card input {
    position: relative;
    left: -38px;
}
.new-c-card .form-group {
    margin-bottom: 8px;
}
.new-c-card label {
    position: relative;
    left: 17px;
    top: -2px;
}
.new-c-card2 {
    width: 100%;
    margin: auto!important;
    background: #e8e7e778;
    padding-top: 11px;
    padding-bottom: 28px;
    border-radius: 3px;
    height: 30px;
}
.new-c-card2 .set-btm-mr {
    position: relative;
    left: 184px;
    top: -50px;
    width: 100%;
    margin: auto;
}
.new-c-card2 .col-sm-12 .set-btm-mr .col-sm-2 .form-group input {
    height: calc(1.32em + .65rem + 2px)!important;
}
.new-c-card2 .set-btm-mr .col-sm-2 .form-group .price-error2 {
    font-size: 10px!important;
    position: relative;
    top: -5px!important;
}
.new-c-card2 .set-btm-mr .col-sm-2 .form-group input::placeholder {
    font-size: 14px;
}
.new-c-card2 .col-sm-12 label span svg {
    margin-left: 2px;
    margin-right: 3px;
    width: 14px!important;
}
.new-c-card2 .set-btm-mr .col-sm-2 .price-error2{
    font-size: 10px!important;
    top: -5px!important;
    left: 8px!important;
    width: 200px!important;
    display: block;
}
.card-frame form .new-c-card2:last-child {
    margin-bottom: 15px!important;
}
.new-c-card2 input {
    position: relative;
    left: 7px;
}
.new-c-card2 span {
    position: relative;
    top: -22px;
    left: 6px;
    font-size: 14px;
}
.new-c-card2 p {
    padding-left: 15px;
    margin-bottom: 2px;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    color: #7e7e86;
}
.new-c-card2 .form-group {
    margin-bottom: 0px;
}
.new-c-card2 label {
    position: relative;
    left: 17px;
    top: -2px;
    color: #7e7e86;
    height: 21px;
    cursor: pointer!important;
}
.card-frame {
    border: 1px solid #ddd;
    border-radius: 3px;
    padding: 10px 15px;
    margin-bottom: 12px;
    margin-top: 11px;
}
.card-frame .row.classrow {
    width: 100%;
    margin: auto;
}
.card-frame .row.classrow .col-sm-6.noterm .form-group {
    margin: 0px;
}
.card-frame .row.classrow .col-sm-6.noterm label {
    position: relative;
    left: 10px;
    top: 23px;
}
.card-frame .row.classrow .col-sm-6.noterm label span {
    position: relative;
    top: 2px;
}
.newcardstepper {
    margin-top: 10px;
}
.newcardstepper .form-group {
    margin: 0px;
}
.newcardstepper .form-group input {
        position: relative;
        left: 7px;
}
.newcardstepper label {
    position: relative;
    left: 17px;
    top: -2px;
}
.newcardstepper label span {
    position: relative;
    top: -20px;
    left: 8px;
}
.savedcardheading {
    padding-left: 5px;
    margin-bottom: 7px;
}
.card-fields {
    margin-bottom: 10px;
}
.card-fields .lable div.sc-bdnylx:first-child {
    width: 100%;
}
.card-fields .jPPSIw {
    display: block;
}
.card-fields #field-wrapper {
    padding: 7.6px;
    border: 1px solid #cacaca;
}

.card-fields .cards-new-fiels div
{
    width: 100%!important;
}
.price-error2 {
    top: 50px;
    width: max-content;
}
.card-fields .sc-bdnylx {
    display: block!important;
    width: 100%!important;
}
.card-fields .price-error2 {
    margin-top: 5px!important;
    display: block!important;
}
.card-fields .kRJgNP, .card-fields p {
    position: relative;
    top: 8px;
    font-size: 11px;
    color: #dc3545;
}
.pricestepper .col-sm-2 {
    flex: 0 0 9.666667%!important;
    max-width: 9.666667%!important;
    padding-right: 0px!important;
}
.pricestepper .col-sm-4 {
    flex: 0 0 40.333333%!important;
    max-width: 40.333333%!important;
    padding-left: 5px!important;
}
/*-- popup --*/
.vendor2-assign-table .modal-content .modal-header h5 {
    color: #262262;
    font-size: 16px;
}
.vendor2-assign-table .modal-body {
    padding: 0px;
    height: 550px;
    overflow-y: scroll;
}
.vendor2-assign-table .modal-content .modal-header {
    padding: 12px 15px;
}
.vendor2-assign-table button.close:focus, .vendor2-assign-table button.close:active, .vendor2-assign-table button.close:hover  {
    border: none;
    outline: 0;
    box-shadow: none;
}
@media (min-width: 576px) {
.vendor2-assign-table.modal-dialog {
    max-width: 1087px;
    margin: 1.75rem 36px 1.75rem auto;
}
}
button.padding-button {
    padding: 5.5px 8px!important;
    margin-right: 0px!important;
    border-radius: 2px!important;
}
button.approved-btn {
    margin-right: 0px;
    background-color: #97CB5F;
    padding: 5px 12px 5px 10px;
    font-size: 13px;
    border-radius: 3px;
    border: 1px solid #97CB5F;
}
button.approved-btn svg {
    margin-right: 5px;
}
button.approved-btn:hover, button.approved-btn:focus, button.approved-btn:active {
    background-color: #97CB5F!important;
    border: 1px solid #97CB5F!important;
    outline: 0!important;
    box-shadow: none!important;
}
.review-box .list .list-item {
    display: inline-flex;
    table-layout: fixed;
    list-style-type: none;
    list-style-position: outside;
    padding: .21428571em 0;
    line-height: 1.14285714em;
    padding-bottom: 8px;
    color: #58585a;
}
.review-box .list .list-item p.width-length{
    color: #58585a;
    width: 100px!important;
    display: block;
}
.review-box .list .list-item p {
    line-height: 20px;
    margin-top: 10px;
    width: 521px;
}
.review-box a{
    color: #97CB5F;
    text-decoration: none;
}
.review-box a:hover{
    color: #EF5823;
    text-decoration: underline;
}
.description-content .tox-tinymce {
    width: 100%!important;
    margin-bottom: 10px;
}
.description-content .tox .tox-statusbar {
    display: none;
}

@media only screen and (min-width: 1270px) and (max-width: 1289px){
    .row-flex {
        display: flex;
        margin: 26px 17px 0!important;
    }
}
@media only screen and (min-width: 1290px) and (max-width: 1310px){    
    .vendor2-assign-table.modal-dialog {
        max-width: 1029px;
    }
}
@media only screen and (min-width: 1500px) and (max-width: 1550px){
.vendor2-assign-table.modal-dialog {
    max-width: 1087px;
    margin: 1.75rem 124px 1.75rem auto;
}
}
.drop-file-select {
    background: #565656;
    padding: 3px 10px;
    border: 1px solid #565656;
    border-radius: 3px;
    position: relative;
    top: -3px;
    color: #ffffff;
    cursor: pointer;
    width: max-content;
}
.drop-file-select p {
    margin-bottom: 0px;
}
.drop-file-select:hover {
    background: #979797;
    border: 1px solid #979797;
}
.drop-file-select:focus {
    outline: 0px!important;
}
.drop-file-select svg {
    margin-right: 5px;
}
.noicurr{
    margin-top:18px;
}
.margin-t20 {
    margin-top: 20px;
}
.color-link {
    color: #97CB5F;
}
.color-link:hover {
    color: #0056b3;
    text-decoration: underline;
}
.image-cropper.modal-dialog{
    max-width: 890px;
}
.image-cropper .modal-header{
    padding: 3px 1rem;
}
.image-cropper .modal-body{
    max-height: 500px;
    overflow-y: auto;
}
.image-cropper .modal-body::-webkit-scrollbar {
    width: 12px;
} 
.image-cropper .modal-body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    border-radius: 10px;
} 
.image-cropper .modal-body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}
@media only screen and (max-width: 1250px){
    input.input-size{
        width: 110px;
    }
}
@media only screen and (min-width: 576px){
    body .vendor-profile-width{
        flex: 0 0 66.333333%;
        max-width: 66.333333%;
    }
}
.vendor-profile-width .co-broker.vendor-popup-table thead tr th:first-child, 
.vendor-profile-width .co-broker.vendor-popup-table tbody tr td:first-child{
    width: 20%;
}
.vendor-profile-width .co-broker.vendor-popup-table thead tr th:nth-child(2),
.vendor-profile-width .co-broker.vendor-popup-table tbody tr td:nth-child(2){
    width: 40%;
}
.vendor-profile-width .co-broker.vendor-popup-table thead tr th:last-child,
.vendor-profile-width .co-broker.vendor-popup-table tbody tr td:last-child{
    width: 15%;
}
.vendor-profile-width .co-broker.vendor-popup-table thead tr th{
    padding: 4px 6px!important;
}
.co-broker.vendor-popup-table tbody{
    display: table-row-group;
}
.cobroker-top-btn{
    margin-top: -15px;
    margin-bottom: 10px;
}
.cobroker-top-btn .edits-btn{
    background-color: #97CB5F !important;
    border: 1px solid #97CB5F !important;
    margin-left: 0px;
    margin-right: 0px;
}
.cobroker-top-btn .resets-btn{
    margin-right: 0 !important;
}
.co-broker.vendor-popup-table tbody tr td .edit-link{
    margin-right: 7px;
}
.co-broker-search .error-top-position{
    top: auto;
    bottom: 0px;
}
.cobroker-top-btn .search-btn{
    padding: 6px 14px!important;
}
.co-broker.vendor-popup-table .no-records-found{
    font-size: 14px !important;
}
.co-broker .slide-button .react-switch-handle{
    width: 22px!important;
}
.co-broker .slide-button .react-switch-bg{
    width: 52px!important;
}
select.disabled-select{
    border: none !important;
    background-image: none !important;
    cursor: default !important;
    pointer-events: none;
    padding: 0px !important;
    height: auto !important;
    line-height: 18px !important;
    margin-top: 5px;
    background-color: #fff !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  input.disabled-select{
    border: none !important;
    cursor: default !important;
    pointer-events: none;
    padding: 0px !important;
    height: auto !important;
    line-height: 18px !important;
    margin-top: 5px;
    background-color: #fff !important;
  }
  .savedcardheading.billing-address-heading{
    font-weight: 500;
    font-size: 16px;
    padding-left: 0;
    display: inline-block;
  }
  .add_edit_btn.btn{
    background: none;
    border: 1px solid #97cb5f;
    color: #97cb5f;
    font-weight: 500;
    font-size: 13px;
    padding: 3px 10px 2px 10px;
    margin-left: 10px;
    margin-top: -3px;
    outline: none !important;
    box-shadow: none !important;
  }
  .add_edit_btn.btn:hover, .add_edit_btn.btn:active,.add_edit_btn.btn:focus
  {
  background-color: #97cb5f !important;
    color: #fff;
    border: 1px solid #97cb5f;
    border-color: #97cb5f !important;
    text-decoration: none;
}
  .add_edit_popup_body .radio-btns .edit-address, .add_edit_popup_body .radio-btns .remove-address {
    background: none!important;
    border: none!important;
    font-size: 13px;
    text-transform: capitalize;
    padding: 0;
    float: none;
    outline: none !important;
    box-shadow: none !important;
}
.add_edit_popup_body .radio-btns .edit-address {
    color: #97CB5F!important;
    margin-right: 10px;
}
.add_edit_popup_body .radio-btns .btns-btm .edit-address svg {
    color: #97CB5F!important;
    width: 14px;
    height: 14px;
    margin-right: 5px;
}
.add_edit_popup_body .radio-btns .remove-address {
    color: #f15d47!important;
}
.add_edit_popup_body .radio-btns .btns-btm .remove-address svg {
    color: #f15d47!important;
    width: 14px;
    height: 14px;
    margin-right: 5px;
}
.add_edit_popup_body .radio-btns .remove-address:before {
    content: "";
    width: 2px;
    height: 13px;
    background-color: #999797;
    display: inline-block;
    margin-right: 10px;
    position: relative;
    top: 2px;
}
.add_edit_popup_body .radio-btns .radio_cls .MuiTypography-body1 {
    font-size: 13px;
}
.add_edit_popup_body .radio-btns .radio_cls .MuiRadio-root {
    padding: 6px;
    margin-top: -4px;
}
.add_edit_popup_body .radio-btns .radio_cls svg:first-child{
    color: #007bff;
}
.add_edit_popup_body .radio-btns .radio_cls svg {
    width: 17px;
    height: 17px;
    margin-top: -1px;
    color: #007bff;
 
}
.add_edit_popup_body .radio-btns .radio_cls {
    margin-bottom: 0;
    -webkit-align-items: start;
    align-items: start;
    vertical-align: top;
    margin-left: -7px;
}
.add_edit_popup_body .btns-btm {
    margin-bottom: 5px;
    margin-left: 23px;
}
.add_edit_popup_body .radio-btns {
    margin-bottom: 5px;
}
.add_edit_popup_body .footer-btns-address {
    margin-top: 10px;
}
.add_edit_popup_body .footer-btns-address .add-new-address-btn {
    padding: 5px 30px 7px 30px;
    font-weight: 500;
    font-size: 14px;
    text-transform: none;
    background: #fff!important;
    color: #313232!important;
    border: 1px solid #313232;
    outline: none !important;
    box-shadow: none !important;
}
.add_edit_popup_body .footer-btns-address .select-address-btn {
    padding: 5px 30px 7px 30px;
    font-weight: 500;
    font-size: 14px;
    text-transform: none;
    background: #97cb5f!important;
    color: #fff;
    border: 1px solid #97cb5f!important;
    outline: none !important;
    box-shadow: none !important;
}
.custom-popup .modal-header{
    border-bottom: none;
    padding-bottom: 0;
}
.add-or-edit.enter-acc-details .form-group{
    margin-bottom: 7px;
}
.add-edit-popup-btn.save-btn {
    margin-bottom: 0;
    border: 1px solid #EF5823 !important;
    border-radius: 2px;
    background: #EF5823;
    width: auto;
    padding: 0 25px;
    text-transform: none;
    height: 33px;
    line-height: 30px;
    font-size: 14px;
    float: right;
    margin-right: 0;
    font-weight: 500;
    margin-top: 10px;
    outline: none !important;
    box-shadow: none !important;
}
.add-edit-popup-btn.cancel-btn {
    margin-bottom: 0;
    float: right;
    border-radius: 2px;
    color: #04d816!important;
    background: none!important;
    padding: 0;
    text-transform: none;
    height: 30px;
    line-height: 30px;
    font-size: 12px;
    width: auto;
    border: none;
    text-decoration: underline !important;
    margin-right: 15px;
    margin-top: 10px;
    outline: none !important;
    box-shadow: none !important;
}
.form-control:focus
{
    border-color: #97CB5F !important;
    box-shadow:none !important;
}
.add_edit_popup_body .ld_fs{
    max-height: calc(100vh - 170px);
    overflow-y: auto;
}