@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
@media (min-width: 576px) {
.modal-dialog.popup-login {
    max-width: 380px!important;
    margin: 1.75rem auto;
}
}
.head-login-btn
{
    position: relative;
}
.head-login-btn .btn-secondary, .head-login-btn .btn-secondary:hover, .head-login-btn .btn-secondary:focus, .head-login-btn .btn-secondary:active, .head-login-btn span, .head-login-btn span:hover, .head-login-btn span:focus, .head-login-btn span:active
{
    background-color: #01890a;
    cursor: pointer;
    border: 0px;
    border-radius: 5px !important;
    border-right: 1px solid rgba(0,0,0,.1);
    bottom: 3px;
    color: #fff;
    font-size: 12px;
    margin-top: -6px;
    padding: 10px 20px;
    position: relative;
    width: auto;
    z-index: 1051;
    box-shadow:none !important;
}
.br-ex-dropdown.dropdown-menu
{
    transform: translate3d(-110px, 40px, 0px) !important;
    border: 0px;
    filter: drop-shadow(rgba(0, 0, 0, 0.32) 0px 2px 8px);
}
.br-ex-dropdown.dropdown-menu::before {
    content: "";
    display: block;
    position: absolute;
    top: 0px;
    right: 14px;
    width: 10px;
    height: 10px;
    background-color: rgb(255, 255, 255);
    transform: translateY(-50%) rotate(45deg);
    z-index: 0;
}
.br-ex-dropdown .dropdown-item
{
    padding: 0px;
}
.br-ex-dropdown .dropdown-divider
{
    margin: 0px;
}
 .broker-login a{
    width: 100%;
    height: auto;
    padding: 6px 16px;
    display: inline-block;
    font-size: 12px;
    color: #01890a; 
    position: relative;
   
}
 .broker-login a img{
    position: relative;
    top: -2px;

}
.broker-login a:hover{
    font-size: 12px;
    color: #01890a; 
    background-color: rgba(0, 0, 0, 0.04);
    text-decoration: none;

}
.broker-login img,.explorer-login img
{
    width: 22px;
    margin-right: 5px;
}
 .explorer-login a{
    width: 100%;
    height: auto;
    padding: 6px 16px;
    display: inline-block;
    font-size: 12px;
    color: #EF5823; 
    position: relative;
}
 .explorer-login a img{
    position: relative;
    top: -2px;

}
 .explorer-login a:hover{
    font-size: 12px;
    color: #EF5823; 
    background-color: rgba(0, 0, 0, 0.04);
    text-decoration: none;

}
.popup-login .modal-header {
    display: none;
}
.popup-login .login-lss {
    padding: 15px 20px ;
}
.popup-login p {
    text-align: center;
    font-size: 14px;
    color: #7c7c7c;
}
.popup-login h4 {
    text-align: center;
    color: #EF5823;
    font-weight: bolder;
    font-size: 1.8em;
    text-transform: uppercase;
    margin-bottom: 14px;
    font-family: "Lato"!important;
}
.popup-login h5 {
    text-align: center;
    color: #565656;
    font-weight: 600;
    margin-bottom: 20px;
    font-size: 19px;
}
.text-skip {
    text-align: center;
    margin-bottom: 14px;
}
.text-skip span {
    color: #97CB5F;
    font-size: 16px;
    font-style: italic;
    font-family: "Lato"!important;
}
.text-skip span:hover {
    color: #002d73;
    text-decoration: underline;
}
.text-skip span svg {
    margin-left: 2px;
    width: 11px!important;
    position: relative;
    top: 1px;
}
.popup-login .form-control {
    font-size: 14px;
    background-color: #f6f6f6;
    padding: 18px;
}
.popup-login .form-control:focus, .basic-details .form-control:focus {
    border-color: #97cb5f;
    box-shadow: none;
    outline: none;
}
.popup-login .form-control.is-invalid:focus, .popup-login .form-control:invalid:focus {
    box-shadow: none!important;
}
.login-close
{
    position: relative;
}

.login-close .login-x-close
{
    position: absolute;
    right: -20px;
    top: -20px;
    font-size: 20px;
    cursor: pointer;
    color: #565656;
    font-weight: bold;
    font-family: 'Roboto';
}
.basic-details
.popup-login .form-group .invalid-feedback {
    position: absolute;
    font-size: 10px;
    margin: 0px;
}
.popup-login .signin-blue {
    width: 100%;
    padding: 7px;
    background-color: #97CB5F;
    border: none;
}
.popup-login .signin-blue:hover, .popup-login .signin-blue:focus {
    box-shadow: none!important;
    background-color: #97CB5F!important;
    color: #ffffff;
    text-decoration: none;
}
.popup-login .text-right {
    float: right;
}
.popup-login a, .popup-login a:hover
{
    color:#97CB5F ;
}
.popup-login .text-left {
    float: left;
}

.home-content,#app
{
    background-color: #fff;
}
.fontsize-15 {
    font-size: 15px;
}
.font-color {
    color: #7c7c7c;
}
.popup-login .margin-b15 {
    margin-bottom: 10px;
    display: flow-root;
}
.line-hr {
    display: table;
    white-space: nowrap;
    height: auto;
    line-height: 1;
    text-align: center;
    font-size: 1rem;
    margin: auto;
}
.line-hr:after, .line-hr:before {
    content: "";
    display: table-cell;
    position: relative;
    top: 50%;
    width: 50%;
    background-repeat: no-repeat;
}
.line-hr:after, .line-hr:before {
    background-image: url('../../Assets/Img/line-hr.png');
}
.line-hr:before {
    background-position: right 1em top 50%;
}
.line-hr:after {
    background-position: left 1em top 50%;
}
h4.fontsize-20 {
    font-size: 19px;
    font-weight: bolder;
}
.popup-login h6 {
    text-align: center;
    color: #4c4c4c;
}
.sign-error {
    background-color: #fff6f6;
    color: #9f3a38;
    text-align: center;
    font-size: 14px;
    padding: 7px;
    margin-top: 7px;
    border: 1px solid #9f3a38;
    border-radius: 3px;
    display: block;
}
.success {
    background-color: #f6fff8;
    color: #389f41;
    text-align: center;
    font-size: 14px;
    padding: 7px;
    margin-top: 7px;
    border: 1px solid #389f41;
    border-radius: 4px;
    display: block;
}
.header-top-bar {
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: inline-block;
    padding: 0 15px 0px 6px;
    background-color: #ffffff;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.header-mobileno {
    float: left;
    position: relative;
    list-style: none;
    margin: 0;
    font-size: 13px;
    padding: 10px 10px;
}
.phone-no {
    margin-right: 20px;
}
.phone-no a {
    color: #212121;
    cursor: pointer;
}
.phone-no a:focus, .phone-no a:active, .phone-no a:hover {
    text-decoration: none;
    color: #212121;
}
.header-top-bar-social {
    float: right;
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0;
}
.header-top-bar-social li {
    float: left;
    position: relative;
}
.header-top-bar-social li a {
    float: left;
    color: #565656;
    text-decoration: none;
    margin: 0;
    padding: 13px 0;
    width: 40px;
    text-align: center;
    text-transform: capitalize;
    font-size: 13px;
    line-height: 1;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
}
.header-top-bar-social li.navlast span {
    float: left;
    color: #565656;
    text-decoration: none;
    margin: 0;
    text-align: center;
    text-transform: capitalize;
    font-size: 13px;
    line-height: 1;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
    border-right: 1px solid rgba(0,0,0,.1);
    width: auto;
    font-weight: 400;
    padding: 13.5px 10px;
}
.header-top-bar-social li:last-child a {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    width: auto;
    font-weight: 400;
    padding: 13.5px 10px;
}
.header-top-bar-social li a:hover,
.header-top-bar-social li.navlast span:hover {
    background: #565656;
    color: #fff;
}
.header-top-bar-social li a span {
    font-weight: 700;
}
.listrvparks .menu-bar {
    position: absolute;
    z-index: 100;
    padding: 1rem;
    padding-bottom: 13px;
    background: #fff;
    width: 100%;
    border-bottom: 3px solid #94C75E;
}
.menu-bar .navbar {  
    padding: 10px 16px;
    background-color: transparent!important;
}
.logo-100 {
    width: 240px;
}
.menu-bar .logo-lss {
    width: 100%;
}
.menu-bar .mr-auto {
    margin-left: auto;
    margin-right: 0px!important;
}
.menu-bar li.nav-item .nav-link {
    padding: 0px;
}
.menu-bar .navbar-nav li.nav-item:last-child{
    padding-right: 0px;
}
.menu-bar li.nav-item a,
.menu-bar li.nav-item.navhead-menu span {
    font-family: Lato,Helvetica Neue,Arial,Helvetica,sans-serif!important;
    /* font-weight: 400; */
    font-size: 14px;
    /* color: #000000de!important; */
    cursor: pointer;
}
.menu-bar li.nav-item {
    padding-right: 2rem;
    color: #000000de!important;
}
.menu-bar li.nav-item a.headeractive:hover{
    color: #94C75E;
}
.listrvparks .navbar-nav .nav-item a.headeractive{
    color:#94C75E !important;
}
.listrvparks .navbar-nav .nav-item a{
    color: #000;
}
.listrvparks .navbar-nav .nav-item a:hover{
    color:#94C75E !important; 
    position: relative;
}
.listrvparks .navbar-nav .nav-item a:hover::after{
    content: '';
        display: inline-block;
        background: #94C75E;
        height: 8px;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0px;
        margin-bottom: 5px;
        top: 54px;
        transform: translateY(-3px);
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
        box-shadow: 0px -3px 18px -1px rgba(255,145,78,1);
}
.listrvparks .head-login-btn span{
    background:#94C75E ;
}
.headeractive {
    position: relative;
    color: #94C75E;
    font-weight: 600;
}

.headeractive::after {
    content: '';
        display: inline-block;
        background: #94C75E;
        height: 8px;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0px;
        margin-bottom: 5px;
        top: 54px;
        transform: translateY(-3px);
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
        box-shadow: 0px -3px 18px -1px rgba(255,145,78,1);
}
.menu-bar li.nav-item a:hover, .menu-bar li.nav-item a:focus, .menu-bar li.nav-item a:active{
    color: rgba(0,0,0,.87)!important;
    text-decoration: none;
}
.menu-bar li.nav-item a.remove-right-padding {
    padding-right: 0px;
}
.custom-control-label::before {
    top: .10rem!important;
}
.custom-checkbox .custom-control-label::before {
    border-radius: .15rem!important;
}
.custom-control-label::after {
    top: .10rem!important;
}
.header-top-bar-social li a.padding-flag {
    padding: 9px 8px 7px!important;
}
@media only screen and (min-width: 280px) and (max-width: 479px) {
    .header-top-bar-social li a {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        width: 32px;
        font-size: 10px;
    }
    .header-top-bar-social li a.padding-flag {
        padding: 6px 3px 6px!important
    }
    .header-top-bar {
        padding: 0px;
    }
    .menu-bar .navbar {
        padding: 10px;
        z-index: 1;
    }
    .header-mobileno {
        display: none;
    }
    .menu-bar li.nav-item:nth-child(1) {
        margin-top: 8px;
    }
    .menu-bar li.nav-item {
        padding-top: 10px;
        padding-bottom: 5px;
        padding-left: 15px;
    }
    .headeractive::after {
        top: 18px;
        max-width: 27%;
        display: block;
    }
    .menu-bar .mr-auto {
        margin-top: 3px!important;
    }
    .menu-bar li.nav-item {
        margin-bottom: 3px!important;
    }
}
@media only screen and (min-width: 480px) and (max-width: 767px){
    .header-mobileno {
        display: none;
    }
    .menu-bar .navbar {
        padding: 10px 20px;
    }
    .header-top-bar {
        padding: 0px 20px;
    }
    .menu-bar .mr-auto {
        margin-top: 10px!important;
    }
    .menu-bar li.nav-item {
        margin-bottom: 10px!important;
    }
 }
 .margin-17t {
    margin-top: -17px;
 }
 @media only screen and (min-width: 768px) and (max-width: 1150px){
    .desktop-insight-details .button-with-icon{
        margin-top: 10px;
    }
    .property-address-right table{
        margin-bottom: 15px;
    }
    .property-address-right table tr td:first-child,
    .property-address-right table tr td:nth-child(2){
        padding-bottom: 5px;
    }
    .property-address-right table td{
        width: 50%;
        display: block;
        float: left;
    }
    body .property-address-right .updated-col{
        border-left: none;
        padding-left: 0px;
    }
    .property-table-layout .col-md-6 {
        flex: 0 0 100%;
        max-width: 100%;
    }
 }

 @media (max-width: 767px){
 .br-ex-dropdown.dropdown-menu
{
    transform: translate3d(0px, 40px, 0px) !important;

}
.br-ex-dropdown.dropdown-menu::before {

    right: 200px;
}

 }
.head-login-btn .login-bg-color{
    background: #94C75E;
}