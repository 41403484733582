.homepage {
    /* position: relative;
    top: -98px; */
}
.banner-homepage.uk-banner-homepage{
    background: linear-gradient( to right, rgb(62 64 64 / 34%), rgb(62 64 64 / 34%)), url("./../../Assets/Img/lss-uk-banner.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}
.banner-homepage {
    background: url("./../../Assets/Img/listrvparks-banner.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    color: #fff;
    padding-top: 115px;
    padding-bottom:115px;
}
.banner-cont
{
    width: 50%;
    margin-left: 0px;
    padding-bottom: 10px;
    padding-top: 85px;

}
.try-btn-cont
{
    display: flex;
    width: 80%;
    margin: 0px auto;
}
.try-it-btn a
{
color: #ffffff!important;
    background-color: #EF5823!important;
    text-decoration: none!important;
    -webkit-border-radius: 3px;
-moz-border-radius: 3px;
border-radius: 3px;
margin-right: 20px;
padding: 12px;
    font-size: 14px;
    position: relative;
    width: 90%;
    display: inline-block;
    text-align: center;
}
.pricing-in-btn a
{
    color: #ffffff!important;
    background-color: #01890a!important;
    text-decoration: none!important;
    -webkit-border-radius: 3px;
-moz-border-radius: 3px;
border-radius: 3px;
padding: 12px;
    font-size: 14px;
    position: relative;
    width: 90%;
    display: inline-block;
    text-align: center;
}
.pricing-in-btn a span, .try-it-btn a span{

    font-size: 16px;
    position: absolute;
    padding-left: 5px;
    width: 20px;
    height: 20px;
    top: 10px;
}
/*-- home search --*/
.home-search .search-box {
    position: relative;
    display: inline-block;
    width: 6.25%;
    padding-left: 1rem;
    padding-right: 1rem;
    vertical-align: top;
    text-align: center;
    font-size: 13px
}
.homepage .search-box .input-box {
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 5px 0px;
    position: relative;
    font-weight: 400;
    font-style: normal;
    color: rgba(0,0,0,.87);
}
.blue-large-button:hover {
    background-color: #97CB5F;
    color: #ffffff;
    text-decoration: none;
}
.blue-large-button {
    background-color: #97CB5F;
    width: 250px;
    color: #fff;
    margin: 0 .25em 0 0;
    padding: 12px 17px;
    text-transform: none;
    text-shadow: none;
    font-weight: 600;
    line-height: 1em;
    font-style: normal;
    text-align: center;
    letter-spacing: 0.3px;
    font-family: 'Lato', sans-serif!important;
    -webkit-font-smoothing: antialiased;
    text-decoration: none;
    border-radius: .28571429rem;
    cursor: pointer;
    display: inline-block;
    min-height: 1em;
    outline: 0;
    border: none;
    vertical-align: initial;
    text-shadow: none;
    font-size: 15px;
    background-image: none;
}
/*-- home content --*/
.home-content .container {
    padding-top: 30px;
    padding-bottom: 70px;
    padding-left: 0px;
    padding-right: 0px;
    display: block;
    background-color: #ffffff;
}
.sub-header {
    font-size: 13px;
    letter-spacing: 2px;
    color: #555759;
    text-transform: uppercase;
    font-family: 'Lato', sans-serif!important;
    text-align: center;
    line-height: 1.28571429em;
    padding-bottom: 10px;
    font-weight: 700;
}
.page-title {
    text-align: center;
    color: #555759;
    font-size: 24.7px;
    font-weight: 500;
    text-transform: uppercase;
    font-family: 'Lato', sans-serif!important;
}
.go-to-btn {
    color: #97CB5F;
    font-size: 14px;
    position: absolute;
    right: 15px;
    top: 37px;
    font-family: 'Roboto'!important;
    display: block;
}
a.go-to-btn:hover {
    color: #99c753!important;
}
/*-- home ads --*/
.home-ad {
    box-shadow: 0px 0px 7px 0px #bbb8b8;
    border-radius: 3px;
    padding: 15px;
    border: 1px solid #ddd;
    margin-top: 6px;
}
.home-ad .ad-home-size {
    width: 100%;
    height: 135px;
    margin: auto auto 10px;
}
.ad-home-size img{
    object-fit: contain;
}
.home-content-box p span {
    background-color: transparent!important;
    font-size: 13px!important;
}
.home-ad .home-content-box {
    width: 100%;
    height: 123px;
    overflow: hidden;
}
.home-ad .home-content-box h6 {
    color: #58585a;
    font-weight: 600;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.home-ad .home-content-box p {
    font-size: 13px;
    color: #58585a;
    margin-bottom: 3px;
    word-break: break-word;
}
.home-ad .home-content-box a {
    color: #97CB5F;
    font-size: 13px;
    text-decoration: underline;
}
.margin-add20-top {
    margin-top: 20px!important;
}
/*-- home-property --*/
.home-property a {
    cursor: pointer;
}
.home-property:hover {
    transform: none;
    text-decoration: none;
    border-radius: .28571429rem .28571429rem 0 0!important;
    display: block;
    box-shadow: 0 0 7px 1px #ddd;
}
.home-property .carousel .thumbs {
    display: none;
}
.home-property ul.control-dots {
    display: none;
}
.home-property .carousel .control-prev.control-arrow:before {
    border-right: 8px solid transparent;
}
.home-property .carousel .control-next.control-arrow:before {
    border-left: 8px solid transparent;
}
.home-property .carousel-root {
    height: 240px;
}
.home-property .carousel .slide {
    background: #fff!important;
    padding: 0px;
    border: none!important;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.home-property .carousel.carousel-slider {
    box-shadow: none!important;
}
.property-img {
    position: relative;
    display: block;
    flex: 0 0 auto;
    padding: 0;
    background: rgba(0,0,0,.05);
    border-radius: .28571429rem .28571429rem 0 0!important;
    border-top: none!important;
}
.property-img img {
    border-radius: .28571429rem .28571429rem 0 0!important;
    border-top: none!important;
    object-fit: fill;
}
.img-home-property {
    width: 100%;
    height: 238px;
    background: rgba(0,0,0,.05);
    border-radius: .28571429rem .28571429rem 0 0!important;
}
.img-home-property img {
    width: 100%;
    height: 131px;
}
.tags-tss {
    position: absolute;
    right: 14px;
    display: inline-flex;
    top: 0;
    margin: 5px;
}
.tags-tss .bluetag {
    background-color: #97CB5F;
    border-color: #97CB5F;
    color: #ffffff;
    margin-left: 0;
    font-size: 12px;
    line-height: 1.5;
    display: inline-block;
    padding: 3.7px 9px;
    font-weight: 600;
    vertical-align: initial;
    margin: 0 .14285714em;
    border: 0 solid transparent;
    border-radius: 3px;
}
.redtag {
    background-color: #EF5823!important;
    border-color: #EF5823!important;
}
.orangetag {
    background-color:#e19503!important ;
    border-color: #e19503!important;
}
.content-property {
    border: 1.90px solid #dddddd;
    margin: 0px!important;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    border-top: none;
}
.content-property h5 {
    font-size: 14.95px!important;
    color: #555759;
    font-family: 'Roboto'!important;
    margin: 10px 0px 5px;
    font-weight: 700;
    width: 90%;
}
.content-property h6 {
    font-size: 13px;
    font-weight: 400;
    color: #666666;
    margin-bottom: 5px;
}
.margin-bottom-23 {
    margin-bottom: 4px!important;
    margin-top: -3px!important;
}
.content-property p {
    margin-bottom: 2px;
    color: rgba(0,0,0,.68);
    font-size: 12px;
}
.content-property p span.rate-p {
    font-size: 1.4em;
    color: #4f4f4f;
    font-weight: 500;
    font-family: 'Roboto';
}
.content-property p.margin-t-10 {
    margin-top: 12px!important;
}
.content-property p span.call-forvalue {
    font-size: 13px;
    font-weight: 500;
}
.content-property p span{
    font-size: 12px;
}
.home-property a {
    text-decoration: none!important;
}
/*-- search box -- */
.select-property-search .dropdown button{
    color: #555;
    position: relative;
    display: inline-block;
    cursor: pointer;
    height: 45.36px!important;
    background: #fff;
    outline: 0;
    border-radius: 3px;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,.2);
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    font-weight: 600;
    letter-spacing: .6px;
    border: 1px solid rgba(34,36,38,.15);
    padding-right: 1em!important;
    font-size: 11pt!important;
    width: 12em;
    padding: 12px;
    border-top-right-radius: 0!important;
    border-bottom-right-radius: 0!important;
}
.select-property-search button:hover, .select-property-search button:focus, .select-property-search button:active {
    background: #fff!important;
    outline: 0!important;
    color: #555!important;
    box-shadow: none!important;
    border: 1px solid rgba(34,36,38,.15)!important;
}
.select-property-search button.btn-secondary:not(:disabled):not(.disabled).active, .select-property-search button.btn-secondary:not(:disabled):not(.disabled):active, .show>.select-property-search button.btn-secondary.dropdown-toggle {
    background: #fff!important;
    outline: 0!important;
    color: #555!important;
    box-shadow: none!important;
    border: 1px solid rgba(34,36,38,.15)!important;
}
.select-property-search .dropdown button {
    border: 1px solid rgba(34,36,38,.15)!important;
}
.select-property-search .dropdown button span.numbroder {
    border: 1px solid #dedede!important;
    padding: 1px 5px;
    margin-left: 0px;
    font-size: 11px;
    position: relative;
    top: -1px;
    border-radius: 10px;
    left: 5px;
}
.select-property-search .dropdown-menu.show {
    top: -4px!important;
    left: 0px;
    border: none!important;
    position: absolute!important;
    padding: 0px!important;
}
.select-property-search .dropdown-menu.show button {
    font-size: 14px!important;
    font-weight: 400;
    width: 100%;
    height: 39.36px!important;
    padding-top: 0px;
    padding-left: 15px;
    padding-bottom: 0px;
}
.select-property-search .dropdown-menu.show [type=button]:not(:disabled), .select-property-search .dropdown-menu.show [type=reset]:not(:disabled), .select-property-search .dropdown-menu.show [type=submit]:not(:disabled), .select-property-search .dropdown-menu.show button:not(:disabled) {
    border: none!important;
}
.select-property-search .dropdown-toggle::after {
    position: relative;
    right: -9px;
    top: 3px;
}
.select-property-search .dropdown-menu.show button:active, .select-property-search .dropdown-menu.show:focus {
    background-color: #e7e7e7;
}
.select-property-search .dropdown-menu.show button svg {
    float: right;
    font-size: 10px;
    font-weight: 300;
    margin-right: 4px;
    margin-top: 5px;
}
.input-search {
    cursor: pointer;
    word-wrap: break-word;
    white-space: normal;
    outline: 0;
    transform: rotate(0);
    min-width: 14em;
    min-height: 44px;
    background: #fff;
    font-weight: 400;
    display: inline-block;
    box-shadow: none;
    border: 1px solid rgba(34,36,38,.15);
    border-radius: .28571429rem;
    transition: box-shadow .1s ease,width .1s ease;
    height: 45.36px;
    border-radius: 0px;
    border-left: none;
    width: 100%;
    font-size: 16px;
    color: #1a1a1a!important;
    font-style: normal;
}
.inputbox-search .MuiInput-underline:before {
    border-bottom: none!important;
}
.inputbox-search .MuiInput-underline:after {
    border-bottom: none!important;
}
.inputbox-search .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child {
    padding: 12px 12px 10px;
    font-size: 14px;
}
.inputbox-search .MuiAutocomplete-endAdornment {
    padding:3px 8px;
    top: calc(50% - 17px);
}
ul.MuiAutocomplete-listbox#asynchronous-demo1-popup {
    padding: 4px 0px!important;
}
ul.MuiAutocomplete-listbox#asynchronous-demo1-popup .MuiAutocomplete-option {
    font-size: 14px!important;
    font-family: 'Roboto'!important;
}
ul.MuiAutocomplete-listbox#asynchronous-demo-popup {
    padding: 4px 0px!important;
}
ul.MuiAutocomplete-listbox#asynchronous-demo-popup .MuiAutocomplete-option {
    font-size: 14px!important;
    font-family: 'Roboto'!important;
}
ul.MuiAutocomplete-listbox#asynchronous-demo1-popup li:first-child {
    font-weight: bolder!important;
}
ul.MuiAutocomplete-listbox#tags-standard-popup {
    padding: 4px 0!important;
    font-size: 14px!important;
    font-family: 'Roboto'!important;
}
.MuiAutocomplete-option[data-focus="true"] {
    background-color: rgb(151 151 151 / 61%)!important;
}
.MuiAutocomplete-popper .MuiAutocomplete-noOptions {
    color: rgba(0,0,0,.4);
    padding: 7px 14px;
    font-size: 14px;
}
input.inputbox-search::placeholder {
    font-weight: 400;
    color: #bfbfbfde;
}
.inputbox-search, .inputbox-search-empty {
    cursor: pointer;
    word-wrap: break-word;
    white-space: normal;
    outline: 0;
    transform: rotate(0);
    background: #fff;
    font-weight: 400;
    display: inline-block;
    box-shadow: none;
    border: 1px solid rgba(34,36,38,.15);
    border-radius: .28571429rem;
    transition: box-shadow .1s ease,width .1s ease;
    border-radius: 0px;
    width: 40%;
    font-size: 16px;
    color: #1a1a1a!important;
    font-style: normal;
    height: 42px;
}
.inputbox-search-empty {
    background:#fff7f7!important;
    box-shadow: none!important;
    color: #a84e4c!important;
}
.inputbox-search-empty .MuiInput-underline:before {
    border-bottom: none!important;
}
.inputbox-search-empty .MuiInput-underline:after {
    border-bottom: none!important;
}
.inputbox-search-empty .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child {
    padding: 12px 12px 6px;
    font-size: 14px;
}
.inputbox-search-empty .MuiAutocomplete-endAdornment {
    padding:4px 8px;
    top: calc(50% - 16px);
}
.inputbox-search-empty input::placeholder {
    font-weight: 400;
    color: #a84e4c!important;
    opacity: 1!important;
}
button.button-search-home svg{
    padding-right: 3px;
}
button.button-search-home {
    cursor: pointer;
    outline: 0;
    border: none;
    vertical-align: initial;
    font-family: Lato,Helvetica Neue,Arial,Helvetica,sans-serif!important;
    margin: 0 .25em 0 0;
    padding:9px 25px 9px;
    text-transform: none;
    text-shadow: none;
    position: relative;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    text-align: center;    
    background-color: #EF5823;
    border-radius: 3px;
    color: #ffffff;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    position: relative;
    top: 10px;
    height: 42px;
}
button.button-search-home:hover {
    color: #ffffff!important;
    background-color: #EF5823!important;
    text-decoration: none!important;
}
button.button-search-home:focus, button.button-search-home:active {
    color: #ffffff!important;
    background-color: #EF5823!important;
    text-decoration: none!important;
    box-shadow: none!important;
    border: none!important;
}
button.button-search-home.disabled, button.button-search-home:disabled {
    background-color: #EF5823!important;
    color: #ffffff!important;
    opacity: 1!important;
    border-top-left-radius: 0px!important;
    border-bottom-left-radius: 0px!important;
    font-family: 'Lato'!important;
    border: none!important;
}
.box-search-home {
    margin: 0px;
    display: block;
    width: auto;
    padding-left: 0px;
    padding-right: 1rem;
    vertical-align: top;
}
.box-search-home input.inputbox-search-empty::placeholder {
    color: #9f3a38;
    opacity: 1; /* Firefox */
    font-size: 16.7143px;
  }
.advanced-search-home {
    padding: 8px 12px;
}
.advanced-search-text {
    background-color: #94C75E;
    color: #fff;
    padding: 13px 20px;
    border-radius: 2px;
    text-transform: none;
    text-shadow: none;
    font-weight: 500;
    line-height: 33px;
    margin-left: 10px;
    font-style: normal;
    letter-spacing: 0.3px;
    font-family: 'Lato', sans-serif!important;
    -webkit-font-smoothing: antialiased;
    text-decoration: none;
    border-radius: .28571429rem;
    cursor: pointer;
    vertical-align: initial;
    font-size: 14px;
    cursor: pointer!important;
    position: relative;
    top: 9px;
}
a.advanced-search-text:hover {
    color: #fff;
    text-decoration: none;
    text-shadow: none;
    cursor: pointer!important;
    background-color: #01890a!important;
}
.error-ad-search1 {
    position: absolute;
    left: 2%;
    width: 97%;
    height: fit-content;
    top: 7px;
    background-color: #fff6f6;
    border: 1px solid #9f3a38;
    color: #9f3a38;
    border-radius: 3px;
    font-size: 13px;
    padding: .4125rem .625rem;
    z-index: 10;
}
button.padding-clear-home {
    padding: 7px 15px!important;
    margin-right: 0px!important;
}
.error-ad-search2 {
    top: 7px!important;
}
.error-ad-search3 {
    top: 7px!important;
}
.error-ad-search5 {
    top: -4px!important;
    position: relative;
    margin-bottom: 0px!important;
    text-align: center;
    display: block;
    width: 100%;
    margin-left: -8px;
}
.error-ad-search4 {
    top: 7px!important;
}
.dropdown-slist {
    cursor: pointer;
    position: absolute;
    width: 63.6%;
    height: 260px;
    border: 1px solid rgba(34,36,38,.15);
    text-align: left;
    overflow-y: auto;
    background-color: #ffffff;
    line-height: 1.21428571em;
    top: 272px;
    left: 290px;
    list-style: none;
    z-index: 5;
    margin: -.78571429em;
    padding: 0px;
    transition: opacity .1s ease;
    border-top: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-color: #96c8da;
    box-shadow: 0 2px 3px 0 rgba(34,36,38,.15);
}
li.home-search-list {
    padding: 10px 12px!important;
}
li.home-search-list a {
    color: rgba(0,0,0,.95);
    text-decoration: none;
    width: 100%;
    display: block;
}
li.home-search-list:hover {
    background: rgba(0,0,0,.05);
    color: rgba(0,0,0,.95);
    z-index: 13;
    cursor: pointer;
}
li.home-search-list span {
    color: #1a1a1a;
    font-size: 15px;
    text-transform: none;
    font-weight: 300;
    width: 100%;
    display: block;
    word-wrap: break-word;
    white-space: normal;
}
/* Box styles */
.myBox {
    height: auto;
    overflow-y: auto;
    max-height: 260px;
    }
    
    /* Scrollbar styles */
    .myBox::-webkit-scrollbar {
    width: 12px;
    height: 12px;
    }
    
    .myBox::-webkit-scrollbar-track {
    background: #f5f5f5;
    border-radius: 10px;
    }
    
    .myBox::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #ccc;  
    }
    
    .myBox::-webkit-scrollbar-thumb:hover {
    background: #999;  
    }
/*--news --*/
.news-para-height {
    height: 52px;
    overflow: hidden;
    margin-bottom: 11px;
    margin-top: 8px;
}
.select-property-search .show>.btn-secondary.dropdown-toggle { 
    background: #fff!important;
    outline: 0!important;
    color: #555!important;
}
.select-property-search .dropdown .btn-secondary:not(:disabled):not(.disabled).active, .select-property-search .dropdown .btn-secondary:not(:disabled):not(.disabled):active {
    background: #fff!important;
    outline: 0!important;
    color: #555!important;
}
.pop-up-model-home .modal-content .modal-body {
    overflow-y: auto;
    max-height: 550px;
}
.pop-up-model-home div.within-field .form-group .form-control {
    cursor: pointer;
}
.modal-dialog.pop-up-model-home, .modal-content.pop-up-model-home {
    pointer-events:none!important;
}
@media only screen and (min-width: 400px) and (max-width: 419px){
    .dropdown-slist {
        width: 251px!important;
        top: 260px!important;
        left: 92px!important;
    }
    .city-selection .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
        width: 319px!important;
        background-color: #f7f5f5;
    }
}
@media only screen and (min-width: 420px) and (max-width: 425px){
    .img-home-property {
        width: 100%;
        height: 238px;
    }
    .home-property .carousel-root {
        height: 237px;
    }
    .dropdown-slist {
        width: 251px!important;
        top: 260px!important;
        left: 98px!important;
    }
    .city-selection .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
        width: 336px!important;
        background-color: #f7f5f5;
    }
}
@media only screen and (min-width: 321px) and (max-width: 360px){
    .img-home-property {
        width: 330px;
        height: 216px;
    }
    .dropdown-slist {
        width: 250px!important;
        top: 250px!important;
        left: 55px!important;
        margin: auto;
    }
    .home-property .carousel-root {
        height: 216px;
    }
    .city-selection .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
        width: 278px!important;
        background-color: #f7f5f5;
    }
}
@media only screen and (min-width: 200px) and (max-width: 280px){
    .dropdown-slist {
        width: 89.6%!important;
        top: 260px!important;
        left: 26px!important;
    }
    .home-property .carousel-root {
        height: 170px;
    }
    .eventscontent .news-box .row.month-date .col-sm-6 {
        padding: 0px 15px;
        flex: 0 0 100%!important;
        max-width:  100%!important;
    }
    .news-para-height {
        height: 38px;
    }
}
@media only screen and (min-width: 280px) and (max-width: 300px){
    .img-home-property {
        width: 250px;
        height: 170px;
    }
}
@media only screen and (min-width: 301px) and (max-width: 320px){
    .img-home-property {
        width: 290px;
        height: 178px;
    }
    .dropdown-slist {
        width: 251px!important;
        top: 261px!important;
        left: 46px!important;
    }
    .home-property .carousel-root {
        height: 177px;
    }
    .city-selection .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
        width: 238px!important;
        background-color: #f7f5f5;
    }
}
@media only screen and (min-width: 412px) and (max-width: 414px){
    .home-property .carousel-root {
        height: 248px!important;
    }
    .city-selection .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
        width: 330px!important;
        background-color: #f7f5f5;
    }
}
@media only screen and (min-width: 400px) and (max-width: 411px){
.img-home-property {
    width: 381px;
    height: 237px;
}
.dropdown-slist {
    width: 250px!important;
    top: 264px!important;
    left: 92px!important;
}
.inputbox-search, button.button-search-home  {
    width: 250px!important;
}
.box-search-home .select-property-search button.dropdown-toggle, .select-property-search .dropdown-menu {
    width: 250px!important;
}
}
@media only screen and (min-width: 361px) and (max-width: 399px){ 
    .dropdown-slist {
        width: 251px!important;
        top: 261px!important;
        left: 73px!important;
    }
}
@media only screen and (min-width: 280px) and (max-width: 479px){
    .box-search-home, .select-property-search {
        width: 100%;
        display: block;
        margin-bottom: 10px;
    }
    .banner-homepage {
        padding-top: 110px;
        padding-bottom: 35px;
    }
    
    .pop-up-model-home .disply-flex {
        display: block!important;
    }
    .dropdown-slist2 {
        width: 89%!important;
        left: 27px!important;
    }
    .inputbox-search, button.button-search-home  {
        width: 100%;
        margin: auto;
        display: block;
        margin-bottom: 10px;
        border-radius: 5px!important;
    }
    .box-search-home .select-property-search button.dropdown-toggle, .select-property-search .dropdown-menu {
        width: 250px;
        border-radius: 5px!important;
    }
    .advanced-search-home {
        position: unset;
        margin-top: 0px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    }
    .pop-up-model-home .col-sm-4 {
        flex: 0 0 100%!important;
        max-width: 100%!important;
        margin-bottom: 15px!important;
    }
    .market-radius-flex label {
        width: auto!important;
        margin-top: 0px!important;
        margin-right: 5px!important;
    }
    .pop-up-model-home .heading-margin-extra {
        margin-bottom: 3px!important;
        margin-top: 21px!important;
    }
    .pop-up-model-home .within-field span {
        left: 0px!important;
        top: -4px!important;
    }
    .market-radius-flex input {
        width: 67%!important;
    }
    .home-content .container {
        padding: 15px;
    }
    .home-content .margin-b25 {
        margin-top: 15px;
    }
    .home-content .page-header h3, .home-content .page-header h2 {
        margin-bottom: 20px;
    }
    .eventscontent .news-box .row.month-date .col-sm-6 {
        flex: 0 0 50%;
        max-width:  50%;
    }
    .home-ad {
        padding: 15px 15px 15px;
        margin-top:7px;
        margin-bottom: 7px;
    }
    .blue-large-button {
        margin-bottom: 10px;
    }
    .home-ad .home-content-box {
        height: auto;
    }
    .go-to-btn {
      display: none;
    }.eventscontent .col-sm-4 {
       margin-top: 0px;
    }
    .width-47 {
        width:100%!important;
    }
    .pop-up-model-home .padding-search, .pop-up-model-home .padding-search2 {
        padding: 0px 0px 0px 0px!important;
    }
    .width-6 {
        width: 100%!important;
        margin: 0px 0px 5px!important;
    }
}
@media only screen and (min-width: 561px) and (max-width: 599px){
    .box-search-home, .select-property-search {
        width: 100%;
        display: block;
        margin-bottom: 10px;
    }
    .banner-homepage {
        padding-top: 60px;
        padding-bottom: 35px;
    }
    .inputbox-search, button.button-search-home  {
        width: 100%;
        margin: auto;
        display: block;
        margin-bottom: 10px;
        border-radius: 5px!important;
    }
    .home-content .col-sm-4 {
        flex: 0 0 100%!important;
        max-width: 100%!important;
    }
    .box-search-home .select-property-search button.dropdown-toggle, .select-property-search .dropdown-menu {
        width: 100%;
        border-radius: 5px!important;
    }
    .dropdown-slist {
        width: 94.6%;
        top: 260px;
        left: 27px;
    }
    .advanced-search-home {
        margin-top: 0px;
        margin-bottom: 10px;
        margin-left: auto;
    }
    .home-content .container {
        padding-top: 30px;
        padding-bottom: 50px;
        padding-left: 30px;
        padding-right: 30px;
    }
    .home-property .carousel-root {
        height: 300px;
    }
    .img-home-property {
        width: 100%;
        height: 300px;
    }
    .go-to-btn {
        top: 67px;
    }
    .eventscontent .news-box .row.month-date .col-sm-6 {
        padding: 0px 15px;
        flex: 0 0 50%;
        max-width:  50%;
    }
    .news-para-height {
        height: 52px;
    }
    .width-47 {
        width:100%!important;
    }
    .pop-up-model-home .padding-search, .pop-up-model-home .padding-search2 {
        padding: 0px 0px 0px 0px!important;
    }
    .width-6 {
        width: 100%!important;
        margin: 0px 0px 5px!important;
    }
}
@media only screen and (min-width: 600px) and (max-width: 700px){
    .home-content .container {
        padding: 15px;
    }
    .box-search-home {
        width: 100%;
    }
    .pop-up-model-home.modal-dialog {
        max-width: 480px!important;
        margin: 1.75rem auto;
    }
    .pop-up-model-home .col-sm-4 {
        flex: 0 0 100%!important;
        max-width: 100%!important;
        margin-bottom: 15px!important;
    }
    .inputbox-search {
        width: 85%;
        height: 45px;
    }
    button.button-search-home {
        height: 45px;
    }
    .go-to-btn {
        top: 52px;
    }
    .home-content .content-property {
        height: 180px!important;
    }
    .news-para-height {
        height: 55px;
    }
    .dropdown-slist {
        left: 183px;
        width: 52.6%;
    }
    .img-home-property {
        width: 100%;
        height: 147px;
    }
    .home-ad .home-content-box {
        width: 100%;
        height: 294px;
    }
    .eventscontent .news-box .row.month-date .col-sm-6 {
        flex: 0 0 50%;
        max-width:  50%;
    }
    .width-47 {
        width:100%!important;
    }
    .pop-up-model-home .padding-search, .pop-up-model-home .padding-search2 {
        padding: 0px 0px 0px 0px!important;
    }
    .width-6 {
        width: 100%!important;
        margin: 0px 0px 5px!important;
    }
    .tags-tss {
        display: grid;
    }
    .tags-tss .bluetag {
        margin-bottom: 5px;
    }
    .city-selection .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
        width:100%!important;
        background-color: #f7f5f5;
    }
}
@media only screen and (min-width: 721px) and (max-width: 745px){
    .home-content .container {
        padding: 15px 15px 35px;
        max-width: 665px!important;
    }
    .box-search-home {
        width: 96%;
    }
    .inputbox-search {
        width: 67%;
        height: 45px;
    }
    button.button-search-home {
        height: 45px;
    }
   
    .news-para-height {
        height: 55px;
    }
    .eventscontent .news-box .row.month-date .col-sm-6 {
        flex: 0 0 100%;
        max-width:  100%;
    }
    .width-47 {
        width:100%!important;
    }
    .pop-up-model-home .padding-search, .pop-up-model-home .padding-search2 {
        padding: 0px 0px 0px 0px!important;
    }
    .width-6 {
        width: 100%!important;
        margin: 0px 0px 5px!important;
    }
    .content-property .col-sm-6 {
        flex: 0 0 100%!important;
        max-width: 100%!important;
    }
    .dropdown-slist {
        width: 53.7%;
        height: auto!important;
        max-height: 225px;
        top: 272px;
        left: 199px;
    }
    .img-home-property {
        width: 100%;
        height: 150px;
    }
    .home-ad .home-content-box {
        height: 233px;
    }
    .tags-tss {
        display: grid;
    }
    .tags-tss .bluetag {
        margin-bottom: 5px;
    }
    .city-selection .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
        width:100%!important;
        background-color: #f7f5f5;
    }
}
@media only screen and (min-width: 501px) and (max-width: 560px){
    .inputbox-search, button.button-search-home  {
        width: 100%;
        margin: auto;
        display: block;
        margin-top: 10px;
        margin-bottom: 10px;
        border-radius: 5px!important;
    }
    .banner-homepage {
        padding-top: 60px;
        padding-bottom: 35px;
    }
    .box-search-home .select-property-search button.dropdown-toggle, .select-property-search .dropdown-menu {
        width: 100%;
        border-radius: 5px!important;
    }
    .dropdown-slist {
        width: 94%;
        top: 260px;
        left: 27px;
    }
    .city-selection .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
        width: 100%!important;
        background-color: #f7f5f5;
    }
    .advanced-search-home {
        margin-top: 0px;
        margin-bottom: 10px;
        margin-left: auto;
        width: 93%;
    }
    .home-content .container {
        padding-top: 30px;
        padding-bottom: 50px;
        padding-left: 30px;
        padding-right: 30px;
    }
    .home-property .carousel-root {
        height: 300px;
    }
    .img-home-property {
        width: 100%;
        height: 300px;
    }
    .go-to-btn {
        top: 67px;
    }
    .eventscontent .news-box .row.month-date .col-sm-6 {
        padding: 0px 15px;
        flex: 0 0 50%;
        max-width:  50%;
    }
    .news-para-height {
        height: 52px;
    }
    .width-47 {
        width:100%!important;
    }
    .pop-up-model-home .padding-search, .pop-up-model-home .padding-search2 {
        padding: 0px 0px 0px 0px!important;
    }
    .width-6 {
        width: 100%!important;
        margin: 0px 0px 5px!important;
    }
    .pop-up-model-home.modal-dialog {
        max-width: 480px!important;
        margin: 1.75rem auto;
    }
    .box-search-home {
        display: block!important;
        width: 100%!important;
    }
    .pop-up-model-home .col-sm-4 {
        flex:  0 0 100%!important;
        max-width: 100%!important;
        margin: 10px 0px;
    }
    .home-ad {
        margin-bottom: 10px;
    }
    .blue-large-button {
        width: 46%!important;
    }
}
@media only screen and (min-width: 480px) and (max-width: 500px){
    .home-content .container {
        padding: 30px;
    }
    .box-search-home {
        width: 100%;
        display: block;
    }
    .banner-homepage {
        padding-top: 60px;
        padding-bottom: 35px;
    }
    .inputbox-search, button.button-search-home  {
        width: 400px;
        margin: auto;
        display: block;
        margin-bottom: 10px;
        border-radius: 5px!important;
    }
    .box-search-home .select-property-search button.dropdown-toggle, .select-property-search .dropdown-menu {
        width: 437px;
        border-radius: 5px!important;
        margin-bottom: 10px;
    }
    .blue-large-button {
        width: 400px;
        margin-bottom: 10px;
    }
    .go-to-btn {
        top: 60px;
    }
    .advanced-search-home {
        position: unset;
        margin-top: 0px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    }
    .img-home-property {
        width: 100%;
        height: 270px;
    }
    .home-property .carousel-root {
        height: 270px;
    }
    .news-para-height {
        height: 55px;
    }
    .eventscontent .news-box .row.month-date .col-sm-6 {
        flex: 0 0 50%;
        max-width:  50%;
    }
    .dropdown-slist {
        width: 83%;
        top: 259px;
        left: 52px;
    }
    .width-47 {
        width:100%!important;
    }
    .pop-up-model-home .padding-search, .pop-up-model-home .padding-search2 {
        padding: 0px 0px 0px 0px!important;
    }
    .width-6 {
        width: 100%!important;
        margin: 0px 0px 5px!important;
    }
    .pop-up-model-home .col-sm-4 {
        flex:  0 0 100%!important;
        max-width: 100%!important;
    }
}
@media only screen and (min-width: 710px) and (max-width: 730px){
    .box-search-home {
        width: 95%!important;
    }
    .home-content .container {
        padding: 15px;
    }
    .dropdown-slist {
        left: 201px!important;
        width: 54.7%!important;
    }
    .home-content .container {
        max-width: 674px!important;
    }
    .pop-up-model-home.modal-dialog {
        max-width: 460px!important;
        margin: 1.75rem auto ;
    }
    .pop-up-model-home .col-sm-4 {
        flex: 0 0 100%!important;
    max-width: 100%!important;
    margin: 10px auto 10px!important;
    }
    .inputbox-search {
        width: 85%;
        height: 45px;
    }
    button.button-search-home {
        height: 45px;
    }
    .go-to-btn {
        top: 52px;
    }
    .home-content .content-property {
        height: 180px!important;
    }
    .news-para-height {
        height: 55px;
    }

    .img-home-property {
        width: 100%;
        height: 147px;
    }
    .home-ad .home-content-box {
        width: 100%;
        height: 294px;
    }
    .eventscontent .news-box .row.month-date .col-sm-6 {
        flex: 0 0 50%;
        max-width:  50%;
    }
    .width-47 {
        width:100%!important;
    }
    .pop-up-model-home .padding-search, .pop-up-model-home .padding-search2 {
        padding: 0px 0px 0px 0px!important;
    }
    .width-6 {
        width: 100%!important;
        margin: 0px 0px 5px!important;
    }
    .tags-tss {
        display: grid;
    }
    .tags-tss .bluetag {
        margin-bottom: 5px;
    }
}
@media only screen and (min-width: 750px) and (max-width: 767px){
    .box-search-home {
        width: 90%!important;
    }
    .dropdown-slist {
        width: 52.6%;
        top: 274px;
        left: 221px;
    }
    .content-property .col-sm-6 {
        flex: 0 0 100%!important;
        max-width: 100%!important;
    }
    .home-ad .home-content-box {
        width: 100%;
        height: 300px;
    }
    .go-to-btn {
        top: 52px;
    }
    .pop-up-model-home .col-sm-4 {
        flex: 0 0 80%!important;
    max-width: 80%!important;
    margin: auto!important;
    }
}
@media only screen and (min-width: 768px) and (max-width: 959px){
    .city-selection .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
        width: 100%!important;
        background-color: #f7f5f5;
    }
    .home-ad .home-content-box {
        height: 220px;
    }
    .box-search-home {
        width: 700px;
    }
    .dropdown-slist {
        width: 51.6%;
        top: 264px;
        left: 236px;
    }
    .home-property .carousel-root {
        height: 145px;
    }
    .pop-up-model-home .col-sm-4 {
        flex: 0 0 80%!important;
    max-width: 80%!important;
    margin: 8px auto!important;
    }
    .pop-up-model-home.modal-dialog {
        max-width: 600px!important;
        margin: 1.75rem auto;
    }
    .img-home-property {
        width: 220px;
        height: 145px;
    }
    .news-para-height {
        height: 39px;
    }
    .eventscontent .news-box .row.month-date {
        width: 100%;
        margin: auto;
    }
    .eventscontent .news-box .row.month-date .col-sm-6 {
        padding: 0px;
        flex: 0 0 100%;
        max-width:  100%;
    }
    .go-to-btn {
        top: 55px;
    }
    button.button-search-home { 
        width: 141px!important;
    }
}
@media only screen and (min-width: 771px) and (max-width: 780px){
    .dropdown-slist {
        width: 51.1%!important;
        top: 264px!important;
        left: 240px!important;
    }
    .pop-up-model-home .col-sm-4 {
        flex: 0 0 100%!important;
    max-width: 100%!important;
    margin: 8px auto!important;
    }
    .pop-up-model-home.modal-dialog {
        max-width: 500px!important;
        margin: 1.75rem auto;
    }
    .dropdown-slist2 {
        width: 83.2%!important;
    }
}
@media only screen and (min-width: 781px) and (max-width: 790px){
    .dropdown-slist {
        width: 52.4%!important;
        top: 264px!important;
        left: 238px!important;
    }
    .box-search-home {
        width: 718px!important;
    }
    .pop-up-model-home .col-sm-4 {
        flex: 0 0 60%!important;
    max-width: 60%!important;
    margin: 8px auto!important;
    }
    .pop-up-model-home.modal-dialog {
        max-width: 700px!important;
        margin: 1.75rem auto;
    }
}
@media only screen and (min-width: 791px) and (max-width: 795px){
    .dropdown-slist {
        width: 53.5%!important;
        top: 264px!important;
        left: 234px!important;
    }
    .box-search-home {
        width: 727px!important;
    }
    .pop-up-model-home .col-sm-4 {
        flex: 0 0 60%!important;
    max-width: 60%!important;
    margin: 8px auto!important;
    }
    .pop-up-model-home.modal-dialog {
        max-width: 700px!important;
        margin: 1.75rem auto;
    }
}
@media only screen and (min-width: 796px) and (max-width: 800px){
    .dropdown-slist {
        width: 53.5%!important;
        top: 264px!important;
        left: 234px!important;
    }
    .box-search-home {
        width: 733px!important;
    }
    .pop-up-model-home .col-sm-4 {
        flex: 0 0 85%!important;
    max-width: 85%!important;
    margin: 8px auto!important;
    }
    .pop-up-model-home.modal-dialog {
        max-width: 550px!important;
        margin: 1.75rem auto;
    }
}
@media only screen and (min-width: 801px) and (max-width: 810px){
    .dropdown-slist {
        width: 54.1%!important;
        top: 264px!important;
        left: 234px!important;
    }
    .box-search-home {
        width: 743px!important;
    }
    .pop-up-model-home .col-sm-4 {
        flex: 0 0 85%!important;
    max-width: 85%!important;
    margin: 8px auto!important;
    }
    .pop-up-model-home.modal-dialog {
        max-width: 550px!important;
        margin: 1.75rem auto;
    }
}
@media only screen and (min-width: 811px) and (max-width: 820px){
    .dropdown-slist {
        width: 54.6%!important;
        top: 264px!important;
        left: 234px!important;
    }
    .box-search-home {
        width: 753px!important;
    }
    .pop-up-model-home .col-sm-4 {
        flex: 0 0 85%!important;
    max-width: 85%!important;
    margin: 8px auto!important;
    }
    .pop-up-model-home.modal-dialog {
        max-width: 550px!important;
        margin: 1.75rem auto;
    }
}
@media only screen and (min-width: 821px) and (max-width: 830px){
    .dropdown-slist {
        width: 55.1%!important;
        top: 264px!important;
        left: 234px!important;
    }
    .box-search-home {
        width: 763px!important;
    }
    .pop-up-model-home .col-sm-4 {
        flex: 0 0 85%!important;
    max-width: 85%!important;
    margin: 8px auto!important;
    }
    .pop-up-model-home.modal-dialog {
        max-width: 550px!important;
        margin: 1.75rem auto;
    }
}
@media only screen and (min-width: 950px) and (max-width: 970px){
    .dropdown-slist {
        width: 50.1%!important;
        top: 274px!important;
        left: 290px!important;
    }
    .box-search-home {
        width: 783px!important;
    }
    .home-property .carousel-root {
        height: 215px;
    }
    .img-home-property {
        width: 100%;
        height: 214px;
    }
    .pop-up-model-home .col-sm-4 {
        flex: 0 0 80%!important;
    max-width: 80%!important;
    margin: 8px auto!important;
    }
    .pop-up-model-home.modal-dialog {
        max-width: 600px!important;
        margin: 1.75rem auto;
    }
}
@media only screen and (min-width: 971px) and (max-width: 990px){
    .dropdown-slist {
        width: 50.7%!important;
        top: 273px!important;
        left: 293px!important;
    }
    .box-search-home {
        width: 802px!important;
    }
    .home-property .carousel-root {
        height: 156px;
    }
    .img-home-property {
        width: 100%;
        height: 155px;
    }
    .eventscontent .news-box .month-date .col-sm-6:first-child {
        flex: 0 0 38%!important;
        max-width: 38%!important;
    }
    .eventscontent .news-box .month-date .col-sm-6:last-child {
        flex: 0 0 62%!important;
        max-width: 62%!important;
    }
    .pop-up-model-home .col-sm-4 {
        flex: 0 0 100%!important;
    max-width: 100%!important;
    margin: 8px auto!important;
    }
    .pop-up-model-home.modal-dialog {
        max-width: 500px!important;
        margin: 1.75rem auto;
    }
    .city-selection .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
        width:100%!important;
        background-color: #f7f5f5;
    }
}
@media only screen and (min-width: 991px) and (max-width: 996px){
    .home-ad .home-content-box {
        height: 150px;
    }
    .box-search-home {
        width: 927px;
    }
    .dropdown-slist {
        width: 62.4%;
        top: 264px;
        left: 235px;
    }
    .home-property .carousel-root {
        height: 193px;
    }
    .img-home-property {
        width: 300px;
        height: 192px;
    }
    .content-property p span.call-forvalue {
        font-size: 10px;
    }
    .pop-up-model-home .within-field span {
        left: -30px!important;
    }
    .market-radius-flex label {
        width: 120px!important;
        margin-top: 0px!important;
    }
    .pop-up-model-home .col-sm-4 {
        flex: 0 0 100%!important;
    max-width: 100%!important;
    margin: 8px auto!important;
    }
    .pop-up-model-home.modal-dialog {
        max-width:450px!important;
        margin: 1.75rem auto;
    }
    .dropdown-slist2 {
        width: 81.7%!important;
    }
    .city-selection .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
        width:100%!important;
        background-color: #f7f5f5;
    }
}
@media only screen and (min-width: 997px) and (max-width: 1050px){
    .home-ad .home-content-box {
        height: 150px;
    }
    .box-search-home {
        width: 958px;
    }
    .dropdown-slist {
        width: 65.1%;
        top: 264px;
        left: 222px;
    }
    .home-property .carousel-root {
        height: 193px;
    }
    .img-home-property {
        width: 300px;
        height: 192px;
    }
    .content-property p span.call-forvalue {
        font-size: 10px;
    }
    .pop-up-model-home .within-field span {
        left: -30px!important;
    }
    .market-radius-flex label {
        width: 54px!important;
        margin-top: 0px!important;
    }
    .pop-up-model-home .col-sm-4 {
        flex: 0 0 100%!important;
    max-width: 100%!important;
    margin: 8px auto!important;
    }
    .pop-up-model-home.modal-dialog {
        max-width: 500px!important;
        margin: 1.75rem auto;
    }
    .city-selection .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
        width:100%!important;
        background-color: #f7f5f5;
    }
}
@media only screen and (min-width: 1000px) and (max-width: 1010px){
    .dropdown-slist {
        width: 62.6%!important;
        top: 264px!important;
        left: 235px!important;
    }
    .box-search-home {
        width: 935px!important;
    }
    .city-selection .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
        width:100%!important;
        background-color: #f7f5f5;
    }
}
@media only screen and (min-width: 1011px) and (max-width: 1015px){
    .dropdown-slist {
        width: 61.9%!important;
        top: 264px!important;
        left: 242px!important;
    }
    .box-search-home {
        width: 935px!important;
    }
    .city-selection .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
        width:100%!important;
        background-color: #f7f5f5;
    }
}
@media only screen and (min-width: 1016px) and (max-width: 1030px){
    .dropdown-slist {
        width: 63.4%!important;
        top: 264px!important;
        left: 235px!important;
    }
    .city-selection .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
        width:100%!important;
        background-color: #f7f5f5;
    }
}
@media only screen and (min-width: 1035px) and (max-width: 1040px){
    .dropdown-slist {
        width: 63.9%!important;
        top: 271px!important;
        left: 235px!important;
    }
    .box-search-home {
        width: 974px!important;
    }
    .city-selection .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
        width:100%!important;
        background-color: #f7f5f5;
    }
}
@media only screen and (min-width: 1041px) and (max-width: 1045px){
    .dropdown-slist {
        width: 63.9%!important;
        top: 271px!important;
        left: 237px!important;
    }
    .box-search-home {
        width: 979px!important;
    }
}
@media only screen and (min-width: 1046px) and (max-width: 1050px){
    .dropdown-slist {
        width: 63.9%!important;
        top: 271px!important;
        left: 237px!important;
    }
    .box-search-home {
        width: 979px!important;
    }
}
@media only screen and (min-width: 1060px) and (max-width: 1070px){
    .img-home-property {
        width: 100%!important;
        height: 217px!important;
    }
    .home-property .carousel-root {
        height: 215px!important;
    }
    .dropdown-slist {
        width: 55%!important;
        left: 289px!important;
    }
    .home-ad .home-content-box {
        height: 150px!important;
    }
    .pop-up-model-home .col-sm-4 {
        flex: 0 0 100%!important;
    max-width: 100%!important;
    margin: 8px auto!important;
    }
    .pop-up-model-home.modal-dialog {
        max-width: 500px!important;
        margin: 1.75rem auto;
    }
    .city-selection .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
        width:100%!important;
        background-color: #f7f5f5;
    }
}
@media only screen and (min-width: 1071px) and (max-width: 1080px){
    .img-home-property {
        width: 100%!important;
        height: 217px!important;
    }
    .home-property .carousel-root {
        height: 215px!important;
    }
    .pop-up-model-home .within-field span {
        left: -40px!important;
    }
    .pop-up-model-home .col-sm-4 {
        flex: 0 0 100%!important;
    max-width: 100%!important;
    margin: 8px auto!important;
    }
    .pop-up-model-home.modal-dialog {
        max-width: 500px!important;
        margin: 1.75rem auto;
    }
    .city-selection .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
        width:100%!important;
        background-color: #f7f5f5;
    }
}
@media only screen and (min-width: 1081px) and (max-width: 1090px){
    .img-home-property {
        width: 100%!important;
        height: 217px!important;
    }
    .home-property .carousel-root {
        height: 215px!important;
    }
    .dropdown-slist {
        width: 55.8%!important;
        left: 289px!important;
    }
    .eventscontent .news-box .month-date .col-sm-6:last-child {
        padding-left: 0px!important;
    }
    .city-selection .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
        width:100%!important;
        background-color: #f7f5f5;
    }
}
@media only screen and (min-width: 1050px) and (max-width: 1099px){
    .box-search-home {
        display: flex;
        width: 84%;
    }
    .banner-homepage .box-search-home{
        display: block;
        width: 100%;
    }
    .dropdown-slist {
        width: 55.4%;
        left: 289px;
    }
    .dropdown-slist2 {
        width: 78.2%!important;
        left: 70px!important;
    }
    .city-lable {
        width: 28px!important;
    }
    .width-47 {
        width: 42.8%;
        margin-bottom: 10px;
    }
    .market-radius-flex label {
        width: 70px!important;
        margin-right: 5px;
    }
    .pop-up-model-home .col-sm-4 {
        flex: 0 0 100%!important;
    max-width: 100%!important;
    margin: 8px auto!important;
    }
    .pop-up-model-home.modal-dialog {
        max-width: 500px!important;
        margin: 1.75rem auto;
    }
    .city-selection .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
        width:100%!important;
        background-color: #f7f5f5;
    }
}
@media only screen and (min-width: 1100px) and (max-width: 1136px){
    .box-search-home {
        width: 967px;
    }
    .dropdown-slist {
        width: 57.9%;
        top: 272px;
        left: 286px;
    }
    .dropdown-slist2 {
        width: 77%!important;
    }
    .city-selection .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
        width:100%!important;
        background-color: #f7f5f5;
    }
}
@media only screen and (min-width: 1137px) and (max-width: 1200px){
.box-search-home {
    width: 982px;
}
.dropdown-slist {
    width: 61%;
    top: 272px;
    left: 262px;
}
.img-home-property {
    width: 100%;
    height: 210px;
}
.home-property .carousel-root {
    height: 210px;
}
.eventscontent .news-box .month-date .col-sm-6:last-child {
    padding-left: 0px!important;
}
.city-selection .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    width:285px!important;
    background-color: #f7f5f5;
}
}
@media only screen and (min-width: 1120px) and (max-width: 1130px){
    .dropdown-slist {
        width: 58.7%;
        left: 279px!important;
    }
    .city-selection .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
        width:275px!important;
        background-color: #f7f5f5;
    }
}
@media only screen and (min-width: 1140px) and (max-width: 1150px){
    .dropdown-slist {
        width: 59%!important;
        left: 281px!important;
    }
    .city-selection .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
        width:280px!important;
        background-color: #f7f5f5;
    }
}
@media only screen and (min-width: 1190px) and (max-width: 1200px){
    .dropdown-slist {
        width: 60.8%!important;
        left: 282px!important;
    }
    .box-search-home {
        width: 1042px!important;
    }
    .city-selection .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
        width:285px!important;
        background-color: #f7f5f5;
    }
}
@media only screen and (min-width: 1201px) and (max-width: 1250px){
    .dropdown-slist {
        width: 70%;
        top: 264px;
        left: 228px;
    }
    .city-selection .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
        width:285px!important;
        background-color: #f7f5f5;
    }
}
@media only screen and (min-width: 1270px) and (max-width: 1289px){
    .box-search-home {
        width: 1104px;
    }
    .dropdown-slist {
        width: 61.83%;
    }
    .city-selection .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
        width:285px!important;
        background-color: #f7f5f5;
    }
}
@media only screen and (min-width: 1290px) and (max-width: 1310px){
    .box-search-home {
        width: 1123px;
    }
    .dropdown-slist {
        width: 62.3%;
    }
    .myBox {
        max-height: 227px;
    }
}
@media only screen and (min-width: 1350px) and (max-width: 1359px){
    .dropdown-slist {
        width: 63.9%;
        top: 272px;
        left: 289px;
    }
    .box-search-home {
        width: 1179px;
    }
}
@media only screen and (min-width: 1340px) and (max-width: 1349px){
    .dropdown-slist {
        width: 63.7%;
        top: 272px;
        left: 288px;
    }
    .box-search-home {
        width: 1169px;
    }
}
@media only screen and (min-width: 1330px) and (max-width: 1339px){
    .dropdown-slist {
        width: 63.4%;
        top: 272px;
        left: 289px;
    }
    .box-search-home {
        width: 1159px;
    }
}
@media only screen and (min-width: 1365px) and (max-width: 1366px){
    .dropdown-slist {
        width: 63.7%;
        top: 272px;
        left: 293px;
    }
}
@media only screen and (min-width: 1390px) and (max-width: 1399px){
    .dropdown-slist {
        width: 64.36%;
        top: 272px;
        left: 291px;
    }
    .box-search-home {
        width: 1204px;
    }
}
@media only screen and (min-width: 1400px) and (max-width: 1440px){
    .dropdown-slist {
        width: 59.5%;
        top: 264px;
        left: 337px;
    }
    .advanced-search-home {
        position: relative;
    }
}
@media only screen and (min-width: 1441px) and (max-width: 1450px){
    .dropdown-slist {
        width: 59%;
        top: 264px;
        left: 343px;
    }
    .advanced-search-home {
        position: relative;
    }
}
@media only screen and (min-width: 1451px) and (max-width: 1460px){
    .advanced-search-home {
        position: relative;
    }
    .dropdown-slist {
        width: 59%;
        top: 273px;
        left: 343px;
    }
}
@media only screen and (min-width: 1461px) and (max-width: 1470px){
.advanced-search-home {
    position: relative;
}
.dropdown-slist {
    width: 58.5%;
    top: 273px;
    left: 349px;
}
}
@media only screen and (min-width: 1471px) and (max-width: 1500px){
    .advanced-search-home {
        position: relative;
    }
    .dropdown-slist {
        width: 57.1%;
        top: 273px;
        left: 367px;
    }
}
@media only screen and (min-width: 1501px) and (max-width: 1520px){
    .advanced-search-home {
        position: relative;
    }
    .dropdown-slist {
        width: 57.3%;
        top: 273px;
        left: 365px;
    }
}
@media only screen and (min-width: 1521px) and (max-width: 1530px){
    .advanced-search-home {
        position: relative;
    }
    .dropdown-slist {
        width: 56.5%;
        top: 274px;
        left: 376px;
    }
}
@media only screen and (min-width: 1531px) and (max-width: 1540px){
    .advanced-search-home {
        position: relative;
    }
    .dropdown-slist {
        width: 56.85%;
        top: 274px;
        left: 376px;
    }
    .box-search-home {
        width: 1188px;
    }
}
@media only screen and (min-width: 1541px) and (max-width: 1600px){
    .advanced-search-home {
        position: relative;
    }
    .dropdown-slist {
        width: 56.5%;
        top: 274px;
        left: 376px;
    }
}
@media only screen and (min-width: 1650px) and (max-width: 1710px){
    .advanced-search-home {
        position: relative;
    }
    .dropdown-slist {
        width: 51%;
        top: 264px;
        left: 457px;
    }
} 
@media only screen and (min-width: 1790px) and (max-width: 1820px){
    .advanced-search-home {
        position: relative;
    }
    .dropdown-slist {
        width: 47.8%;
        top: 263px;
        left: 513px;
    }
}
@media only screen and (min-width: 1900px) and (max-width: 1920px){
    .dropdown-slist {
        width: 44.6%;
        top: 272px;
    left: 577px;
    }
}
@media only screen and (min-width: 2030px) and (max-width: 2048px){
    .dropdown-slist {
        width: 42.3%;
        top: 272px;
        left: 630px;
    }
}
@media only screen and (min-width: 2550px) and (max-width: 22570px){
    .dropdown-slist {
        width: 33.4%;
        top: 272px;
        left: 898px;
    }
}
/*-- twitter -feed --*/
.twitter-feeds .timeline-Tweet .timeline-Tweet-media {
    display: none!important;
}
.SandboxRoot.env-bp-min .timeline-Tweet {
    border: 1px solid #000000!important;
}

/* popup */

.pop-up-model-home .modal-header {
    border-bottom: none;
    padding-bottom: 0px;
}
.pop-up-model-home .modal-header .modal-title {
    margin: auto;
    color: #97CB5F;
    font-size: 22px;
    font-weight: 600;
}
.pop-up-model-home .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem;
}
.pop-up-model-home .row {
    width: 100%;
    margin: auto;
}
.pop-up-model-home .col-sm-4 {
    border: 1px solid #ddd;
    border-radius: 3px;
    margin-right: 10px;
    flex: 0 0 32.70%;
    max-width: 32.70%;
    box-shadow: 0px 0px 3px 0px #ddd;
}
.pop-up-model-home .col-sm-4 h4 {
    font-size: 17px;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
    color: #EF5823;
    font-weight: 500;
}
.pop-up-model-home .col-sm-4 h3 {
    font-size: 16px;
    margin: 0px 0px 7px;
    color: #4c4c4c;
    text-align: left;
}
.pop-up-model-home .col-sm-4 h4 span {
    font-size: 13px;
}
.pop-up-model-home .disply-flex label {
    margin-top: 6px;
    margin-bottom: auto;
    margin-right: 15px;
    color: #565656;
    font-weight: 400;
    font-size: 14px;
    width: 32px;
}
.pop-up-model-home #multiselectContainerReact input {
    margin-top: 0px;
}
.pop-up-model-home #multiselectContainerReact {
    margin-bottom: 12px;
    border: 1px solid #cccccc!important;
    border-radius: 2px!important;
    background-color: #f7f5f5!important;
}
.pop-up-model-home #multiselectContainerReact ._2iA8p44d0WZ-WqRBGcAuEV {
    border-radius: 2px;
    background-color: #f7f5f5;
    border: none!important;
}
.pop-up-model-home #multiselectContainerReact ._7ahQImyV4dj0EpcNOjnwA {
    padding: 2px 6px;
    background: #1e5699;
    margin-bottom: 3px;
    white-space: break-spaces;
}
.myBox2 {
    height: auto;
    overflow-y: auto;
    max-height: 188px;
}

   .myBox2::-webkit-scrollbar {
    width: 5px;
    height: 10px;
    }
    
    .myBox2::-webkit-scrollbar-track {
    background: #e5e5e5;
    border-radius: 10px;
    }
    
    .myBox2::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #c3c3c3;  
    }
    
    .myBox2::-webkit-scrollbar-thumb:hover {
    background: #999;  
    }
.dropdown-slist2 {
    cursor: pointer;
    position: absolute;
    width: 78.7%;
    height: auto;
    max-height: 188px;
    border: 1px solid rgba(34,36,38,.15);
    text-align: left;
    overflow-y: auto;
    background-color: #ffffff;
    line-height: 1.21428571em;
    left: 72px;
    list-style: none;
    z-index: 1;
    margin: -.78571429em;
    padding: 0px;
    transition: opacity .1s ease;
    border-top: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-color: #96c8da;
    box-shadow: 0 2px 3px 0 rgba(34,36,38,.15);
}
.pop-up-model-home label {
    color: #565656;
    font-size: 14px;
    font-weight: 400;
}
.city-lable {
    margin-top: 6px;
    margin-bottom: auto;
    margin-right: 15px;
    color: #565656;
    font-weight: 400;
    font-size: 14px;
    width: 31px
}
.pop-up-model-home .form-group {
    margin-bottom: 9px;
}
.pop-up-model-home .form-group .form-control {
    height: 34px;
    background: #f7f5f5;
    border: 1px solid #ccc;
    border-radius: 2px;
    font-size: 14px;
}
.heading-margin-extra {
    margin-bottom: 24px!important;
    margin-top: 23px!important;
}
.heading-margin-reduce {
    margin-top: -9px!important;
}
.pop-up-model-home .form-group .form-control:focus, .pop-up-model-home .form-group .form-control:active {
    box-shadow: none!important;
    outline: none!important;
    border: 1px solid #99c753;
}
.pop-up-model-home .within-field, .pop-up-model-home .within-field .form-group {
    display: inline-flex;
    width: 100%;
}
.pop-up-model-home .within-field label {
    width: 47px;
    margin-top: 5px;
    margin-bottom: auto;
}
.pop-up-model-home .within-field select {
    width: 64px;
    padding: 8px;
}
.pop-up-model-home .within-field span {
    width: 100%;
    margin-top: 9px;
    margin-bottom: auto;
    margin-left: 10px;
    color: #666666;
    font-size: 12px;
    position: relative;
    left: -55px;
}
.max-min-price .form-group input::placeholder {
    font-size: 14px;
}
.max-min-price2 .form-group input::placeholder {
    font-size: 14px;
}
.pop-up-model-home .max-min-price .form-group {
    display: inline-flex;
    width: 100%;
}
.pop-up-model-home .max-min-price .form-group label {
    width: 135px;
    margin-top: 7px;
    margin-bottom: auto;
}
.pop-up-model-home .padding-search {
    padding: 0px 6px 0px 0px;
}
.pop-up-model-home .padding-search2 {
    padding: 0px 0px 0px 6px;
}
.pop-up-model-home .radius-btn-search .form-control {
    background-color: transparent;
}
.pop-up-model-home .modal-header button.close:focus, .pop-up-model-home .modal-header button.close:active {
    border: none!important;
    outline: none!important;
}
.radius-btn-search .form-check-inline .form-check-input {
    margin-bottom: 3px;
}
.radius-btn-search {
    margin-bottom: 0px!important;
}
.pop-up-model-home .radius-btn-search .form-check-inline {
    margin-right: 30px;
}
.pop-up-model-home .col-sm-4 h6 {
    font-size: 16px;
    margin: 0px 0px 7px;
    color: #97CB5F;
    text-align: center;
}
.pop-up-model-home .max-min-price2 .form-group {
    display: inline-flex;
    width: 100%;
}
.pop-up-model-home .max-min-price2 .form-group label {
    width: 45px;
    margin-top: 7px;
    margin-bottom: auto;
}
.pop-up-model-home fieldset .form-control  {
    background-color: #ffffff!important;
}
.search-show-room {
    display: inline-flex;
    margin-bottom: 6px;
}
.search-show-room input {
    margin-right: .3125rem;
    position: static;
    margin-top: 1px;
}
.search-show-room label {
    font-weight: 500;
    margin-top: -2px;
    font-size: 14.4px;
    margin-right: 15px;
}
.market-radius-flex, .market-radius-flex .form-group  {
    display: inline-flex;
}
.market-radius-flex label {
    width: 100px;
    font-style: italic;
    margin-top: 7px!important;
    margin-bottom: auto;
    text-align: right;
    margin-right: 15px;
}
.market-radius-flex input {
    width: 188px;
    text-align: left;
    align-content: flex-start;
}
.market-radius-flex svg {
    margin-top: 9px;
    margin-bottom: auto;
    margin-left: 10px;
    color: #565656;
    width: 17px!important;
    height: 17px!important;
    cursor: pointer!important;
}
.market-radius {
    margin-bottom: 25px;
}
.no-record-home {
    padding: 3px 12px 5px!important;
    color: rgba(0,0,0,.4);
    font-weight: 500;
    font-family: 'Lato', sans-serif!important;
}
p.fullmarket-data-home i a{
    color: #4f4f4f;
    text-decoration: underline!important;
}
p.fullmarket-data-home i a:hover {
    color: #1e5699;
    text-decoration: underline!important;
}
.option-msa .optionContainer span.notFound {
    background-color: #fff6f6;
    color: #9f3a38;
    text-align: center;
    font-size: 13px;
    padding: .4125rem .625rem;
    border: 1px solid #9f3a38;
    border-radius: 3px;
    display: block; 
}
@media (min-width: 576px) {
.pop-up-model-home.modal-dialog {
    max-width: 1150px;
    margin: 1.75rem auto;
}
}
.show-room-only label {
    position: relative;
    left: 18px;
    top: 2px;
}
.show-room-only input {
    position: absolute;
    left: 34px;
}
.city-selection, .city-selection2 {
margin-bottom: 10px;
}
.city-selection .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    width:100%;
    background-color: #f7f5f5;
}
.city-selection .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"], .city-selection2 .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 1.5px 0px ;
}
.city-selection .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input, .city-selection2 .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
    padding: 7px 4px;
    font-size: 14px!important;
}
.city-selection .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
.city-selection2 .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #bebdbd!important;
    border-width: 1px!important;
}
.city-selection .MuiInputBase-root:focus,
.city-selection2 .MuiInputBase-root:focus {
    outline: 0px!important;
}
.city-selection .MuiInputBase-root:hover,
.city-selection2 .MuiInputBase-root:hover {
    outline: 0px!important;
}
.city-selection .MuiChip-root,
.city-selection2 .MuiChip-root {
    color: rgb(255 255 255);
    height: 25px;
    border-radius: 10px;
    background-color: #3670b5;
}
.city-selection .MuiChip-deleteIcon,
.city-selection2 .MuiChip-deleteIcon {
    color: rgb(255 255 255);
    width: 16px;
    cursor: pointer;
    height: 16px;
}
.city-selection .MuiChip-deleteIcon:hover,
.city-selection2 .MuiChip-deleteIcon:hover {
    color: rgb(251 251 251 / 80%);
}
.city-selection .MuiOutlinedInput-root,
.city-selection2 .MuiOutlinedInput-root {
    border-radius: 2px;
}
.city-selection .Mui-focused,
.city-selection2 .Mui-focused {
    outline: 0!important;
    border: none!important;
}
.city-selection .MuiFormHelperText-root.Mui-error,
.city-selection2 .MuiFormHelperText-root.Mui-error {
    height: fit-content;
    background-color: #fff6f6;
    border: 1px solid #9f3a38;
    color: #9f3a38;
    border-radius: 3px;
    font-size: 13px;
    text-align: center;
    padding: .4125rem .625rem;
    z-index: 10;
    width: 100%;
    margin-left: 0px;
}
.homepage .home-property .content-property svg {
    margin-right: 3px;
}
.city-selection2 .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    background-color: #f7f5f5;
}
div.home-content .container{
    padding-top: 0px;
}
.banner-homepage h1{
    margin-bottom: 15px;
    color: #000;
    font-size: 36px;
    font-weight: 600;
}
.banner-homepage p{
    font-size: 15px;
    color: #58585a;
    margin-bottom: 0px;
}
.banner-homepage p span{
    color: #97cb5f;
}
.home-property .content-property p{
    margin-bottom: 4px;
}
@media (max-width: 1200px){
    .home-content .home-property .carousel-root{
        width: 100%;
    }
    .property-details .home-property .carousel-root{
        width: 100%;
    }
    .home-content .home-property .content-property{
        width: 100%;
    }
}
@media (min-width: 1200px){
    .slider-below .col-md-6:first-child{
        padding-right: 7px;
    }
    .slider-below .col-md-6:last-child{
        padding-left: 7px;
    }
}
.tags-tss .redtag-s{
    background-color: #EF5823;
    border-color: #EF5823;
    color: #ffffff;
    margin-left: 0;
    font-size: 12px;
    line-height: 1.5;
    display: inline-block;
    padding: 3.7px 9px;
    font-weight: 600;
    vertical-align: initial;
    margin: 0 .14285714em;
    border: 0 solid transparent;
    border-radius: 3px;
}
.tags-tss .bluetag-s {
    background-color: #9660c9;
    border-color: #9660c9;
    color: #ffffff;
    margin-left: 0;
    font-size: 12px;
    line-height: 1.5;
    display: inline-block;
    padding: 3.7px 9px;
    font-weight: 600;
    vertical-align: initial;
    margin: 0 .14285714em;
    border: 0 solid transparent;
    border-radius: 3px;
}
.tags-tss .greentag-s {
    background-color: #38a83c;
    border-color: #38a83c;
    color: #ffffff;
    margin-left: 0;
    font-size: 12px;
    line-height: 1.5;
    display: inline-block;
    padding: 3.7px 9px;
    font-weight: 600;
    vertical-align: initial;
    margin: 0 .14285714em;
    border: 0 solid transparent;
    border-radius: 3px;
}
.tags-tss .orangetag-s {
    background-color: #cd156b;
    border-color: #cd156b;
    color: #ffffff;
    margin-left: 0;
    font-size: 12px;
    line-height: 1.5;
    display: inline-block;
    padding: 3.7px 9px;
    font-weight: 600;
    vertical-align: initial;
    margin: 0 .14285714em;
    border: 0 solid transparent;
    border-radius: 3px;
}
.slider-below{
    margin-top: 20px;
}
.market-news{
    background: #e85f91;
    color: #fff;
    padding: 2px 5px;
    font-size: 12px;
    border-radius: 3px;
    font-weight: 400;
    margin-right: 5px;
}
.anatomy-news{
    background: #7d87ce;
    color: #fff;
    padding: 2px 5px;
    font-size: 12px;
    border-radius: 3px;
    font-weight: 400;
    margin-right: 5px;
}
.tech-news{
    background: #cc1e24;
    color: #fff;
    padding: 2px 5px;
    font-size: 12px;
    border-radius: 3px;
    font-weight: 400;
    margin-right: 5px;
}
.coffee-news{
    background: #b4d69c;
    color: #fff;
    padding: 2px 5px;
    font-size: 12px;
    border-radius: 3px;
    font-weight: 400;
    margin-right: 5px;
}
.spon-news{
    background: #d8a0c2;
    color: #fff;
    padding: 2px 5px;
    font-size: 12px;
    border-radius: 3px;
    font-weight: 400;
    margin-right: 5px;
}
.best-practices{
    background: #db875e;
    color: #fff;
    padding: 2px 5px;
    font-size: 12px;
    border-radius: 3px;
    font-weight: 400;
    margin-right: 5px;
}
.fin-news{
    background: #db9f51;
    color: #fff;
    padding: 2px 5px;
    font-size: 12px;
    border-radius: 3px;
    font-weight: 400;
    margin-right: 5px;
}
.resources-news{
    background: #9660c9;
    color: #fff;
    padding: 2px 5px;
    font-size: 12px;
    border-radius: 3px;
    font-weight: 400;
    margin-right: 5px;
}
.recent-news{
    background: #81d742;
    color: #fff;
    padding: 2px 5px;
    font-size: 12px;
    border-radius: 3px;
    font-weight: 400;
    margin-right: 5px;
}
.featured-news{
    background: #82ada1;
    color: #fff;
    padding: 2px 5px;
    font-size: 12px;
    border-radius: 3px;
    font-weight: 400;
    margin-right: 5px;
}
.five-news{
    background: #4db2ec;
    color: #fff;
    padding: 2px 5px;
    font-size: 12px;
    border-radius: 3px;
    font-weight: 400;
    margin-right: 5px;
}
.title-hover a{
    color: #58585a;
}
.title-hover a:hover{
    color: #97CB5F !important;
}
.try-it-btn
{
    width: 50%;
    text-align: left;
}
.pricing-in-btn
{
    width: 50%;
    text-align: right;
}
@media only screen and (max-width: 576px){
    body .img-home-property img{
        height: 100%;
    }
}
.inputbox-search .MuiAutocomplete-endAdornment .MuiAutocomplete-popupIndicator{
    display: none;
}
@media (max-width: 599px){
    button.button-search-home
    {
        top:0px
    }
    .advanced-search-text {
        width: 100%;
        display: block;
        padding: 5px 20px;
        text-align: center;
        top:0px;
    }
    .box-search-home .inputbox-search,.box-search-home button.button-search-home
    {
        width: 100%;
        border-radius: 5px!important;
    }
}
@media (max-width: 700px){
.inputbox-search {
    width: 100%;
}
.box-search-home button.button-search-home
{
  height: auto;
    border-radius: 5px!important;
}
.box-search-home button.button-search-home
{
  height: auto;
    border-radius: 5px!important;
}
}
@media (max-width: 794px){
    .menu-bar
    {
        top: 0px;
    }
    .homepage {
        position: relative;
        top: 0px;
    }
    .head-login-btn
{
    margin-top: 11px;
}

.listrvparks .banner-homepage{
    padding-left: 40px;
    padding-top: 75px;
}
.text-1 {
    font-size: 30px;
    line-height: normal;
}
.listrvparks .pag{
    padding: 20px 60px;
}
.listrvparks .left-side-text-instant{
    font-size: 22px;
}
.listrvparks .marketreportnew-section .reportcover img{
    width: 50%;
    text-align: center;
}
.listrvparks .marketreportnew-section .reportcover{
    margin-top: 0px;
}
.listrvparks .marketreportnew-content{
    padding: 30px 0px 30px 20px;
}
.fluid-col .instant-ama {
    width: calc(111vw - 120px);
}
    .banner-homepage {
        padding-top: 60px;
    }
    .box-search-home
    {
        padding: 0px;
    }
 
}
@media (max-width: 769px){
    .listrvparks .banner-homepage{
        padding-bottom: 50px;
        padding-top: 80px;
    }

}

@media (max-width: 1000px){
    .banner-cont {
        width: 100%;
        margin-left: 0px;
        margin-bottom: 15px;
    }
    .property-de-main {
        display: inline;
    }
   
    .about-in-img {
        padding: 0px 30px 30px 30px;
        width: 100%;
    }
    .try-btn-cont
    {
        width: 100%;
    }

    .listrvparks .banner-homepage{
        padding-left: 0;
        padding-right: 0px;
    }
    .banner-homepage h1{
        font-size: 25px;
    }
    .text-1 {
        font-size: 20px;
        line-height: normal;
    }
    .listrvparks .pag {
        padding: 15px 8px;
    }
    .listrvparks .icon-maindiv{
        margin-bottom: 15px;
    }
    .left-side .respon{
        margin-top: 0px !important;
    }
    .listrvparks .two-btn .discoverbtn{
        padding: 0px;
    }
    .listrvparks .discoverbtn{
        padding-top: 11px !important;
    }
    .listrvparks .marketreportnew-section .reportcover{
        position: inherit;
        text-align: center;
    }
    
 
}