.search-page {
    min-height: calc(114vh - 400px);
    background-color: #ffffff;
}
.search-page .row {
    margin: auto!important;
    width: 100%;
}
.search-page .select-property-search .dropdown button span.checked-close {
    border: none!important;
    padding-left: 9px!important;
}
.searchbar-top {
    background-color: #f2f2f2;
    display: inline-flex;
    width: 100%;
    padding-left:15px;
    padding-right:15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    left: 0px !important;
    width: 100% !important;
    z-index: 100 !important;
    position: fixed !important;
    top: 103.5px !important;
}
.location-search .radius-btn-search .form-check-inline {
    margin-right: 14px;
}
.myBox-relative {
    position: relative;
    width: 100%;
    height: 100%;
}
.searchbar-top form {
    display: inline-flex;
}
.searchbar-top h5 {
    margin-bottom: 0px;
    background-color: #EF5823;
    padding: 11.7px 18px;
    font-weight: 500;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 16px;
    font-family: 'Lato', sans-serif!important;
}
.search-heading {
    background-color: #fff;
    border: 1px solid #b1b3b0;
    border-radius: 2px;
    cursor: pointer!important;
    margin-top: 4px;
    margin-bottom: 4px;
}
.width10 {
    width: 10px!important;
}
.search-page .search-resultdropdown .dropdown button .checked-close .width12 {
    margin-right: -2px!important;
}
.search-page .select-property-search.search-resultdropdown .dropdown button
{
    width: 16em!important;
    text-align: left;
}

.search-page .select-amenities-search.search-resultdropdown .dropdown button
{
    width: 13em!important;
    text-align: left;
    position: relative;
    top: 4px;
    border: 1px solid rgba(34,36,38,.15);
 }
  .select-amenities-search .dropdown-item
{
    border: 0px !important;

}
.select-amenities-search .dropdown button {
    color: #555;
    position: relative;
    display: inline-block;
    cursor: pointer;
    height: 45.36px!important;
    background: #fff;
    outline: 0;
    border-radius: 3px;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 20%);
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    font-weight: 600;
    letter-spacing: .6px;
    padding-right: 1em!important;
    font-size: 11pt!important;
    width: 12em;
    padding: 12px;
    border-top-right-radius: 0!important;
    border-bottom-right-radius: 0!important;
}

.search-page .select-property-search.search-resultdropdown .dropdown button .checked-close,.search-page .select-amenities-search.search-resultdropdown .dropdown button .checked-close
{
    text-align: right;
    right: 0px;
    position:absolute;
}
 .checked-close .arrow-bottom {
    top: -2.8px!important;
}
.width12 {
    width: 18px!important;
    margin: 0 1px!important;
}
.searchbar-top .search-heading a {
    color: #555759;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: normal;
    text-decoration: none;
    cursor: pointer;
    vertical-align: middle;
    padding: 8px 7px 7px;
    display: inline-block;
    font-family: 'Roboto'!important;
}
.search-page .optionListContainer ul.optionContainer .highlight {
    background: #1e5699!important;
    color: #ffffff;
}
.search-page .optionListContainer ul.optionContainer li:hover {
    background: #1e5699!important;
    color: #fff;
    cursor: pointer;
}
.searchbar-top .search-heading a svg {
    font-size: 14px;
    margin-left: 1px;
    margin-right: 5px;
}
.searchbar-top .form-group .form-control:focus {
    box-shadow: none!important;
    outline: none!important;
    border: 1px solid #97CB5F;
}
.location-search #multiselectContainerReact ._2iA8p44d0WZ-WqRBGcAuEV:focus {
    border: 1px solid #97CB5F;
}
.error-ad-searchresult {
    width: 100%;
    height: fit-content;
    background-color: #fff6f6;
    border: 1px solid #9f3a38;
    color: #9f3a38;
    border-radius: 2px;
    font-size: 13px;
    padding: 5px;
    text-align: center;
    z-index: 10;
    margin-bottom: 3px;
    margin-top: -5px;
}
.entervalidaddress {
    display: block;
    margin-bottom: 2px;
    padding: .4125rem .625rem;
}
.checked-close {
    border-radius: 4px;
    position: relative;
    top: 1px;
}
.checked-close.amenities-close 
    {
        position: absolute;
        top: 8px;
        right: 0px;
    }
    .card-fields.card-fields-new .price-error2
    {
        position: relative;
        top:2px;
    }
.checked-close .closed-x {
    color: #d71921;
    font-size: 17px;
    margin: 0px;
    width: 20px;
    position: relative;
    top: -2px;
    padding: 10px 6px 9px 0px;
}
.checked-close .closed-x.amenities-arrow-button
{
padding: 5px;
}
button.apply-search-btn {
    height: 35px;
    border-radius: 2px;
    width: 70px;
    background-color: #97CB5F;
    border: 1px solid #97CB5F;
    padding: 0px;
    font-family: 'Lato', sans-serif!important;
    font-weight: 700;
    color: #ffffff;
    font-size: 13px;
    cursor: pointer;    
    margin-left: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 10px;
}
button.clear-search-btn {
    height: 35px;    
    border-radius: 2px;
    color: #ffffff!important;
    width: 70px;
    background-color: #565656;
    border: 1px solid #565656!important;
    padding: 0px;
    font-family: 'Lato', sans-serif!important;
    font-weight: 700;
    color: #ffffff;
    font-size: 13px;
    cursor: pointer;  
    margin-top: 5px;
    margin-bottom: 5px; 
}
button.clear-search-btn:focus, button.clear-search-btn:active, button.clear-search-btn:hover {
    background-color: #565656!important;
    border: 1px solid #565656!important;
    box-shadow: none!important;
    outline: none!important;
}
button.apply-search-btn:hover, button.apply-search-btn:active, button.apply-search-btn:focus {
background-color:  #97CB5F!important;
border: 1px solid  #97CB5F!important;
color: #ffffff;
outline: 0px;
box-shadow: none!important;
}
/*--- fields ---*/
.location-search {
    position: absolute;
    background-color: #ffffff;
    border-radius: 3px;
    border: 1px solid #ddd;
    padding: 15px 15px;
    box-shadow:0px 3px 3px 1px rgb(156 151 151 / 25%);
    width: 360px;
    top: 43px;
    margin-left: -1px;
    z-index: 99;
}
.location-search input::placeholder {
    font-size: 14px;
}
.img-size-searchresult {
    width: 383px!important;
    height: 238px!important;
    background: rgba(0,0,0,.05);
    border-radius: .28571429rem .28571429rem 0 0!important;
}
.img-size-searchresult img {
    object-fit: cover!important;
}
.location-search .form-group .form-control {
    height: 34px;
    background: #f7f5f5;
    border: 1px solid #cccc;
    border-radius: 2px;
    font-size: 14px;
}
.background-search .form-group .form-control {
    background: #ffffff;
}
.background-search label {
    margin-bottom: 0px!important;
}
.location-search #multiselectContainerReact ._2iA8p44d0WZ-WqRBGcAuEV {
    border-radius: 2px;
    background-color: #f7f5f5;
}
.location-search #multiselectContainerReact ._3vt7_Mh4hRCFbp__dFqBCI {
    margin-bottom: 10px;
}
.location-search #multiselectContainerReact input {
    margin-bottom: 3px;
    margin-top: 0px;
}
.location-search input.city-input-text {
    height: 34px;
    background: #f7f5f5;
    border: 1px solid #ccc;
    border-radius: 2px;
    width: 85.6%;
    padding-left: 7px;
    padding-right: 7px;
    margin-left: 4px;
    margin-bottom: 12px;
}
.location-search input.city-input-text:focus, .location-search #multiselectContainerReact:focus {
    outline: 0px!important;
    border: 1px solid #99c753!important;
}
.location-search .disply-flex label  {
    margin-top: 6px;
    margin-bottom: auto;
    margin-right: 15px;
    color: #565656;
    font-weight: 400;
    font-size: 14px;
    width: 32px;
}
.location-search .disply-flex {
    margin-bottom: 10px;
}
.location-search label {
    margin-top: 0px;
    margin-bottom: 3px;
    margin-right: 15px;
    color: #565656;
    font-weight: 400;
    font-size: 14px;
}
.location-search .form-group {
    margin-bottom: 7px;
}
.location-search .within-field, .location-search .within-field .form-group {
    display: inline-flex;
    width: 100%;
    margin-top: 3px;
}
.location-search .within-field label {
    width: 32px;
    margin-top: 5px;
    margin-bottom: auto;
}
.location-search .within-field select {
    width: 64px;
    padding: 6px;
}
.location-search .within-field span {
    width: 100%;
    margin-top: 12px;
    margin-bottom: auto;
    margin-left: 10px;
    color: #565656;
    font-size: 12px;
    position: relative;
    left: -55px;
}
.location-search #multiselectContainerReact ._7ahQImyV4dj0EpcNOjnwA {
    padding: 2px 6px;
    background: #1e5699;
    margin-bottom: 3px;
    white-space: break-spaces;
}
.location-search .padding-search {
    padding: 0px 6px 0px 0px;
}
.location-search .padding-search2 {
    padding: 0px 0px 0px 6px;
}
.width-47 {
    width: 43.8%;
    margin-bottom: 10px;
}
.width-6 {
    width: 6%;
    margin: 9px 10px;
    text-align: center;
    font-size: 15px;
    padding: 0px;
}
.location-search .radius-btn-search .form-control {
    background-color: transparent;
}
.location-search .city-selection .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    width: 280px!important;
    background-color: #f7f5f5;
}
.width-47 .form-group {
    margin-bottom: 0px;
}
.search-show-room2 {
    display: inline-flex;
    margin-bottom: 6px;
}
.search-show-room2 input {
    margin-right: .3125rem;
    position: static;
    margin-top: 3px;
}
.search-show-room label {
    font-weight: 500;
    margin-top: -2px;
    font-size: 14.4px;
    margin-right: 15px;
}
.location-search h3 {
    font-size: 16px;
    margin: 0px 0px 12px;
    color: #EF5823;
    text-align: left;
    text-align: center;
}
.location-search h3 span {
    font-size: 13px;
}
.location-search h6 {
    font-size: 15px;
    color: #555759;
    margin-bottom: 4px;
    margin-top: 6px;
}
/*--- property list content ---*/
.p-list-search {
    width: 41%!important;
    padding: 12px 0px 0px 15px;
}
.m-list-search {
    width: 59%!important;
    height: 100vh;
    padding-top: 120px;
    margin-top: 0px;
    position: sticky;
    top: 0px;
}
.grid-and-list, .m-list-search {
    margin-top: 0;
    position: -webkit-sticky;
    position: sticky;
}
.grid-and-list {
    padding: 0px 0px 12px 0px;
    height: auto!important;
    padding-top: 120px;
    margin-top: 0px;
    position: sticky;
    top: 12px;
    z-index: 12;
    background-color: #fff;
}
.gird-list-categories {
    width: 16%!important;
}
.property-link {
    width: 100%;
    display: flex;
}
.link-grid {
    width: 100%;
    display: flex;
}
.gird-list-categories button {
    background: rgb(255 255 255)!important;
    color: rgba(0,0,0,.6)!important;
    font-weight: 400;
    border-radius: 3px;
    display: inline-block;
    min-height: 1em;
    outline: 0;
    border: 1px solid #dedede;
    vertical-align: initial;
    background: #e0e1e2 none;
    color: rgba(0,0,0,.6);
    margin: 0 .25em 0 0;
    padding: 9px 10px 8px;
    line-height: 1em;
    font-style: normal;
    text-align: center;
    text-decoration: none;
}
.gird-list-categories button:hover {
    border: 1px solid #7d7d7d!important;
}
.gird-list-categories button:focus {
    box-shadow: none!important;
}
.list-viewbutton {
    padding: 8px 10.89px!important;
}
.gird-list-categories button svg {
    margin: 0!important;
    vertical-align: top;
    font-size: 14px;
    padding-top: .8px;
    color: #232323;
}
.disabled-grid {
    background-color: transparent!important;
    box-shadow: none!important;
}
.active-grid {
    background: rgba(0,0,0,.05)!important;
    color: rgba(0,0,0,.6)!important;
}
.active-grid.disabled, .active-grid:disabled {
    background-color: #f2f2f2!important;
}
.disabled-grid.disabled, .disabled-grid:disabled {
    background-color: #f2f2f2!important;
}
.property-list-grid {
    border-radius: .28571429rem;
    border: 1px solid rgba(34,36,38,.15);
    display: inline-flex;
    width: 98%;
    max-width: 100%;
}
.property-list-grid a {
    text-decoration: none!important;
}
.property-list-grid .tag-wishlist3 button img {
    top: 1px;
    left: 0px;
}

.image-grid {
    width: 21.9%;
    height: 118.74px;
    position: relative;
    flex: 0 0 auto;
    display: block;
    float: none;
    margin: 0;
    padding: 0;
    background: rgba(0,0,0,.05);
}
.image-grid img {
    width: 100%;
    height: 100%;
}
.width-content-39 {
    width: 100%;
    padding-left: 15px;
    padding-top: 13px;
    padding-right: 0px;
    padding-bottom: 5px;
}
.width-content-392 {
    width: 100%;
    padding-left: 15px;
    padding-top: 13px;
    padding-right: 15px;
    padding-bottom: 5px;
}
.width-content-392 p {
    color: #000000de;
    margin-bottom: 15px;
    font-size: 13px;
} 
.width-content-392 p i a, p.fullmarket-data i a{
    color: rgba(0,0,0,.85);
    text-decoration: underline!important;
}
.width-content-392 p i a:hover,  p.fullmarket-data i a:hover {
    color: #1e5699;
    text-decoration: underline!important;
}
.width-content-39 h6 {
    width: 100%;
    display: block;
    font-size: 13px;
    color: #000000de;
    font-family: 'Lato', sans-serif!important;
}
.width-content-39 p {
    width: 100%;
    display: block;
    font-size: 13px;
    margin-bottom: 5px;
    color: #000000de;
    font-family: 'Lato', sans-serif!important;
}
.width-content-39 p svg {
    margin-right: 3px;
}
.check-showonlyroom .form-check {
    margin-bottom: 2px;
}
.width-content-39 h5 {
    white-space: nowrap;
    text-overflow: ellipsis!important;
    overflow: hidden;
    width: 100%;
    display: block;
    font-size: 14.95px;
    font-family: 'Lato', sans-serif!important;
    color: #555759;
    font-weight: 700;
}
.width-content-39 h5 a {
    display: inline-block;
    margin-bottom: 8px;
    font-weight: 700;
    white-space: nowrap!important;
    overflow: hidden!important;
    font-size: 14.95px;
    font-family: 'Lato', sans-serif!important;
    color: #555759;
    text-overflow: ellipsis!important;
    width: 295px!important;
    text-decoration: none;
}
.width-content-392 h6 {
    width: 100%;
    display: block;
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 16px!important;
    color: #000000de;
    width: 295px!important;
    font-family: 'Lato', sans-serif!important;
    white-space: nowrap!important;
    overflow: hidden!important;
}
.width-content-392 h4 {
    font-size: 18px;
    color: #000000de;
    font-weight: 700;
    font-family: 'Lato', sans-serif!important;
}
.width-content-392 h4.font16 {
    font-size: 16px!important;
}
.tag-wishlist {
    position: absolute;
    right: 5px;
    display: inline-flex;
    top: 3px;
    margin: 0px;
}
.tag-wishlist button {
    width: 20px;
    height: 20px;
    padding: 0px;
}
.tag-wishlist button:focus, .tag-wishlist button:active {
    background-color: transparent!important;
    box-shadow: none!important;
    outline: none!important;
    border: none!important;
    width: 20px!important;
    height: 20px!important;
    padding: 0px!important;
}
.tag-wishlist button:not(:disabled):not(.disabled):active {
    background-color: white!important;
    box-shadow: none!important;
    outline: none!important;
    border: none!important;
    width: 33px!important;
    height: 33px!important;
    padding: 5px!important;
    border-radius: 16px!important;
}
.tag-wishlist2 {
    position: absolute;
    right: 16px;
    display: inline-flex;
    top: 2px;
    margin: 0px;
    z-index: 10;
}
.tag-wishlist2 button {
padding: 0px;
outline: none!important;
box-shadow: none!important;
border: none!important;
background-color: #ffffff!important;
width: 33px!important;
height: 33px!important;
border-radius: 16px!important;
}
.tag-wishlist2 button img {
box-shadow: none!important;
border: none!important;
background-color: transparent!important;
width: 18px!important;
height: 18px!important;
position: relative!important;
top: 0px!important;
}
.tag-wishlist2 button:focus, .tag-wishlist2 button:active, .tag-wishlist2 button:hover {
box-shadow: none!important;
outline: none!important;
border: none!important;
background-color: #ffffff!important;
width: 33px!important;
height: 33px!important;
border-radius: 16px!important;
padding: 0px!important;
}
.tag-wishlist2 button:not(:disabled):not(.disabled):active {
box-shadow: none!important;
outline: none!important;
border: none!important;
background-color: #ffffff!important;
width: 33px!important;
height: 33px!important;
border-radius: 16px!important;
padding: 0px!important;
}
.tag-wishlist3 {
    position: absolute;
    right: 8px;
    display: inline-flex;
    top: 8px;
    margin: 0px;
    z-index: 10;
}
.tag-wishlist3 button {
padding: 0px;
outline: none!important;
box-shadow: none!important;
border: none!important;
background-color: #ffffff!important;
width: 27px!important;
height: 27px!important;
border-radius: 16px!important;
}
.tag-wishlist3 button img {
box-shadow: none!important;
border: none!important;
background-color: transparent!important;
width: 20px!important;
height: 20px!important;
position: relative!important;
top: .5px!important;
}
.tag-wishlist3 button:focus, .tag-wishlist3 button:active, .tag-wishlist3 button:hover {
box-shadow: none!important;
outline: none!important;
border: none!important;
background-color: #ffffff!important;
width: 27px!important;
height: 27px!important;
border-radius: 16px!important;
padding: 0px!important;
}
.tag-wishlist3 button:not(:disabled):not(.disabled):active {
box-shadow: none!important;
outline: none!important;
border: none!important;
background-color: #ffffff!important;
width: 27px!important;
height: 27px!important;
border-radius: 16px!important;
padding: 0px!important;
}
.tag-wishlist button:not(:disabled):not(.disabled):active img {
    position: relative;
    left: -0.9px!important;
    top: -3px!important;
}
.tag-wishlist button, .tag-wishlist button:hover, .tag-wishlist button:focus, .tag-wishlist button:active {
    background-color: transparent!important;
    border: none!important;
    outline: 0;
    box-shadow: none!important;
}
.myBox1 .margin-b-15 {
    padding-right: 14px;
}
.myBox1 .col-sm-6.list-grid-search {
    padding: 0px 15px 0px 0px!important;
}
.list-grid-search .home-property .content-property .col-sm-6.padding-grid-remove {
    padding: 0px 0px 0px 10px!important;
}
.list-grid-search .home-property .content-property .col-sm-6.padding-grid-remove2 {
    padding: 0px 10px 0px 0px!important;
}
/* Box styles */
.myBox1 {
    height: 393px;
    overflow-y: scroll;
    }
    
    /* Scrollbar styles */
    .myBox1::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    }
    
    .myBox1::-webkit-scrollbar-track {
    background: #e5e5e5;
    border-radius: 10px;
    }
    
    .myBox1::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #c3c3c3;  
    }
    
    .myBox1::-webkit-scrollbar-thumb:hover {
    background: #999;  
    }
.myBox1 a {
    text-decoration: none!important;
}
.property-list-grid:hover {
    cursor: pointer;
    box-shadow: 0 0 6px 0 rgba(0,0,0,.25);
}
.save-wishlist {
    margin-right: 10px;
    font-size: 12px;
}
.save-wishlist span{
    margin-top: 14px;
    display: block;
    margin-bottom: 14px;
}
.save-wishlist img {
    width: 14px;
    margin-top: 0px;
}
.select-orderby select {
    height: 35px;
    border-radius: 3px;
    font-size: 13px;
    width: auto;
    padding-left: 10px;
    color: #242424;
    margin-top: 5px;
    margin-bottom: 5px;
}
.select-orderby  select .optionbox {
    height: 35px;
    padding: 10px!important;
}
.select-orderby select:focus, .select-orderby select:active {
    box-shadow: none!important;
}
/*-- seaarch-pagination --*/
.seaarch-pagination {
    display: inline-flex;
    vertical-align: middle;
    box-shadow: 0 1px 2px 0 rgba(34,36,38,.15);
}
.seaarch-pagination li button {
    display: inline-flex;
    vertical-align: middle;
    border-right: 1px solid rgba(34,36,38,.15);
    border-top: 1px solid rgba(34,36,38,.15);
    border-bottom: 1px solid rgba(34,36,38,.15);
    height: 30px;
    width: 32px;
    border-radius: 0px;
    text-align: center;
    line-height: 1;
    font-size: 12px;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
    flex: 0 0 auto;
    margin: 0px;
    cursor: pointer;
}
.seaarch-pagination li:first-child button::before {
    position: absolute;
    content: "«";
    top: 11px;
    left: 18px;
    right: 0;
    height: 100%;
    width: 1px;
}
.seaarch-pagination li:nth-child(2) button::before {
    position: absolute;
    content: "⟨";
    top:11px;
    left: 18px;
    right: 0;
    height: 100%;
    width: 1px;
}
.seaarch-pagination .MuiPaginationItem-page.Mui-disabled {
    opacity: 10;
}
.seaarch-pagination li:first-child button::before {
    position: absolute;
    content: "«";
    top: 8px;
    left: 15px;
    right: 0;
    height: 70%;
    width: 1px;
}
.seaarch-pagination li:nth-child(2) button::before {
    position: absolute;
    content: "⟨";
    top:8px;
    left: 15px;
    right: 0;
    height: 70%;
    width: 1px;
}
.seaarch-pagination li:last-child button::before {
    position: absolute;
    content: "»";
    top: 8px;
    left: 15px;
    right: 0;
    height: 70%;
    width: 1px;
}
.seaarch-pagination li:nth-last-child(2) button::after {
    position: absolute;
    content: "⟩";
    top:8px;
    left: 15px;
    right: 0;
    height: 70%;
    width: 1px;
}
.seaarch-pagination li button svg {
    display: none;
}
.last-property:last-child .margin-b-15 {
    margin-bottom: 0px;
}
.seaarch-pagination li:first-child button {
    border-left: 1px solid rgba(34,36,38,.15);
    border-top-left-radius: .28571429rem;
    border-bottom-left-radius: .28571429rem;
}
.seaarch-pagination li:last-child button {
    border-top-right-radius: .28571429rem;
    border-bottom-right-radius: .28571429rem;
}
.seaarch-pagination li .MuiPaginationItem-ellipsis {
    display: inline-flex;
    vertical-align: middle;
    text-align: center;
    line-height: 1;
    border-right: 1px solid rgba(34,36,38,.15);
    border-top: 1px solid rgba(34,36,38,.15);
    border-bottom: 1px solid rgba(34,36,38,.15);
    border-radius: 0px;
    height: 30px;
    width: 32px;
    margin: 0px;
    padding: 8px 16px;
}
.table-pagination div {
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
}
.table-pagination .seaarch-pagination button:focus {
    outline: none!important;
}
.seaarch-pagination button.Mui-selected:hover, .seaarch-pagination button.Mui-selected.Mui-focusVisible, .seaarch-pagination button.Mui-selected {
    background-color: rgba(0,0,0,.05);
}
.tags-tss-s {
    position: absolute;
    left: 5px;
    display: inline-flex;
    top: 2px;
    margin: 5px;
}
.tags-tss-s .bluetag-s {
    background-color: #274C77;
    border-color: #274C77;
    color: #ffffff;
    margin-left: 0;
    font-size: 12px;
    line-height: 1.5;
    display: inline-block;
    padding: 3.7px 9px;
    font-weight: 600;
    vertical-align: initial;
    margin: 0 .14285714em;
    border: 0 solid transparent;
    border-radius: 3px;
}
.tags-tss-s .redtag-s{
    background-color: #EF5823;
    border-color: #EF5823;
    color: #ffffff;
    margin-left: 0;
    font-size: 12px;
    line-height: 1.5;
    display: inline-block;
    padding: 3.7px 9px;
    font-weight: 600;
    vertical-align: initial;
    margin: 0 .14285714em;
    border: 0 solid transparent;
    border-radius: 3px;
}
.saletag-s{
    background-color: #7e2d25!important;
    border-color: #7e2d25!important;
}
.bluetag-ss {
    background-color: #002d73!important;
    border-color: #002d73!important;
}
.redtag-s {
    background-color: #D90429!important;
    border-color: #D90429!important;
}
.bluetag-s.redtag-s
{
    background-color: #cd156b!important;
    border-color: #cd156b!important;
}
.orangetag-s {
    background-color: #7C6F02!important;
    border-color: #7C6F02!important;
}
.greentag-s {
    background-color: #01890a!important;
    border-color: #01890a!important;
}
.yellowtag-s {
    background-color: #7c0187!important;
    border-color: #7c0187!important;
}
.myBox-s {
    height: auto;
    overflow-y: auto;
    max-height: 240px;
}
   /* Scrollbar styles */
   .myBox-s::-webkit-scrollbar {
    width: 5px;
    height: 10px;
    }
    
    .myBox-s::-webkit-scrollbar-track {
    background: #e5e5e5;
    border-radius: 10px;
    }
    
    .myBox-s::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #c3c3c3;  
    }
    
    .myBox-s::-webkit-scrollbar-thumb:hover {
    background: #999;  
    }
.dropdown-slist-s {
    cursor: pointer;
    position: absolute;
    width: 78.5%;
    height: auto;
    max-height: 260px;
    border: 1px solid rgba(34,36,38,.15);
    text-align: left;
    overflow-y: auto;
    background-color: #ffffff;
    line-height: 1.21428571em;
    left: 73px;
    list-style: none;
    z-index: 1;
    margin: -.78571429em;
    padding: 0px;
    transition: opacity .1s ease;
    border-top: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-color: #96c8da;
    box-shadow: 0 2px 3px 0 rgba(34,36,38,.15);
}
.emptydiv {
    width: 100px!important;
    display: inline-block;
}
.search-page .search-resultdropdown .dropdown button {
    height: 37px!important;
    border-radius: 2px!important;
    box-shadow: none!important;
    width: 11.1em!important;
    padding: 3px!important;
    padding-left: 5px!important;
    color: #555759!important;
    font-size: 14px!important;
    font-weight: 400!important;
    letter-spacing: normal!important;
    text-decoration: none!important;
    cursor: pointer!important;
    font-family: 'Roboto'!important;
    border-color: rgb(177 179 176)!important;
}
.search-page .dropdown-menu {
    min-width: 14rem!important;
}
.search-page .select-property-search .dropdown-menu.show {
    border: 1px solid rgba(34,36,38,.15)!important;
    border-color: rgb(177 179 176)!important;
    border-top: none!important;
}
.search-page .select-property-search .dropdown-menu.show button {
    padding-left: 14px!important;
    width: 237px!important;
}
.search-page .select-property-search .dropdown-menu.show {
    top: 1px!important;
   
}
.searchresult-input {
    cursor: pointer;
    word-wrap: break-word;
    white-space: normal;
    outline: 0;
    margin-right: 3px!important;
    transform: rotate(0);
    min-width: 17.4em!important;
    min-height: 37px!important;
    background: #fff;
    display: inline-block;
    color: #555759!important;
    font-size: 14px!important;
    font-weight: 400!important;
    letter-spacing: normal!important;
    text-decoration: none!important;
    cursor: pointer!important;
    font-family: 'Roboto'!important;
    box-shadow: none;
    border: 1px solid rgba(34,36,38,.15);
    border-radius: .28571429rem;
    transition: box-shadow .1s ease,width .1s ease;
    height: 36px!important;
    border-radius: 2px!important;
    border-left: 1px solid rgba(34,36,38,.15)!important;
    width: 100%;
    font-style: normal;
}
.searchresult-input .MuiInput-underline:before {
    border-bottom: none!important;
}
.searchresult-input .MuiInput-underline:after {
    border-bottom: none!important;
}
.searchresult-input .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child {
    padding: 9px 0px 6px 12px;
    font-size: 14px;
}
.searchresult-input .MuiAutocomplete-endAdornment {
    padding:1px 8px;
}
.searchresult-input input.MuiInputBase-input::placeholder {
    font-size: 14px!important;
    color: #555759!important;
}
.search-page li.home-search-list a {
    color: #555759!important;
    font-size: 14px!important;
    font-weight: 400!important;
    letter-spacing: normal!important;
    text-decoration: none!important;
    cursor: pointer!important;
    font-family: 'Roboto'!important;
    width: 100%;
    display: block;
}
.search-page .select-property-search .dropdown {
    margin-right: 3px;
}
.search-page .box-search-home {
    width: auto!important;
    padding-left: 1px!important;
    padding-right: 1px!important;
    margin: 0px;
    display: flex;
}
.search-page .dropdown-slist {
    cursor: pointer;
    position: absolute;
    width: 23.04%!important;
    height: auto!important;
    max-height: 209px!important;
    border: 1px solid rgb(177 179 176)!important;
    text-align: left;
    overflow-y: auto;
    background-color: #ffffff;
    line-height: 1.21428571em;
    top: 50px!important;
    left: 131px!important;
    list-style: none;
    z-index: 5;
    margin: -.78571429em;
    padding: 0px;
    transition: opacity .1s ease;
    border-top: none!important;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-color: rgb(177 179 176)!important;
    box-shadow: 0 2px 3px 0 rgba(34,36,38,.15);
}
.search-page .searchresult-input {
    border-color: rgb(177 179 176)!important;
    margin-top: 4px;
    margin-bottom: 4px;
}
.search-page .select-property-search{
    margin-top: 4px;
    margin-bottom: 4px;
}
.search-page .searchresult-input::placeholder {
    color: #555759;
    font-size: 14px;
    font-weight: 400;
    font-family: "Roboto"!important;
}
.gridviewproperty .content-property svg {
    margin-right: 3px;
}
.display-content {
    display: contents!important;
}
.gridviewproperty .property-img a{
    width: 100%;
    display: block;
    height: 100%;
}
.gridviewproperty .property-img {
    height: 150px !important;
    width: 41% !important;
    float: left;
    border-radius: 0px !important;
    background-size: 100% 100%;
}
.gridviewproperty .content-property{
    width: 59%;
    float: left;
}
.gridviewproperty a.link-grid {
    display: block;
}
.gridviewproperty .content-property{
    border: none !important;
}
.gridviewproperty .home-property{
    border: 1px solid #dddddd;
}
/*--- media ---*/
@media only screen and (min-width: 200px) and (max-width: 280px){
    .searchresult-input {
        margin: 12px;
    min-width: 16.4em!important;
    }
    .width-content-39 p {
        font-size: 12px;
    }
    li.home-search-list {
        padding: 8px 12px 6px!important;
    }
    .search-page .search-resultdropdown .dropdown button {
        width: 100%!important;
        margin: 0px 10px;
    }
    .search-page .dropdown-slist {
        width: 90.7%!important;
        top: 227px!important;
    left: 24px!important;
    }
    .searchbar-top form button.apply-search-btn {
        margin-left: 15px !important;
    }
    .gird-list-categories button.grid-viewbutton {
        position: absolute;
        left: 198px!important;
        top: 619px!important;
    }
    .gird-list-categories button.list-viewbutton:last-child {
        position: absolute;
        left: 234px!important;
        top: 618px!important;
    }
    .gridviewproperty .property-img {
        height: 158px;
        width: 100%;
    }
    .location-search {
        width: 93%!important;
        top: 317px!important;
    }
    .location-search .disply-flex {
        display: block!important;
    }
    .location-search input.city-input-text {
        width: 98%!important;
    }
    .dropdown-slist-s {
        width: 86.5%!important;
        left: 30px!important;
    }
    .emptydiv {
        display:none!important;
    }
    .width-content-392 h4.font16 {
        font-size: 9px !important;
        font-weight: 500 !important;
    }
}
@media only screen and (min-width: 280px) and (max-width: 300px){}
@media only screen and (min-width: 301px) and (max-width: 320px){
    .price-search-head .location-search {
        width: 92%!important;
    top: 326px!important;
    left: 14px!important;
    }
    .width-content-39 p {
        font-size: 12px;
    }
    li.home-search-list {
        padding: 8px 12px 6px!important;
    }
    .lot-search-head .location-search {
        width: 92%!important;
    top: 372px!important;
    left: 16px!important;
    }
    .nrsf-search-head .location-search {
        width: 92%!important;
        top: 373px!important;
        left:  14px!important;
    }
    .others-search-head .location-search {
        width: 92%!important;
        top: 418px!important;
        left: 16px!important;
    }
    .searchbar-top .search-heading {
        width: 40%!important;
    }
    .gridviewproperty .property-img {
        height: 186px;
        width: 100%;
    }
    .searchbar-top form button.apply-search-btn {
        margin-left: 15px !important;
    }
    .gird-list-categories button.grid-viewbutton {
        left: 220px!important;
        top: 541px!important;
    }
    .gird-list-categories button.list-viewbutton:last-child {
        left: 262px!important;
        top: 541px!important;
    }
    .searchresult-input {
        min-width:  255px!important;
        margin: 15px 5px 15px 15px;
    }
    .search-page .search-resultdropdown .dropdown button {
        width: 101%!important;
        margin: 0px 5px 0px 15px;
        padding-top: 7px!important;
    }
    .search-page .dropdown-slist {
        width: 267px!important;
        top: 231px!important;
        left: 27px!important;
    }
    .search-page .select-property-search .dropdown-menu.show {
        top: -5px!important;
        left: 0px!important;
    }
    .search-page .select-property-search .dropdown-menu.show button {
        width: 87%!important;
        padding-left: 14px!important;
    }
    .search-page .dropdown .checked-close {
        margin-top: -5px;
    }
    .search-page .box-search-home .select-property-search button.dropdown-toggle, .search-page .select-property-search .dropdown-menu {
        width: 273px!important;
        border-radius: 2px!important;
    }
    .search-page .search-resultdropdown .dropdown-item button {
        width: 95%!important;
    }
    .emptydiv {
        display:none!important;
    }
    .width-content-392 h4.font16 {
        font-size: 9px !important;
        font-weight: 500 !important;
    }
    .location-search {
        width: 90%!important;
        top: 328px!important;
        left: 15px!important;
    }
    .location-search .disply-flex {
        display: block!important;
    }
    .location-search input.city-input-text {
        width: 100%!important;
        margin-left: 0px!important;
    }
    .dropdown-slist-s {
        width: 89.5%!important;
    left: 26px!important;
    }
    .location-search .within-field span {
        left: -11px!important;
    }
    .location-search .city-selection .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
        width: 256px!important;
        background-color: #f7f5f5;
    }
}
@media only screen and (min-width: 335px) and (max-width: 360px){
    .searchbar-top .search-heading {
        width: 138px!important;
    }
    .searchbar-top form button.apply-search-btn {
        margin-left: 15px !important;
    }
    .gird-list-categories button.list-viewbutton:last-child {
        left: 284px!important;
        top: 545px!important;
    }
    .gird-list-categories button.grid-viewbutton {
        left: 242px!important;
        top: 545px!important;
    }
}
@media only screen and (min-width: 321px) and (max-width: 360px){
    .price-search-head .location-search {
        width: 92%!important;
    top: 326px!important;
    left: 14px!important;
    }
    .width-content-39 p {
        font-size: 12px;
    }
    li.home-search-list {
        padding: 8px 12px 6px!important;
    }
    .lot-search-head .location-search {
        width: 92%!important;
    top: 372px!important;
    left: 16px!important;
    }
    .nrsf-search-head .location-search {
        width: 92%!important;
        top: 373px!important;
        left:  14px!important;
    }
    .others-search-head .location-search {
        width: 92%!important;
        top: 418px!important;
        left: 16px!important;
    }
    .search-page .dropdown-slist {
        width: 89%!important;
        top: 231px!important;
        left: 27px!important;
    }
    .search-page .dropdown .checked-close {
        margin-top: 5px;
    }
    .search-page .box-search-home .select-property-search button.dropdown-toggle, .search-page .select-property-search .dropdown-menu {
        width: 322px;
        border-radius: 2px!important;
    }
    .search-page .search-resultdropdown .dropdown-item button {
        width: 95%!important;
    }
    .searchresult-input {
        min-width: 21.4em!important;
        margin: 15px 5px 15px 15px;
    }
    .search-page .search-resultdropdown .dropdown button {
        width: 102%!important;
        margin: 0px 5px 0px 15px;
        padding-top: 8px!important;
    }
    .search-page .select-property-search .dropdown button span.checked-close {
        position: relative;
        top: -10px;
    }
    .gridviewproperty .property-img {
        height: 215px;
        width: 100%;
    }
    .emptydiv {
        display:none!important;
    }
    .width-content-392 h4.font16 {
        font-size: 9px !important;
        font-weight: 500 !important;
    }
    .location-search {
        width: 90%!important;
        top: 328px!important;
        left: 15px!important;
    }
    .location-search .disply-flex {
        display: block!important;
    }
    .location-search input.city-input-text {
        width: 100%!important;
        margin-left: 0px!important;
    }
    .dropdown-slist-s {
        width: 89.5%!important;
    left: 26px!important;
    }
    .location-search .within-field span {
        left: -11px!important;
    }
    .location-search .city-selection .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
        width: 292px!important;
        background-color: #f7f5f5;
    }
}
@media only screen and (min-width: 361px) and (max-width: 399px){
    .price-search-head .location-search {
        width: 92%!important;
    top: 326px!important;
    left: 14px!important;
    }
    .width-content-39 p {
        font-size: 12px;
    }
    li.home-search-list {
        padding: 8px 12px 6px!important;
    }
    .lot-search-head .location-search {
        width: 92%!important;
    top: 372px!important;
    left: 16px!important;
    }
    .nrsf-search-head .location-search {
        width: 92%!important;
        top: 373px!important;
        left:  14px!important;
    }
    .others-search-head .location-search {
        width: 92%!important;
        top: 418px!important;
        left: 16px!important;
    }
    .gird-list-categories button.grid-viewbutton {
        left: 274px!important;
        top: 541px!important;
    }
    .gird-list-categories button.list-viewbutton:last-child {
        left: 314px!important;
        top: 541px!important;
    }
    .searchresult-input {
        min-width: 21.7em!important;
        margin: 15px 5px 15px 15px;
    }
    .search-page .search-resultdropdown .dropdown button {
        width: 101%!important;
        margin: 0px 5px 0px 15px;
        padding:8px!important;
    }
    .search-page .dropdown-slist {
        width: 86%!important;
        top: 231px!important;
        left: 27px!important;
    }
    .search-page .select-property-search .dropdown-menu.show {
        top: -5px!important;
    }
    .search-page .dropdown .checked-close {
        margin-top: -5px;
    }
    .search-page .box-search-home .select-property-search button.dropdown-toggle, .search-page .select-property-search .dropdown-menu {
        width: 322px!important;
        border-radius: 2px!important;
    }
    .search-page .search-resultdropdown .dropdown-item button {
        width: 95%!important;
    }
    .gridviewproperty .property-img {
        height: 219px;
        width: 100%;
    }
    .emptydiv {
        display:none!important;
    }
    .width-content-392 h4.font16 {
        font-size: 9px !important;
        font-weight: 500 !important;
    }
    .location-search input.city-input-text {
        width: 84%!important;
    }
    .location-search .within-field span {
        left: -28px!important;
    }
    .location-search {
        width: 90%!important;
        top: 328px!important;
        left: 15px!important;
    }
    .location-search .city-selection .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
        width: 258px!important;
        background-color: #f7f5f5;
    }
}
@media only screen and (min-width: 400px) and (max-width: 411px){
    .price-search-head .location-search {
        width: 92%!important;
    top: 326px!important;
    left: 14px!important;
    }
    .width-content-39 p {
        font-size: 12px;
    }
    li.home-search-list {
        padding: 8px 12px 6px!important;
    }
    .lot-search-head .location-search {
        width: 92%!important;
    top: 372px!important;
    left: 16px!important;
    }
    .nrsf-search-head .location-search {
        width: 92%!important;
        top: 373px!important;
        left:  14px!important;
    }
    .others-search-head .location-search {
        width: 92%!important;
        top: 418px!important;
        left: 16px!important;
    }
    .searchbar-top .search-heading {
        margin-right: 15px!important;
        margin-left: 15px!important;
        width: 170px!important;
    }
    .gridviewproperty .property-img {
        height: 244px;
        width: 100%;
    }
    .searchbar-top form button.apply-search-btn {
        margin-left: 15px !important;
    }
    .searchresult-input {
        min-width: 26.1em!important;
        margin: 15px 5px 15px 15px;
    }
    .search-page .search-resultdropdown .dropdown button {
        width: 97%!important;
        margin: 0px 5px 0px 15px;
    }
    .search-page .dropdown-slist {
        width: 89%!important;
        top: 231px!important;
        left: 27px!important;
    }
    .search-page .dropdown .checked-close {
        margin-top: 5px;
    }
    .search-page .box-search-home .select-property-search button.dropdown-toggle, .search-page .select-property-search .dropdown-menu {
        width: 369px!important;
        border-radius: 2px!important;
    }
    .search-page .search-resultdropdown .dropdown-item button {
        width: 95%!important;
    }
    .gird-list-categories button.grid-viewbutton {
        left: 296px!important;
    top: 540px!important;
    }
    .gird-list-categories button.list-viewbutton:last-child {
        left: 339px!important;
        top: 541px!important;
    }
    .emptydiv {
        display:none!important;
    }
    .width-content-392 h4.font16 {
        font-size: 10px !important;
        font-weight: 500 !important;
    }
    .grid-and-list {
        padding-left: 15px!important;
        padding-right: 15px!important;
    }
    .location-search input.city-input-text {
        width: 85%!important;
    }
    .location-search .within-field span {
        left: -28px!important;
    }
    .searchbar-top .search-heading a {
        width: 86%;
    }
    .location-search {
        width: 90%!important;
        top: 328px!important;
        left: 15px!important;
    }
    .location-search .city-selection .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
        width: 292px!important;
        background-color: #f7f5f5;
    }
}
@media only screen and (min-width: 412px) and (max-width: 420px){
    .price-search-head .location-search {
        width: 92%!important;
    top: 326px!important;
    left: 14px!important;
    }
    .width-content-39 p {
        font-size: 12px;
    }
    .lot-search-head .location-search {
        width: 92%!important;
    top: 372px!important;
    left: 16px!important;
    }
    .nrsf-search-head .location-search {
        width: 92%!important;
        top: 373px!important;
        left:  14px!important;
    }
    .others-search-head .location-search {
        width: 92%!important;
        top: 418px!important;
        left: 16px!important;
    }
    .searchbar-top .search-heading {
        width: 176px!important;
    }
    .gridviewproperty .property-img {
        height: 249px;
        width: 100%;
    }
    .searchbar-top form button.apply-search-btn {
        margin-left: 15px !important;
    }
    .gird-list-categories button.grid-viewbutton {
        left: 314px!important;
        top: 541px!important;
    }
    .gird-list-categories button.list-viewbutton:last-child {
        left: 358px!important;
        top: 541px!important;
    }
    .searchresult-input {
        min-width: 25.7em!important;
        margin: 15px 5px 15px 15px;
    }
    .search-page .search-resultdropdown .dropdown button {
        width: 101%!important;
        margin: 0px 5px 0px 15px;
        padding-top: 8px!important;
    }
    .search-page .select-property-search .dropdown button span.checked-close {
        position: relative;
        top: -5px;
    }
    .search-page .dropdown-slist {
        width: 91.5%!important;
        top: 231px!important;
        left: 27px!important;
    }
    .search-page .select-property-search .dropdown-menu.show {
        top: -5px!important;
        left: 1px!important;
    }
    .search-page .box-search-home .select-property-search button.dropdown-toggle, .search-page .select-property-search .dropdown-menu {
        width: 383px;
        border-radius: 5px!important;
    }
    .emptydiv {
        display:none!important;
    }
    .width-content-392 h4.font16 {
        font-size: 10px !important;
        font-weight: 500 !important;
    }
    .p-list-search table tfoot, .p-list-search table tfoot tr {
        display: block;
    width: 100%;
    margin-left: auto;
    }
    .location-search input.city-input-text {
        width: 85%!important;
    }
    .location-search .within-field span {
        left: -28px!important;
    }
    .searchbar-top .search-heading a {
        width: 86%;
    }
    .location-search {
        width: 90%!important;
        top: 328px!important;
        left: 15px!important;
    }
    .location-search .city-selection .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
        width: 292px!important;
        background-color: #f7f5f5;
    }
}
@media only screen and (min-width: 421px) and (max-width: 426px){
    .price-search-head .location-search {
        width: 90%!important;
    top: 306px!important;
    left: 14px!important;
    }
    .width-content-39 p {
        font-size: 12px;
    }
    .lot-search-head .location-search {
        width: 90%!important;
        top: 352px!important;
        left: 16px!important;
    }
    .nrsf-search-head .location-search {
        width: 90%!important;
        top: 353px!important;
        left: 14px!important;
    }
    .others-search-head .location-search {
        width: 90%!important;
    top: 398px!important;
    left: 16px!important;
    }
    .searchbar-top .search-heading {
        width: 176px!important;
    }
    .gridviewproperty .property-img {
        height: 249px;
        width: 100%;
    }
    .searchbar-top form button.apply-search-btn {
        margin-left: 15px !important;
    }

    .gird-list-categories button.list-viewbutton:last-child {
        left: 363px!important;
    top: 519px!important;
    }
    .searchresult-input {
        min-width: 25.4em!important;
        margin: 0px 13px 10px!important;
    }
    .gird-list-categories button.grid-viewbutton {
        position: absolute;
        left: 315px!important;
    top: 519px!important;
    }
    .search-page .search-resultdropdown .dropdown button {
        width: 101%!important;
        margin: 0px 5px 0px 15px;
        padding-top: 8px!important;
    }
    .search-page .select-property-search .dropdown button span.checked-close {
        position: relative;
        top: -5px;
    }
    .search-page .dropdown-slist {
        width: 89.9%!important;
        top: 212px!important;
        left: 25px!important;
    }
    .search-page .select-property-search .dropdown-menu.show {
        top: -5px!important;
        left: 1px!important;
    }
    .search-page .box-search-home .select-property-search button.dropdown-toggle, .search-page .select-property-search .dropdown-menu {
        width: 383px;
        border-radius: 5px!important;
    }
    .emptydiv {
        display:none!important;
    }
    .width-content-392 h4.font16 {
        font-size: 10px !important;
        font-weight: 500 !important;
    }
    .p-list-search table tfoot, .p-list-search table tfoot tr {
        display: block;
    width: 100%;
    margin-left: auto;
    }
    .location-search input.city-input-text {
        width: 85%!important;
    }
    .location-search .within-field span {
        left: -28px!important;
    }
    .searchbar-top .search-heading a {
        width: 86%;
    }
    .location-search {
        width: 90%!important;
        top: 306px!important;
        left: 15px!important;
    }
    .location-search .city-selection .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
        width: 299px!important;
        background-color: #f7f5f5;
    }
}
@media only screen and (min-width: 427px) and (max-width: 479px){
    .searchresult-input {
        min-width:  382px!important;
        margin: 0px 13px 9px;
    }
    .search-page .search-resultdropdown .dropdown button {
        width: 100%!important;
        margin: 0px 5px 6px 15px;
        padding-top: 8px!important;
    }
    .searchbar-top .search-heading {
        width: 182px!important;
    }
    .searchbar-top form button.apply-search-btn {
        margin-left: 15px !important;
    }
    .gird-list-categories button.grid-viewbutton {
        position: absolute;
        left: 344px!important;
        top: 550px!important;
    }
    .gird-list-categories button.list-viewbutton:last-child {
        position: absolute;
        left: 390px!important;
        top: 550px!important;
    }
}
@media only screen and (min-width: 480px) and (max-width: 500px){
    .location-search .city-selection .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
        width: 257px!important;
        background-color: #f7f5f5;
    }
    .price-search-head .location-search {
        width: 75%!important;
    top: 298px!important;
    left: 105px!important;
    }
    .lot-search-head .location-search {
        width: 75%!important;
    top: 345px!important;
    left: 16px!important;
    }
    .nrsf-search-head .location-search {
        width: 75%!important;
    top: 343px!important;
    left: 105px!important;
    }
    .others-search-head .location-search {
        width: 75%!important;
        top: 390px!important;
        left: 16px!important;
    }
    .searchresult-input {
        min-width:  424.6px!important;
        margin: 0px 13px 9px;
    }
    .search-page .search-resultdropdown .dropdown button {
        width: 100%!important;
        margin: 0px 5px 6px 15px;
        padding-top: 8px!important;
    }
    .search-page .search-resultdropdown .dropdown button span {
        position: relative;
        top: -6px;
    }
    .search-page .search-resultdropdown .dropdown button span.numbroder {
        top: -1px!important;
    }
    .searchbar-top .search-heading {
        width: 209px!important;
    }
    .searchbar-top form button.apply-search-btn {
        margin-left: 15px !important;
    }
    .search-page .dropdown-slist {
        width: 440.65px!important;
        top: 214px!important;
        left: 25px!important;
    }
    .gird-list-categories button.grid-viewbutton {
        left: 420px!important;
    }
    .gird-list-categories button.list-viewbutton:last-child {
        left: 373px!important;
    }
    .location-search input.city-input-text {
        width: 85%!important;
    }
    .location-search .within-field span {
        left: -28px!important;
    }
    .searchbar-top .search-heading a {
        width: 86%;
    }
    .location-search {
        width: 70%!important;
        top: 296px!important;
        left: 15px!important;
    }
}
@media only screen and (min-width: 501px) and (max-width: 560px){
    .searchresult-input {
        min-width:  460px!important;
        margin: 0px 13px 9px;
    }
    .search-page .search-resultdropdown .dropdown button {
        width: 100%!important;
        margin: 0px 5px 6px 15px;
        padding-top: 8px!important;
    }
    .searchbar-top .search-heading {
        width: 219px!important;
    }
    .searchbar-top form button.apply-search-btn {
        margin-left: 15px !important;
    }
    .gird-list-categories button.grid-viewbutton {
        left: 400px!important;
        top: 510px!important;
    }
    .gird-list-categories button.list-viewbutton:last-child {
        left: 450px!important;
        top: 510px!important;
    }
}
@media only screen and (min-width: 600px) and (max-width: 640px){
    .price-search-head .location-search {
        width: 60%!important;
        top: 276px!important;
        left: 216px!important;
    }
    .lot-search-head .location-search {
        width: 60%!important;
        top: 276px!important;
        left: 224px!important;
    }
    .nrsf-search-head .location-search {
        width: 60%!important;
    top: 323px!important;
    left: 16px!important;
    }
    .search-page .select-property-search .dropdown-menu.show button {
        padding-left: 1px!important;
        margin-left: 5px!important;
    }
    .others-search-head .location-search {
        width: 60%!important;
        top: 321px!important;
        left: 216px!important;
    }
    .searchbar-top form button.apply-search-btn {
        margin-left: 15px !important;
    }
    .gird-list-categories button.grid-viewbutton {
        position: absolute;
        left: initial!important;
        top: 446px!important;
        right: 63px!important;
    }
    .gird-list-categories button.list-viewbutton:last-child {
        position: absolute;
        left: initial!important;
        right: 18px!important;
        top: 446px!important;
    }
    .width-content-392 h4.font16 {
        font-size: 15px!important;
    }
    .image-grid {
        height: 95.74px!important;
    }
    .searchresult-input {
        min-width: 26.5em!important;
        margin: 15px 5px 15px 15px;
    }
    .search-page .search-resultdropdown .dropdown button {
        width: 125%!important;
        margin: 0px 5px 0px 15px;
        padding-top: 8px!important;
    }
    .search-page .search-resultdropdown .dropdown button span {
        position: relative;
        top: -6px;
    }
    .search-page .search-resultdropdown .dropdown button span.numbroder {
        top: -1px!important;
    }
    .searchbar-top .search-heading {
        width: 169px!important;
    }
    .search-page .dropdown-slist {
        width: 68.3%!important;
        top: 228px!important;
        left: 27px!important;
    }
    .search-page .select-property-search .dropdown-menu.show button {
        width: 9em!important;
        margin-top: 5px;
    }
    .search-page .select-property-search .dropdown-menu.show {
        top: -12px!important;
    left: 0px!important;
    border-top: none!important;
    border-radius: 0px!important;
    }
    .search-page .box-search-home .select-property-search button.dropdown-toggle, .search-page .select-property-search .dropdown-menu {
        border-radius: 5px!important;
    }
    .gridviewproperty .property-img {
        height: 177px;
        width: 100%;
    }
    .emptydiv {
        display:none!important;
    }
    .link-grid {
        display: block!important;
    }
    .home-property .content-property .col-sm-6 {
        flex: 0 0 100%;
        max-width:  100%;
    }
    .width-content-39 {
        padding: 5px 0px 7px 10px!important;
    }
    .p-list-search table tfoot, .p-list-search table tfoot tr {
        display: block;
    width: 100%;
    margin-left: auto;
    }
    .location-search input.city-input-text {
        width: 85%!important;
    }
    .location-search .within-field span {
        left: -28px!important;
    }
    .searchbar-top .search-heading a {
        width: 86%;
    }
    .location-search {
        width: 70%!important;
        top: 276px!important;
        left: 15px!important;
    }
    .p-list-search .content-property {
        height: 139px!important;
    }
    .location-search .city-selection .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
        width: 100%!important;
        background-color: #f7f5f5;
    }
}
@media only screen and (min-width: 641px) and (max-width: 720px){
    .location-search .city-selection .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
        width: 100%!important;
        background-color: #f7f5f5;
    }
    .location-search-head .location-search {
        width: 60%!important;
        top: 276px!important;
        left: 15px!important;
    }
    .price-search-head .location-search {
        width: 60%!important;
        top: 276px!important;
        left: 35%!important;
    }
    .lot-search-head .location-search {
        width: 60%!important;
        top: 276px!important;
        left: 35%!important;
    }
    .nrsf-search-head .location-search {
        width: 60%!important;
    top: 323px!important;
    left: 16px!important;
    }
    .others-search-head .location-search {
        width: 60%!important;
        top: 321px!important;
        left: 35%!important;
    }
    .search-page .select-property-search .dropdown-menu.show button {
        padding-left: 1px!important;
        margin-left: 5px!important;
    }
    .searchbar-top .search-heading a {
        width: 80%;
    }
    .search-page .home-property .content-property {
        height: 139px!important;
    }
    .searchbar-top form button.apply-search-btn {
        margin-left: 15px !important;
    }
    .gird-list-categories button.grid-viewbutton {
        position: absolute;
        left: initial!important;
        top: 446px!important;
        right: 63px!important;
    }
    .gird-list-categories button.list-viewbutton:last-child {
        position: absolute;
        left: initial!important;
        right: 18px!important;
        top: 446px!important;
    }
    .image-grid {
        height: 95.74px!important;
    }
    .searchresult-input {
        min-width: 386px!important;
        margin: 15px 5px 15px 15px;
    }
    .search-page .search-resultdropdown .dropdown button {
        width: 121%!important;
        margin: 0px 5px 0px 15px;
        padding-top: 8px!important;
    }
    .search-page .search-resultdropdown .dropdown button span {
        position: relative;
        top: -6px;
    }
    .search-page .search-resultdropdown .dropdown button span.numbroder {
        top: -1px!important;
    }
    .searchbar-top .search-heading {
        width: 28%!important;
    }
    .search-page .dropdown-slist {
        width: 424px!important;
        top: 228px!important;
        left: 27px!important;
    }
    .search-page .select-property-search .dropdown-menu.show {
        top: -5px!important;
        left: 1px!important;
    }
    .search-page .box-search-home .select-property-search button.dropdown-toggle, .search-page .select-property-search .dropdown-menu {
        width: 383px;
        border-radius: 5px!important;
    }
    .gridviewproperty .property-img {
        height: 177px;
        width: 100%;
    }
    .emptydiv {
        display:none!important;
    }
    .link-grid {
        display: block!important;
    }
    .home-property .content-property .col-sm-6 {
        flex: 0 0 100%;
        max-width:  100%;
    }
    .width-content-39 {
        padding: 5px 0px 7px 10px!important;
    }
    .p-list-search table tfoot, .p-list-search table tfoot tr {
        display: block;
    width: 100%;
    margin-left: auto;
    }
}
@media only screen and (min-width: 721px) and (max-width: 767px){
    .location-search-head .location-search {
        width: 60%!important;
        top: 206px!important;
        left: 37.5%!important;
    }
    .price-search-head .location-search {
        width: 60%!important;
        top: 251px!important;
        left: 2.1%!important;
    }
    .lot-search-head .location-search {
        width: 60%!important;
        top: 251px!important;
        left: 26.5%!important;
    }
    .nrsf-search-head .location-search {
        width: 60%!important;
    top: 323px!important;
    left: 11%!important;
    }
    .others-search-head .location-search {
        width: 60%!important;
    top: 250px!important;
    left: 34.8%!important;
    }
    .search-page .select-property-search .dropdown-menu.show button {
        padding-left: 1px!important;
        margin-left: 5px!important;
    }
    .searchbar-top .search-heading a {
        width: 80%;
    }
    .search-page .search-resultdropdown .dropdown button {
        padding-top: 8px!important;
    }
    .search-page .search-resultdropdown .dropdown button span {
        position: relative;
        top: -8px!important;
    }
    .search-page .search-resultdropdown .dropdown button span.numbroder {
        top: -1px!important;
    }
    .search-page .search-resultdropdown .dropdown button .checked-close .arrow-bottom {
        top: 1.2px!important;
    }
    .search-page .home-property .content-property {
        height: 139px!important;
    }
    .gridviewproperty .property-img {
        height: 210px;
        width: 100%;
    }
    .content-property p span {
        font-size: 11px;
    }
    
    .gird-list-categories button.grid-viewbutton {
        left: 629px!important;
        top: 386px!important;
    }
    .gird-list-categories button.list-viewbutton:last-child {
        left: 678px!important;
        top: 386px!important;
    }
    .searchresult-input {
        min-width: 26.4em!important;
        margin-right: 12px!important;
        margin-left: 15px!important;
    }
    .searchbar-top .search-heading {
        width: 20%!important;
        height: 37px!important;
        margin-bottom: 8px!important;
        margin-top: 0px!important;
    }
    .searchbar-top form button.apply-search-btn {
        margin-left: 15px !important;
    }
    .searchbar-top form {
        margin-bottom: 0px!important;
        width: 84%;
    }
    .search-page .dropdown-slist {
        width: 50.6%!important;
    height: auto!important;
    max-height: 152px!important;
    top: 214px!important;
    left: 27px!important;
    }
    .p-list-search table tfoot, .p-list-search table tfoot tr {
        display: block;
    width: 100%;
    margin-left: auto;
    }
    .select-property-search .dropdown button span {
        top: 4px!important;
    }
    .image-grid {
        width: 21.9%!important;
        height: 96.74px!important;
    }
    .property-list-grid {
        width: 100%!important;
    }
}
@media only screen and (min-width: 280px) and (max-width: 767px){
    .searchresult-header .height-99 {
        height: auto!important;
        width: 100% !important;
        z-index: auto!important;
        position: relative!important;
    top: 7px!important;
    }
    .searchbar-top .search-heading a {
        width: 80%;
    }
    .checked-close .closed-x {
        top: 0px!important;
    }
    .emptydiv {
        width: auto!important;
    }
    .p-list-search {
        width: 100%!important;
        padding: 10px!important;
    }
    .searchbar-top {
    display: block;
    height: auto !important;
    width: 100% !important;
    z-index: auto!important;
    position: unset !important;
    padding: 0px;
    padding-top: 110px;
    }
    .searchbar-top h5 {
        margin-right: 0px;
    }
    .searchbar-top form {
        display: block;
        margin-top: 10px;
        margin-bottom: 5px;
    }
    .searchbar-top .row {
        display: flex;
    }
    .searchbar-top .search-heading {
        margin-right: 15px!important;
        margin-left: 15px!important;
        width: 149px;
        margin-bottom: 5px;
        margin-top: 5px;
    }
   
    .width10 {
        display: none;
    }
    button.clear-search-btn {
        left: -6px;
    }
    .grid-and-list {
        height: auto;
        padding-top: 3px;
        padding-bottom: 3px;
        position: unset;
        top: initial;
    }
    .gird-list-categories {
        width: 100%!important;
        display: block;
        margin-bottom: 10px;
        padding-right: 0px;
    }    
    .m-list-search {
        width: 100%!important;
        height: 350px;
        padding-top: 10px;
    position: unset;
    padding: 10px;
    }
    .gird-list-categories button {
        height: 36px;
        width: 36px;
    }
    .gird-list-categories button.grid-viewbutton {
        position: absolute;
    left: 253px;
    top: 540px;
    }
    .gird-list-categories button.list-viewbutton:last-child {
        position: absolute;
        left: 302px;
        top: 540px;
    }
    .checked-close {
        float: right;
    }
    .searchbar-top form button.apply-search-btn {
        margin-left: 15px !important;
    }
    .location-search {
        width: 326px;
        top: 293px;
    }
    .p-list-search table, .p-list-search table tbody {
        display: block; 
    }
    .width-content-39 {
        width: 50%;
        padding: 5px 0px 7px 7px;
    }
    .width-content-392 {
        width: 100%;
        padding: 5px 7px 0px 8px;
    }
    .image-grid {
        width: 21.9%;
        height: 60.74px;
    }
    .width-content-39 h5 a {
        width: 200px!important;
    }
    .property-link {
        width: 80%;
        display: block;
    }
    .width-content-392 h6 {
        width: 100%!important;
    }
    .map-infoview .gm-style-iw-c {
        width: 265px;
        max-width: 265px!important;
        max-height: 230px!important;
        height: 230px;
        margin-bottom: 15px;
        max-width: none;
    }
    .map-infoview .gm-style-iw-d {
        max-height:none!important;
     }
}
@media only screen and (min-width: 768px) and (max-width: 899px){
    .location-search-head .location-search {
        width: 48%!important;
        top: 83px!important;
        left: 16.5%!important;
    }
    .price-search-head .location-search {
        width: 48%!important;
        top: 83px!important;
        left: 30%!important;
    }
    .lot-search-head .location-search {
        width: 48%!important;
        top: 83px!important;
        left: 40%!important;
    }
    .nrsf-search-head .location-search {
        width: 48%!important;
    top: 83px!important;
    left: 52%!important;
    }
    .others-search-head .location-search {
        width: 48%!important;
    top: 83px!important;
    left: 25.8%!important;
    }
    .search-page .search-resultdropdown .dropdown button {
        width: 11.2em!important;
    }
    .searchbar-top form {
        margin-left: 14px;
    }
    .search-page .dropdown-slist {
        width: 36.5% !important;
        top: 43px !important;
        left: 140px !important;
    }
    .search-page .box-search-home {
        margin-top: 2px;
        margin-bottom: 2px;
    }
    .searchresult-input {
        min-width: 19.4em!important;
    }
    .gridviewproperty .property-img {
        height: 140px;
        width: 100%;
    }
    .emptydiv {
        display: none!important;
    }
    .p-list-search {
        width: 65%!important;
        padding: 12px 0px 0px 15px;
    }
    .m-list-search {
        width: 35%!important;
        padding-top: 177px;
    }
    .grid-and-list {
        height: auto!important;
        padding-top: 178px;
    }
    .property-list-grid {
        width: 97%;
    }
    .property-link {
        display: block;
    }
    .image-grid {
        width: 30.9%;
        height: 107.74px;
    }
    .search-heading {
        margin-bottom: 5px;
        margin-top: 6px;
    }
    .searchbar-top h5 {
        vertical-align: middle;
        padding: 27px 20px 25px;
    }
    .location-search {
        top: 80px;
    }
    .gridviewproperty.row {
        margin: 0px!important;
        width: 100%!important;
    }
    .gridviewproperty a.link-grid {
        display: block;
    }
    .home-property .content-property .col-sm-6 {
        flex: 0 0 100%;
        max-width:  100%;
    }
    .location-search .city-selection .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
        width: 291px!important;
        background-color: #f7f5f5;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1147px){

    .content-property h5 {
        font-size: 12px!important;
    }
}
@media only screen and (min-width: 799px) and (max-width: 800px){
    .gridviewproperty .property-img {
        height: 100%!important;
        width: 50%!important;
    }
    .search-page .dropdown-slist {
        width: 35% !important;
        top: 43px !important;
        left: 145px !important;
    }
    .location-search .city-selection .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
        width: 305px!important;
        background-color: #f7f5f5;
    }
}
@media only screen and (min-width: 960px) and (max-width: 990px){
    .location-search-head .location-search {
        width: 40%!important;
        top: 80px!important;
        left: 12%!important;
    }
    .price-search-head .location-search {
        width: 40%!important;
        top: 80px!important;
        left: 23%!important;
    }
    .lot-search-head .location-search {
        width: 40%!important;
        top: 80px!important;
        left: 31%!important;
    }
    .nrsf-search-head .location-search {
        width: 40%!important;
    top: 80px!important;
    left: 40.8%!important;
    }
    .others-search-head .location-search {
        width: 40%!important;
    top: 80px!important;
    left: 49%!important;
    }
    .searchresult-input {
        min-width: 472.77px!important;
    }
    .search-page .dropdown-slist {
        width: 477.77px!important;
        top: 47px!important;
        left: 128px!important;
    }
    .grid-and-list {
        height: 32.5vh;
        padding-top: 176px;
    }
    .width-content-39 {
        width: 50%;
    }
    .width-content-392 {
        width: 50%;
    }
    .emptydiv {
        width: auto!important;
        display: inline-block;
    }
    .gird-list-categories {
        width:100%!important;
        text-align: right;
    }
    .search-heading {
        margin-top: 3px;
    }
    .searchbar-top h5 {
        padding: 29.7px 18px;
    }
    .gridviewproperty .property-img {
        height: 180px;
        width: 100%;
    }
    .image-grid {
        width: 21.9%;
        height: 108.74px;
    }
    .width-content-39 h5 a {
        width: 186px!important;
    }
    .p-list-search .myBox-relative .last-property .margin-b-15 {
        margin-bottom: 15px;
    margin-right: 12px;
    }
    .property-list-grid {
        width: 100%;
    }
    .home-property .content-property .col-sm-6 {
        flex: 0 0 100%;
        max-width:  100%;
    }
    .link-grid {
        display: block;
    }
    .location-search .city-selection .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
        width: 306px!important;
        background-color: #f7f5f5;
    }
}
@media only screen and (min-width: 900px) and (max-width: 910px){
    .location-search-head .location-search {
        width: 40%!important;
        top: 83px!important;
        left: 14.5%!important;
    }
    .price-search-head .location-search {
        width: 40%!important;
        top: 83px!important;
        left: 25.5%!important;
    }
    .lot-search-head .location-search {
        width: 40%!important;
        top: 83px!important;
        left: 34%!important;
    }
    .nrsf-search-head .location-search {
        width: 40%!important;
    top: 83px!important;
    left: 44.5%!important;
    }
    .others-search-head .location-search {
        width: 40%!important;
    top: 83px!important;
    left: 53.5%!important;
    }
    .search-page .search-resultdropdown .dropdown button {
        width: 10.2em!important;
    }
    .searchbar-top form {
        margin-left: 14px;
    }
    .search-page .dropdown-slist {
        width: 428px!important;
        top: 43px !important;
        left: 140px !important;
    }
    .search-page .box-search-home {
        margin-top: 2px;
        margin-bottom: 2px;
    }
    .searchresult-input {
        min-width: 423px!important;
    }
    .gridviewproperty .property-img {
        height: 140px;
        width: 100%;
    }
    .emptydiv {
        display: none!important;
    }
    .p-list-search {
        width: 65%!important;
        padding: 12px 0px 0px 15px;
    }
    .m-list-search {
        width: 35%!important;
        padding-top: 177px;
    }
    .gird-list-categories {
        width: 100%!important;
    }
    .grid-and-list {
        height: auto!important;
        padding-top: 178px;
    }
    .property-list-grid {
        width: 97%;
    }
    .property-link {
        display: block;
    }
    .image-grid {
        width: 30.9%;
        height: 107.74px;
    }
    
    .search-heading {
        margin-bottom: 5px;
        margin-top: 2px;
    }
    .searchbar-top h5 {
        vertical-align: middle;
        padding: 27px 20px 25px;
    }
    .location-search {
        top: 80px;
    }
    .gridviewproperty.row {
        margin: 0px!important;
        width: 100%!important;
    }
    .gridviewproperty a.link-grid {
        display: block;
    }
    .home-property .content-property .col-sm-6 {
        flex: 0 0 100%;
        max-width:  100%;
    }
}
@media only screen and (min-width: 992px) and (max-width: 996px){
    .location-search-head .location-search {
        width: 40%!important;
        top: 80px!important;
        left: 12%!important;
    }
    .price-search-head .location-search {
        width: 40%!important;
        top: 80px!important;
        left: 22%!important;
    }
    .lot-search-head .location-search {
        width: 40%!important;
        top: 80px!important;
        left: 30%!important;
    }
    .nrsf-search-head .location-search {
        width: 40%!important;
    top: 80px!important;
    left: 39.8%!important;
    }
    .others-search-head .location-search {
        width: 40%!important;
    top: 80px!important;
    left: 48%!important;
    }
    .searchresult-input {
        min-width: 500px!important;
    }
    .grid-and-list {
        height: 31.5vh;
        padding-top: 176px;
    }
    .width-content-39 {
        width: 50%;
    }
    .width-content-392 {
        width: 50%;
    }
    .property-link {
        display: inline-flex;
    }
    .search-page .dropdown-slist {
        width: 505px!important;
        top: 47px!important;
        left: 128px!important;
    }
    .emptydiv {
        width: auto!important;
        display: inline-block;
    }
    .gird-list-categories {
        width:100%!important;
        text-align: right;
    }
    .search-heading {
        margin-top: 3px;
    }
    .searchbar-top h5 {
        padding: 29.7px 18px;
    }
    .gridviewproperty .property-img {
        height: 191px;
        width: 100%;
    }
    .image-grid {
        width: 21.9%;
        height: 109.74px;
    }
    .location-search .city-selection .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
        width: 318px!important;
        background-color: #f7f5f5;
    }
}
@media only screen and (min-width: 997px) and (max-width: 1050px){
    .searchbar-top {
        height: 90px !important;
    }
    .search-heading {
        margin-top: 3px;
    }
    .gridviewproperty .property-img {
        height: 194px;
        width: 100%;
    }
    .gridviewproperty .link-grid .padding-grid-remove {
        padding-right: 0px!important;
        padding-left: 10px!important;
        flex: 0 0 100%!important;
        max-width: 100%!important;
    }
    .gridviewproperty .link-grid .padding-grid-remove2 {
        padding-left: 0px!important;
        padding-right: 10px!important;
        flex: 0 0 100%!important;
        max-width: 100%!important;
    }
    .gridviewproperty a.link-grid {
        display: block;
    }
    .content-property .link-grid .col-sm-6:first-child {
        padding-right: 0!important;
    }
    .width-content-39 h5 a {
        width: 175px!important;
    }
    .searchbar-top h5 {
        margin-bottom: 0px;
        padding: 31.7px 18px;
    }
    .searchresult-input {
        min-width: 514px!important;
    }
    .emptydiv {
        width: auto!important;
        display: inline-block;
    }
    .gird-list-categories {
        width: 100%!important;
    }
    .grid-and-list {
        height: auto!important;
        padding-top: 185px;
    }
    .m-list-search {
        height: 100vh;
    }
    .image-grid {
        width: 21.9%;
        height: 110.74px;
    }
    .search-page .dropdown-slist {
        width:519px!important;
        left: 128px!important;
    }
    .width-content-392 h6 {
        white-space: nowrap!important;
        overflow: hidden!important;
        width: 212px;
    }
    .location-search {
        top: 84px!important;
    }
    .search-page .market-radius-flex label {
        width: 69px!important;
    }
}
@media only screen and (min-width: 1051px) and (max-width: 1060px){
    .searchresult-input {
        min-width: 40.4em!important;
    }
    .search-page .dropdown-slist {
        width: 53.8%!important;
        left: 138px!important;
    }
    .location-search .city-selection .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
        width: 282px!important;
        background-color: #f7f5f5;
    }

}
@media only screen and (min-width: 1061px) and (max-width: 1070px){
    .searchresult-input {
        min-width: 40.4em!important;
    }
    .search-page .dropdown-slist {
        width: 53.4%!important;
        left: 138px!important;
    }
    .location-search .city-selection .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
        width: 282px!important;
        background-color: #f7f5f5;
    }
}
@media only screen and (min-width: 1071px) and (max-width: 1080px){
    .searchresult-input {
        min-width: 40.4em!important;
    }
    .search-page .dropdown-slist {
        width: 52.9%!important;
        left: 138px!important;
    }
}
@media only screen and (min-width: 1081px) and (max-width: 1090px){
    .searchresult-input {
        min-width: 40.4em!important;
    }
    .search-page .dropdown-slist {
        width: 52.4%!important;
        left: 138px!important;
    }
}
@media only screen and (min-width: 1091px) and (max-width: 1095px){
    .searchresult-input {
        min-width: 40.4em!important;
    }
    .search-page .dropdown-slist {
        width: 52%!important;
        left: 138px!important;
    }
}
@media only screen and (min-width: 1051px) and (max-width: 1095px){
    .searchbar-top {
        height: 90px !important;
    }
    .search-heading {
        margin-top: 3px;
    }
    .gridviewproperty .property-img {
        height: 194px;
        width: 100%;
    }
    .searchbar-top h5 {
        margin-bottom: 0px;
        padding: 31.7px 18px;
    }
    .searchresult-input {
        min-width: 43.4em;
    }
    .emptydiv {
        width: auto!important;
        display: inline-block;
    }
    .gird-list-categories {
        width: 100%!important;
    }
    .grid-and-list {
        height: 48vh;
        padding-top: 187px;
    }
    .searchbar-top form {
        margin-left: 10px;
    }
    .m-list-search {
        height: 100vh;
        padding-top: 191px!important;
    }
    .image-grid {
        width: 21.9%;
        height: 108.74px;
    }
    .search-page .dropdown-slist {
        width: 50.3%;
        left: 128px;
    }
    .width-content-39 h5 a {
        width: 175px!important;
    }
    .width-content-392 h6 {
        white-space: nowrap!important;
        overflow: hidden!important;
    }
    .gridviewproperty .link-grid .padding-grid-remove {
        padding-right: 0px!important;
        padding-left: 10px!important;
        flex: 0 0 100%!important;
        max-width: 100%!important;
    }
    .gridviewproperty .link-grid .padding-grid-remove2 {
        padding-left: 0px!important;
        padding-right: 10px!important;
        flex: 0 0 100%!important;
        max-width: 100%!important;
    }
    .gridviewproperty a.link-grid {
        display: block;
    }
    .location-search-head .location-search {
        width: 34%!important;
        top: 89px!important;
        left: 11.8%!important;
    }
    .price-search-head .location-search {
        width: 34%!important;
        top: 89px!important;
        left: 21.2%!important;
    }
    .lot-search-head .location-search {
        width: 34%!important;
        top: 89px!important;
        left: 28.5%!important;
    }
    .nrsf-search-head .location-search {
        width: 34%!important;
        top: 89px!important;
        left: 37.5%!important;
    }
    .others-search-head .location-search {
        width: 34%!important;
    top: 89px!important;
    left: 45.2%!important;
    }
    .location-search .city-selection .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
        width: 288px!important;
        background-color: #f7f5f5;
    }
}
@media only screen and (min-width: 1100px) and (max-width: 1150px){
    .gridviewproperty .property-img {
        height: 218px;
        width:100%;
    }
    .grid-and-list {
        height: 25vh;
    }
    .emptydiv {
        display: none;
    }
    .image-grid {
        height: 108.74px;
    }    
    .search-page .dropdown-slist {
        width: 17.1%!important;
        top: 49px!important;
    }    
    .gridviewproperty .link-grid .padding-grid-remove {
        padding-right: 0px!important;
        padding-left: 10px!important;
        flex: 0 0 100%!important;
        max-width: 100%!important;
    }
    .gridviewproperty .link-grid .padding-grid-remove2 {
        padding-left: 0px!important;
        padding-right: 10px!important;
        flex: 0 0 100%!important;
        max-width: 100%!important;
    }
    .gridviewproperty a.link-grid {
        display: block;
    }
    .width-content-392 h4.font16 {
        font-size: 14px!important;
    }
}
@media only screen and (min-width: 1190px) and (max-width: 1200px){
    .location-search-head .location-search {
      
        top: 42px!important;
       
    }
    .price-search-head .location-search {
       
        top: 42px!important;
      
    }
    .lot-search-head .location-search {
       
        top: 42px!important;
      
    }
    .nrsf-search-head .location-search {
     
        top: 42px!important;
        left: 46.5%!important;
    }
    .others-search-head .location-search {
       
        top: 42px!important;
        left: 54%!important;
    }
    .searchresult-input {
        min-width: 284px!important;
    }
    .search-page .dropdown-slist {
        width: 290px!important;
        top: 48px!important;
    }
}
@media only screen and (min-width: 1151px) and (max-width: 1170px){
    .searchresult-input {
        min-width: 240px!important;
    }
    .search-page .dropdown-slist {
        width: 248px!important;
        top: 48px!important;
    }
}
@media only screen and (min-width: 1151px) and (max-width: 1209px){
    .gridviewproperty .property-img {
        height: 220px;
        width:100%;
    }
    .grid-and-list {
        height: 25vh;
    }
    .emptydiv {
        display: none;
    }
    .image-grid {
        height: 108.74px;
    }
    .searchresult-input {
        min-width: 284px;
    }
    .search-page .dropdown-slist {
        width: 290px;
        top: 48px;
    }
}
@media only screen and (min-width: 1210px) and (max-width: 1260px){
    .grid-and-list {
        height: 32vh
    }
    .gridviewproperty .property-img {
        height: 236px;
        width: 100%;
    }
    .emptydiv {
        width: auto!important;
        display: inline-block;
    }
    .image-grid {
        width: 21.9%;
        height: 110.74px;
    }
    .search-page .dropdown-slist {
        width: 284px!important;
        top: 48px!important;
    }
    .searchresult-input {
        min-width: 276px!important;
    }
}
@media only screen and (min-width: 1270px) and (max-width: 1289px){
    .emptydiv {
        width: 80px!important;
        display: inline-block;
    }
    .grid-and-list {
        height: auto!important;
    }
    .gridviewproperty .property-img {
        height: 242px;
        width: 100%;
    }
    .search-page .dropdown-slist {
        width: 284px!important;
        top: 48px!important;
    }
    .searchresult-input {
        min-width: 276px!important;
    }
    button.apply-search-btn {
        left: -20px;
    }
    .image-grid {
        width: 21.9%;
        height: 111.74px;
    }
}
@media only screen and (min-width: 1290px) and (max-width: 1310px){
    .emptydiv {
        width: 99px!important;
        display: inline-block;
    }
    .grid-and-list {
        height: auto!important;
    }
    .gridviewproperty .property-img {
        height: 246px;
        width: 100%;
    }
    .search-page .dropdown-slist {
        width: 284px!important;
        top: 48px!important;
    }
    .searchresult-input {
        min-width: 250px!important;
    }
}
@media only screen and (min-width: 1320px) and (max-width: 1330px){
    button.clear-search-btn {
        position: relative;
    left: 0px;
    }
    button.apply-search-btn {
        left: 0px;
    }
    .gridviewproperty .property-img {
        height: 246px;
        width: 100%;
    }
    .search-page .dropdown-slist {
        width: 284px!important;
        top: 48px!important;
    }
    .searchresult-input {
        min-width: 250px!important;
    }
}
@media only screen and (min-width: 1260px) and (max-width: 1387px){
    .searchresult-input {
    min-width: 16.4em!important;
    }
    .search-page .select-property-search.search-resultdropdown .dropdown button {
        width: 12em!important;
    }
    .search-page .select-property-search.search-resultdropdown .more-width .dropdown-menu button{
        width: 17em!important;
    }

}
@media only screen and (min-width: 1365px) and (max-width: 1366px){
    .search-page .dropdown-slist {
        width: 284px!important;
        top: 48px!important;
        left: 132px!important;
    }
    .searchresult-input {
        min-width: 245px!important;
    }
}
@media only screen and (min-width: 1380px) and (max-width: 1429px){
    .gridviewproperty .property-img {
        height: 246px;
        width: 100%;
    }
    .search-page .dropdown-slist {
        width: 347px!important;
        top: 48px!important;
    }
    .searchresult-input {
        min-width: 212px!important;
    }
}
@media only screen and (min-width: 1388px) and (max-width: 1429px){


}
@media only screen and (min-width: 1430px) and (max-width: 1510px){
    .search-page .dropdown-slist {
        width: 396px!important;
        top: 48px!important;
        left: 132px!important;
    }
    .gridviewproperty .property-img {
        height: 273px;
        width: 100%;
    }
    .grid-and-list {
        height: 25vh;
    }
    .searchresult-input {
        min-width: 245px!important;
    }
}
@media only screen and (min-width: 1447px) and (max-width: 1510px){

    .grid-and-list {
       
        top:10px;
    }
}
@media only screen and (min-width: 1511px) and (max-width: 1546px){

    .grid-and-list {
     
        padding-top: 124px;
    }
}
@media only screen and (min-width: 1511px) and (max-width: 1520px){
    .search-page .dropdown-slist {
        width: 26.91%!important;
        left: 130px!important;
        top: 48px!important;
    }
    .gridviewproperty .property-img {
        height: 289px;
        width: 100%;
    }
    .grid-and-list {
        height: 26vh;
    }
    
}
@media only screen and (min-width: 1521px) and (max-width: 1530px){
    .gridviewproperty .property-img {
        height: 289px;
        width: 100%;
    }
    
    .search-page .dropdown-slist {
        width: 399px!important;
    left: 133px!important;
    }
    .gridviewproperty .property-img {
        height: 289px;
        width: 100%;
    }
    .emptydiv {
        width: 200px!important;
        display: inline-block;
    }
    .width-content-392 h6 {
        width: 100%!important;
    }
}
@media only screen and (min-width: 1531px) and (max-width: 1599px){
    .gridviewproperty .property-img {
        height: 289px;
        width: 100%;
    }
    
    .search-page .dropdown-slist {
        width: 399px!important;
    left: 133px!important;
    }
    .gridviewproperty .property-img {
        height: 289px;
        width: 100%;
    }
    .emptydiv {
        width: 200px!important;
        display: inline-block;
    }
    .width-content-392 h6 {
        width: 100%!important;
    }
}
@media only screen and (min-width: 1600px) and (max-width: 1699px){
    .search-page .p-list-search .grid-and-list {
        height:auto!important;
}
.gridviewproperty .property-img {
    height: 292px;
    width: 100%;
}
.searchresult-input {
    min-width: 23.2em!important;
}
.width-content-392 h6 {
    width: 100%!important;
}
}
@media only screen and (min-width: 1700px) and (max-width: 1710px){
    .gridviewproperty .property-img {
        height: 250px;
        width: 41%!important;
    }
    .search-page .p-list-search .grid-and-list {
        height:auto!important;
}
.search-page .dropdown-slist {
    width: 18.2%!important;
    left: 134px!important;
}
.width-content-392 h6 {
    width: 100%!important;
}
}
@media only screen and (min-width: 1800px) and (max-width: 1820px){
    .gridviewproperty .property-img {
        height: 355px;
        width: 100%;
    }
    .grid-and-list {
        height: 22vh;
    }
    .width-content-392 h6 {
        width: 100%!important;
    }
}
@media only screen and (min-width: 1899px) and (max-width: 1915px){
    .gridviewproperty .property-img {
        height: 315px;
        width: 100%!important;
    }
    .grid-and-list {
        height: auto!important;
    }
    .width-content-392 h6 {
        width: 100%!important;
    }
}
@media only screen and (min-width: 1916px) and (max-width: 1920px){
    .gridviewproperty .property-img {
        height: 250px!important;
        width: 100%!important;
    }
    .grid-and-list {
        height: auto!important;
    }
    .image-grid {
        width: 17.9%!important;
    }
    .searchresult-input {
        min-width: 27.8em!important;
    }
    .vendorsearch-box .box-input-search {
        width: calc(1220px + 2rem)!important;
    }
    .vendorsearch-box p {
        width: calc(1220px + 2rem)!important;
    }
    .search-page .dropdown-slist {
        width: 20.6%!important;
        top: 50px!important;
        left: 133px!important;
    }
    .gridviewproperty .col-sm-6.list-grid-search {
        flex: 0 0 33.3%!important;
    max-width: 33.3%!important;
    }
    .width-content-392 h6 {
        width: 100%!important;
    }
}
@media only screen and (min-width: 1700px) and (max-width: 2200px){
    .gridviewproperty .property-img {
        height: 250px;
        width: 33%!important;
    }
    .gridviewproperty .content-property{
        width: 67%;
    }
    .grid-and-list {
        height: auto!important;
    }
    .image-grid {
        width: 17.9%!important;
    }
    .searchresult-input {
        min-width: 27.8em!important;
    }
    .vendorsearch-box .box-input-search {
        width: calc(1220px + 2rem)!important;
    }
    .vendorsearch-box p {
        width: calc(1220px + 2rem)!important;
    }
    .search-page .dropdown-slist {
        width: 19.3%!important;
        top: 49px!important;
    }
    .gridviewproperty .col-sm-6.list-grid-search {
        flex: 0 0 33.3%!important;
    max-width: 33.3%!important;
    }
    .width-content-392 h6 {
        width: 100%!important;
    }
}
@media only screen and (min-width: 2201px) and (max-width: 2569px){
    .gridviewproperty .property-img {
        height: 330px;
        width: 100%!important;
    }
    .grid-and-list {
        height: auto!important;
    }
    .image-grid {
        width: 17.9%!important;
    }
    .searchresult-input {
        min-width: 27.8em!important;
    }
    .vendorsearch-box .box-input-search {
        width: calc(1220px + 2rem)!important;
    }
    .vendorsearch-box p {
        width: calc(1220px + 2rem)!important;
    }
    .search-page .dropdown-slist {
        width: 19.3%!important;
        top: 49px!important;
    }
    .gridviewproperty .col-sm-6.list-grid-search {
        flex: 0 0 33.3%!important;
    max-width: 33.3%!important;
    }
    .width-content-392 h6 {
        width: 100%!important;
    }
}
@media only screen and (min-width: 2570px) and (max-width: 2999px){
    .gridviewproperty .property-img {
        width: 41%!important;
    }
    .grid-and-list {
        height: auto!important;
    }
    .image-grid {
        width: 17.9%!important;
    }
    .searchresult-input {
        min-width: 27.8em!important;
    }
    .vendorsearch-box .box-input-search {
        width: calc(1220px + 2rem)!important;
    }
    .vendorsearch-box p {
        width: calc(1220px + 2rem)!important;
    }
    .search-page .dropdown-slist {
        width: 19.3%!important;
        top: 49px!important;
    }
    .gridviewproperty .col-sm-6.list-grid-search {
        flex: 0 0 25%!important;
    max-width: 25%!important;
    }
    .width-content-392 h6 {
        width: 100%!important;
    }
}
@media only screen and (min-width: 3000px) and (max-width: 3100px) {
    .gridviewproperty .property-img {
        height:310px;
        width: 100%!important;
    }
    .grid-and-list {
        height: auto!important;
    }
    .image-grid {
        width: 17.9%!important;
    }
    .searchresult-input {
        min-width: 27.8em!important;
    }
    .vendorsearch-box .box-input-search {
        width: calc(1220px + 2rem)!important;
    }
    .vendorsearch-box p {
        width: calc(1220px + 2rem)!important;
    }
    .search-page .dropdown-slist {
        width: 19.3%!important;
        top: 49px!important;
    }
    .gridviewproperty .col-sm-6.list-grid-search {
        flex: 0 0 25%!important;
    max-width: 25%!important;
    }
    .width-content-392 h6 {
        width: 100%!important;
    }
}
@media only screen and (max-width: 580px){
    .gridviewproperty .property-img,
    .gridviewproperty .content-property{
        width: 100% !important;
    }
    .gridviewproperty .property-img{
        height: 244px !important;
    }
}
@media only screen and (max-width: 1327px){

    .searchresult-input {
        min-width: 12em!important;
    }

}
@media only screen and (min-width: 3101px) {
    .gridviewproperty .property-img {
        width: 41%!important;
    }
    .grid-and-list {
        height: auto!important;
    }
    .image-grid {
        width: 17.9%!important;
    }
    .searchresult-input {
        min-width: 27.8em!important;
    }
    .vendorsearch-box .box-input-search {
        width: calc(1220px + 2rem)!important;
    }
    .vendorsearch-box p {
        width: calc(1220px + 2rem)!important;
    }
    .search-page .dropdown-slist {
        width: 19.3%!important;
        top: 49px!important;
    }
    .gridviewproperty .col-sm-6.list-grid-search {
        flex: 0 0 25%!important;
    max-width: 25%!important;
    }
    .width-content-392 h6 {
        width: 100%!important;
    }
}
@media only screen and (min-width: 5000px) {
    .gridviewproperty .property-img {
        height:520px!important;
    }
}
.location-search .show-room-only label {
    position: relative;
    left: 18px;
    top: 1px;
}
.width-content-392 h6 {
    width: 100%!important;
}
/*-- map-view --*/
button.button-watchlist {
    background-color: #ffffff!important;
    width: 33px;
    height: 33px;
    border-radius: 16px;
}
button.button-watchlist:focus, button.button-watchlist:hover, button.button-watchlist:active {
    background-color: #ffffff!important;
    width: 33px!important;
    height: 33px!important;
    border-radius: 16px!important;
}
.sponsored-tag .width-content-39 {
    padding-left: 15px;
    padding-top: 13px;
    padding-right: 15px;
    padding-bottom: 5px;
}
.grid-sponsored .content-property h6 {
    height: 68px;
    margin-bottom: 11px;
    margin-top: 11px;
    font-family: 'Roboto'!important;
    white-space: normal;
    line-height: 17px;
    font-size: 13px;
}
.map-infoview .gm-style-mtc button {
    height: 36px!important;
    font-size: 15px!important;
}
.map-infoview .gm-style-mtc button:focus {
    outline: 0!important;
}
.map-infoview .gm-style-mtc ul {
    top: 30px!important;
}
.map-infoview .gm-style-mtc ul li span img {
    position: relative;
    top: -3px;
}
.map-infoview .gm-style-mtc ul li label {
    font-size: 15px;
    margin-left: 3px;
}
.map-infoview .gm-style .gm-style-mtc div {
    font-size: 15px!important;
}
.map-infoview .gm-style .gm-style-mtc label {
margin-bottom: 0px!important;
margin-top: 6px!important;
}
.map-infoview button.gm-fullscreen-control {
    height: 35px!important;
    width: 35px!important;
}
.map-infoview button.gm-fullscreen-control img {
    height: 16px!important;
    width: 16px!important;
}
.map-infoview .gm-svpc {
    height: 40px!important;
    width: 40px!important;
}
.map-infoview .gm-svpc img {
    height: 27px!important;
    width: 27px!important;
}
.map-infoview button.gm-control-active img {
    width: 15px!important;
    height: 15px!important;
}
.gridviewproperty .list-grid-search{
    padding-left: 0px !important;
    margin-bottom: 15px !important;
}
.gridviewproperty .property-img img{
    border-radius: 0px !important;
}
.p-list-search table, .p-list-search tbody{
    display: block;
}
.p-list-search tfoot{
    margin: 0 auto;
    display: table;
}
.gird-list-categories .save-wishlist, .gird-list-categories .select-orderby{
    float: right;
}
.search-page .no-records-found{
    color: #d71921 !important;
}
@media only screen and (min-width: 768px){
    .search-page .no-records-found{
        padding-top: 220px;
    }
}
@media only screen and (min-width: 1310px){
    .searchbar-top form{
        width: 84%;
    }
}
@media only screen and (max-width: 1327px){
    .grid-and-list{
        padding-top: 166px;
    }
    body .m-list-search{
        padding-top: 166px !important;
    }
}
@media only screen and (max-width: 500px){
    .search-page .box-search-home{
        display: block;
    }
}
@media only screen and (max-width: 767px){
    body .m-list-search{
        padding-top: 0px !important;
    }
    .grid-and-list{
        padding-top: 0px;
    }
    .searchbar-top .search-heading.location-search-head{
        height: 37px;
    }
}
@media only screen and (min-width: 819px) and (max-width: 1160px){
    .searchbar-top .row{
        width: 90%;
        margin: 0px !important;
    }    
}
@media only screen and (min-width: 767px) and (max-width: 1160px){
    .select-orderby select{
        width: 150px;
    }
}
@media only screen and (min-width: 768px) and (max-width: 1158px){
    .m-list-search{
        width: 60%!important;
    }
    .p-list-search {
        width: 40%!important;
    }
}
@media only screen and (min-width: 768px) and (max-width: 982px){
    .m-list-search{
        width: 50%!important;
    }
    .p-list-search {
        width: 50%!important;
    }
}
.map-relative{
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    position: relative;
}
.map-display-none{
    display: none;
}
.list-no-records{
    text-align: center;
    color: #d71921;
    padding-top: 80px;
}
.alllisting .your-search{
    background: #eff9fd;
    border: 1px solid #ddd;
    padding: 10px;
    font-weight: 500;
    font-size: 16px;
    margin-right: 15px;
    margin-top: 15px;
}
.alllisting .your-search span{
    display: block;
    font-weight: normal;
    font-size: 13px;
}
.search-page .search-resultdropdown .dropdown button.selected-value{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 17px !important;
}
.map-relative [tabindex='0'] {
    cursor: pointer !important;
}
.mapboxgl-popup {
    max-width: 648px;
    max-height: 626px;
}
.mapboxgl-popup img.map-view-img {
    height: 100% !important;
    width: 100%!important;
}
.search-page .map-infoview .map-relative div[tabindex='0']{
	width: 100% !important;
}