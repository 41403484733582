.usermanagement {
    padding: 30px 15px;
}
.user-management-box {
    border: 3px solid #97CB5F;
    border-radius: 30px;
    padding: 15px;
    margin-bottom: 44px;
    background-color: #ffffff;
}
.user-management-box:hover {
    border: 3px solid #97CB5F;
    box-shadow: 0px 0px 6px 2px #ddd;
}
.user-management-box h6 {
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    color: #565656;
}
.img-logo-usermanege {
    width: 155px;
    height: 63px;
    margin: 32px auto;
}
.user-management-box p {
    color: #565656;
    font-size: 13px;
    font-weight: 500;
    text-align: center;
}
.user-management-box .um-link a {
    color: #4c4c4c;
    text-decoration: underline;
    font-size: 13px;
    font-weight: 600;
}
.user-management-box .um-link a:hover, .user-management-box .um-link a:focus, .user-management-box .um-link a:active {
    color: #f26a53;
}
.um-content {
    height: 45px;
    margin: auto;
}
.green-color {
    color: #99c753!important;
}
.orange-color {
    color: #f26a53!important;
}
.img-logo-usermanege-watchlist {
    width: 274px;
    height: 30px;
    margin: 49px auto;
}
.img-logo-usermanege-lss {
    width: 140px;
    height: 64px;
    margin: 32px auto;
}
.usermanagement .row .col-sm-5 a {
    text-decoration: none;
}
.usermanagement .row .col-sm-5 {
    margin: auto;
}