.profile-vendordirectory {
    padding: 25px 0px;
    min-height: calc(130vh - 400px);
    background-color: #ffffff;
    border-top: 1px solid #ddd;
}
.profile-vendordirectory container-fluid {
    padding: 0px 40px 25px;
    display: block;
    background-color:#ffffff;
}
.profile-vendordirectory .heading h3 {
    font-family: 'Lato', sans-serif!important;
    font-size: 24.6px;
    color: #58585a;
    font-weight: 700;
    text-transform: uppercase;
}
.profile-logo {
    height: 110px;
    object-fit: contain;
    margin: 42px auto;
}
.profile-logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
/*-- ads --*/
.vendor-ad-box {
    border: 1px solid #ddd;
    border-radius: 3px;
    margin-bottom: 15px;
}
.vendor-ad-box .img-size-vad {
    text-align: center;
    width: 100%;
    margin: auto;
}
.vendor-ad-box h6 {
    color: #58585a;
    margin: 7px auto 3px;
    text-align: center;
    font-size: 13.4px;
    display: none;
}
/*-- contact box --*/
.contact-box {
    background-color: #faf9f9;
    border: 1px solid #ebeaea;
    border-radius: 3px;
    padding: 13px 13px 15px;
}
.contact-box h6 {
    font-size: 16px;
    color: #EF5823;
    margin-bottom: 10px;
    font-weight: 600;
    font-family: 'Lato', sans-serif!important;
}
.contact-box p {
    font-size: 13px;
    color: #565656;
    margin-bottom: 8px;
    font-weight: 600;
    font-family: 'Lato', sans-serif!important;
}
.contact-box .list .list-item .list-content a {
    color: #565656;
    cursor: pointer;
}
.contact-box .list .list-item .list-content a:hover {
    text-decoration: none;
    cursor: pointer;
}
.contact-box .list {
    display:grid;
}
.contact-box .list .list-item {
    display: inline-flex;
    table-layout: fixed;
    list-style-type: none;
    list-style-position: outside;
    padding: .21428571em 0;
    line-height: 1.14285714em;
    padding-bottom: 8px;
    font-family: 'Lato', sans-serif!important;
    font-weight: 600;
    font-size: 13px;
    color: #58585a;
}
.contact-box .list .list-item svg {
    color: #58585a;
    margin-right: 10px;
}
/*-- gallery --*/
.heading-gallery {
    font-size: 16px;
    font-family: 'Lato';
    font-weight: 700;
    color: #262262;
    margin-top: 15px;
    margin-bottom: 15px;
}
.gallery-row {    
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #ebeaea;
    padding: 15px 0px;
    border-radius: 3px;
}
.gallery-row .display-size img {
    margin-bottom: 15px;
    border-radius: 3px;
}
.gallery-row .col-sm-6:last-child .display-size img {
    margin-bottom: 0px;
}
.profile-description {
    font-size: 13px;
    color: #565656;
    text-align: left;
}
.background-white {
    background-color: #ffffff!important;
} 
.border-white {
    border: none!important;
}
.free-profile-padding {
    padding: 0px 15px!important;
}
.free-profile-padding .list-item {
    margin-bottom: 5px;
}
.free-profile-padding p {
    font-size: 13px;
    color: #58585a;
    margin-bottom: 8px;
    font-weight: 600;
    font-family: 'Lato', sans-serif!important;
}
/*-- condition --*/
.profile-condition .container {
    background-color: #ffffff;
}
.profile-condition {
    padding: 15px 0 20px;
    margin: 15px;
    background-color: #ffffff;
    border: 1px solid #fff;
    border-radius: 2px;
    box-shadow: 0px 3px 7px #ddd;
}
.profile-condition .col-sm-2 {
    display: none;
}
.profile-condition .col-md-8  {
    flex: 0 0 100%;
    max-width: 100%;
}
.profile-condition .row {
    width: 100%!important;
    margin: auto!important;
}
.contact-title {
    font-size: 14px;
    color: #565656;
    font-weight: 500;
    margin-bottom: 7px;
    margin-left: 22px;
    margin-top: -5px;
}
.contact-box p.font-14 {
    font-size: 14px!important;
}