.property-value {
  min-height: calc(100vh - 400px);
  background-color: #ffffff;
  padding-top: 10px;
  }
  .property-value h3 {
    border: none;
    margin: calc(1rem - .14286em) 0 15px;
    padding: 0;
    color: #555759;
    font-size: 24.7px;
    font-weight: 500;
    text-transform: uppercase;
    font-family: 'Lato', sans-serif!important;
  }
  .property-value .container .row {
    position: relative;
    align-items: stretch;
    display: flex;
    width: 100%;
    padding: 0 0 1rem;
    margin: 0px
  }
  .property-value .container .col-sm-12.remove-padding {
    padding: 1rem 0 55px!important;
  }
  .property-value button {
    font-size: 14px;
    font-family: 'Lato', sans-serif!important;
    font-weight: 700!important;
  }
  .request-property {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .request-property .col-md-6, .request-property .col-md-12 {
    padding-left: .5em;
    padding-right: .5em;
  }
  .request-property label {
    display: block;
    margin: 0 0 3px;
    color: rgba(0,0,0,.87);
    font-size: .92857143em;
    font-weight: 700;
    text-transform: none;
  }
  .request-property .form-group .invalid-feedback {
      position: absolute;
      margin: 0px;
  }
.request-property .form-group input:focus, .request-property .form-group input:active  {
    box-shadow: none;
    border: 1px solid #EF5823;
}
@media only screen and (min-width: 280px) and (max-width: 767px) {
    .property-value .container {
        padding: 0px 15px!important;
    }
    .property-value .padding-left-remove, .request-property .padding-remove.col-md-12  {
        padding-left: .5em!important;
    }
    .property-value .padding-right-remove, .request-property .padding-remove.col-md-12 {
        padding-right: .5em!important;
    }
}
button.send-request:hover, button.send-request:focus, button.send-request:active {
  background-color: #97CB5F!important;
  color: #ffffff!important;
  text-decoration: none;
  box-shadow: none!important;
  outline: none!important;
}