.resetpassword-cs {
  background-color: #ffffff;
  padding-bottom: 40px;
}
.resetpassword-cs .col-sm-5 {
  background-color: #ffffff;
  margin: 44px auto;
  box-shadow: 0 1px 2px 0 rgba(34,36,38,.15);
  padding: 35px 40px 40px;
  border-radius: .28571429rem;
  border: 1px solid rgba(34,36,38,.15);
}
.resetpassword-cs p {
  text-align: center;
  margin-bottom: 25px;
}
.resetpassword-cs button {
  box-shadow: none;
  width: 100%;
  padding: 7px;
  background-color: #97CB5F;
  border: none;
  margin-top: 15px;
}
.resetpassword-cs .page-title {
  margin-bottom: 10px;
}
.resetpassword-cs button:hover, .resetpassword-cs button:focus, .resetpassword-cs button:active {
  background-color: #97CB5F;
  box-shadow: none!important;
  outline: 0;
}
.forgot-error {
  background-color: #fff6f6;
  color: #9f3a38;
  text-align: center;
  font-size: 14px;
  padding: 7px;
  margin-top: 7px;
  border: 1px solid #9f3a38;
  border-radius: 4px;
  display: block;
}
.resetpassword-cs .success {
  background-color: #f6fff8;
  color: #389f41;
  text-align: center;
  font-size: 14px;
  padding: 7px;
  margin-top: 7px;
  border: 1px solid #389f41;
  border-radius: 4px;
  display: block;
}
.resetpassword-cs .form-group .invalid-feedback {
  font-size: 10px;
  margin: 0px;
}
.resetpassword-cs .form-group {
  margin-bottom: 5px;
}