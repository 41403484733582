.addpadding-property {
    padding: 28px 15px!important;
}
.property-table table thead tr th.property-width2 {
    width: 230px;
}
.property-table table thead tr th.property-width3 {
    width: 270px;
}
.property-table table thead tr th.width65 {
    width: 65px;
}
.select-status-property select {
    cursor: pointer;
    padding: 1px 0px;
    border-radius: 3px;
    background-color: #EF5823;
    color: #ffffff;
    border: 1px solid #565656;
}
.select-status-property select option {
    background-color: #fafafa;
    color: #4c4c4c;
}
.select-status-property select:hover, .select-status-property select:focus, .select-status-property select:active {
    outline: 0px!important;
    box-shadow: none!important;
    border: 1px solid #565656!important;
}