.userregistration {
    background-color: #ffffff;
    padding-bottom: 30px;
    padding-top: 125px;
}
.userregistration .box-center {
    margin: 0px auto 20px;
    box-shadow: 0px 0px 2px 3px #ddd;
    padding: 0px;
    border-radius: 5px;
}
.userregistration .box-center .row-width {
    margin: 0px;
}
.background-signup {
    background: linear-gradient(90deg,rgba(151,203,95,.82),rgba(151,203,.82)), url("../../../Assets/Img/background-signup.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    color: #ffffff;
    box-shadow: inset 0 0 7px 2px #97cb5f;
    padding: 30px 15px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.userregistration .background-signup h6, .signup-form h6 {
    font-size: 24px;
    font-weight: 600;
    font-family: 'Lato', sans-serif!important;
    color: #EF5823;
    text-align: center;
    margin-bottom: 18px;
}
.userregistration .background-signup h2 {
    font-size: 18px;
    font-weight: 600;
    text-align: left;
    font-family: 'Lato', sans-serif!important;
    text-transform: capitalize;
    margin-bottom: 5px;
}
.userregistration .background-signup img {
    margin-right: 6px;
    width: 22px;
    height: 22px;
}
.userregistration .background-signup p {
    margin-left: 30px;
    font-weight: 400;
}
.signup-form {
    background-color: #ffffff;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.signup-form .row {
    width: 100%;
    margin: 0px;
}
.signup-form {
    padding:20px 15px 15px;
}
.signup-form form .form-group {
    width: 100%;
}
.signup-form form .form-group .form-control {
    height: calc(1.4em + .75rem + 2px);
    padding: 7px 10px;
    font-size: 14px;
    border-radius: 2px;
}
.signup-form form .form-group .form-control:focus, .signup-form form .form-group .form-control:active {
    box-shadow: none!important;
    border-color:#97cb5f ;
    background-color:transparent;
}
.padding-remove-left {
    padding: 0px 10px 0px 0px!important;
}
.padding-remove-right {
    padding: 0px 0px 0px 10px!important;
}
.signup-form h5 {
    font-size: 16px;
    margin-bottom: 5px;
    font-family: 'Lato', sans-serif!important;
    font-weight: 600;
    color: #565656;
}
.signup-form form .form-group .invalid-feedback {
position: absolute;
margin: 0px;
font-size: 10px;
}
.checkbox-signup .form-check,  .checkbox-signup-user .form-check {
    margin-bottom: 2px;
}
.checkbox-signup span, .checkbox-signup-user span {
    margin-left: 18px;
    color: #565656;
}
.checkbox-signup {
    width: 140px;
    cursor: pointer;
}
.checkbox-signup label {
    cursor: pointer!important;
}
.checkbox-signup-80 {
    width: 85px!important;
}
.signup-form form .form-group textarea {
    height: 60px!important;
}
.popup-regisration-selectuser .row.need-margin {
    margin: 0px!important;
    width: 100%;
}
.margin-select-user-row {
    margin: 2px 10px 5px 10px!important;
 }
 .checkbox-signup-user {
     width: 135px!important;
 }
 .error-user-block-90 {
    width: max-content;
    top: 157px;
    left: 26px;
 }
 .error-user-block-146 {
    width: max-content;
    top: 127px;
 }
 .specify-user .form-group {
     width: 100%;
     margin-bottom: 10px;
 }
 .specify-user .form-group input {
    margin-top: 3px;
    height: calc(1.3em + .75rem + 2px);
    border-radius: 2px;
    font-size: 14px;
 }
 .error-user-block-289 {
    top: 243px;
    font-size: 10px;
  }
  .register-user-type .form-group {
      margin-bottom: 0px;
  }
  .register-user-type .form-group input {
    margin: 0px 0px 5px!important;
    border-color: #EF5823!important;
  }
  .error-user-block-337 {
    top: 345px;
    font-size: 10px;
  }
  .error-user-block-27 {
        top: 27px;
        font-size: 10px;
  }
.password-error .form-group .invalid-feedback {
    position: initial!important;
    margin-bottom: -12px!important;
    margin-top: 0px!important;
  }
  .popup-password-email {
      width: 100%;
      margin: auto!important;
  }
  .popup-password-email .col-md-12 {
      padding: 0px 8px!important;
  }
  .popup-password-email .col-md-12 .form-group .invalid-feedback {
      position: absolute;
      margin: 0px;
  }
  .popup-password-email .col-md-12 .form-group input {
    height: calc(1.3em + .75rem + 2px);
    border-radius: 2px;
    font-size: 14px;
   }
  .error-user-block-120 {
    width: max-content;
    top: 85px;
  }
  h5.youarea {
    font-size: 16px;
    margin-bottom: 5px;
    font-family: 'Lato', sans-serif!important;
    font-weight: 600;
    margin-left: 7px;
    color: #565656;
  }
  button.blue-color-btn {
    background-color: #EF5823!important;
    padding: 8px 24px!important;
    vertical-align: initial;
    text-shadow: none;
    border: 1px solid #EF5823!important;
    border-radius: .28571429rem!important;
    font-size: 15px!important;
    text-align: center;
    letter-spacing: 0.3px!important;
    font-family: 'Lato', sans-serif!important;
    -webkit-font-smoothing: antialiased!important;
  }
  button.blue-color-btn:hover, button.blue-color-btn:focus, button.blue-color-btn:active {
    background-color: #EF5823!important;
    border: 1px solid #EF5823!important;
    color: #ffffff!important;
    text-decoration: none;
}
@media only screen and (min-width: 280px) and (max-width: 767px){
    .userregistration .padding-remove-right, .userregistration .padding-remove-left {
        display: none;
    }
}