.section-cendordirectory {
    padding: 0px 0px 25px;
    min-height: calc(155vh - 400px);
    background-color: #ffffff;
    border-top: solid 1px #ddd;

}
.section-cendordirectory .title-separator1 {
    margin-bottom: 13px;
}
.section-cendordirectory container-fluid {
    padding: 0px 40px 25px;
    display: block;
    background-color:#ffffff;
}
/*-- heading --*/
.page-header h3, .page-header h2 {
    text-align: center;
    font-size: 24.7px;
    color: #58585a;
    text-transform: uppercase;
}
.pass-c-signin{
    cursor: pointer;
}
/*-- vendor search --*/
.vendorsearch-box .box-input-search{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    padding: 0;
    margin: auto;
    width: 100%!important;
}
.vendorsearch-box input {
    width: 82.7%;
    border-color: #ddd;
    outline: 0;
    padding: 9px 7px;
    border-image: none;
    border: 1px solid #ddd;
}
.vendorsearch-box button {
    border-radius: 1px;
    padding: 6.9px 35px;
    background-color: #EF5823;
    border-color: #EF5823;
    color: #ffffff;
    font-weight: 500;
    cursor: pointer;

}
.vendorsearch-box button svg {
    width: 13px!important;
    margin: 2px 3px -1px 0px;
}
.vendorsearch-box button:focus, .vendorsearch-box button:active, .vendorsearch-box button:hover {
    box-shadow: none!important;
    outline: 0;
    color: #fff;
    background-color: #EF5823!important;
    border-color: #EF5823!important;
}
.vendorsearch-box input:focus, .vendorsearch-box input:hover {
    border: 1px solid #EF5823;
}
.vendorsearch-box p {
    margin: 4px auto;
    color: #58585a;
    font-weight: 400;
    font-size: 13px;
    font-style: italic;
}
/* -- category vendor --*/
.category-vendor .heading h3 {
    text-transform: capitalize;
    font-size: 24.7px;
    color: #58585a;
    font-weight: 500;
    font-family: 'Lato', sans-serif!important;
}
.category-vendor .heading h3 span {
    color:#58585a;
    text-transform: uppercase;
}
.category-vendor .heading .heading-broder {
    content: '';
    background: #3a3a3c;
    height: 0.50%;
    width:263px;
    position: absolute;
  }
  .category-vendor .category-list {
      margin: 20px 0;
  }
  .category-list .MuiExpansionPanelSummary-root {
    padding: 0px 2px;
    min-height: 18px;
    height: 30px;
  }
  .category-list .MuiExpansionPanelSummary-content {
    margin: 5px 0;
  }
  .category-list .expand-category p,
  .category-list .expand-category p a {
      font-size: 15px;
      color: #565656;
      font-weight: 700;
      font-family: 'Lato', sans-serif!important;
  }
  .category-list .expand-category img {
      margin-right: 7px;
  }
  .category-list .MuiPaper-rounded {
      box-shadow: none;
      margin: 0px 0;
  }
  .category-list .cate-head {
    color:#58585a;
    font-weight: 600;
  }
  .category-list .MuiExpansionPanelDetails-root {
    display: flex;
    background-color: #f7f6f6;
    padding: 4px 11px 4px;
    margin-bottom: 4px!important;
    border-radius: 3px;
    box-shadow: 0px 1px 3px 0px #848486;
}
.category-list .MuiTableCell-root {
     padding: 5px 10px;
     font-size: 14px;
     color: #58585a;
     border-bottom: 0px;
}
.category-list a {
    color: #97CB5F;
    font-size: 13px;
}
.category-list a:hover {
    text-decoration: none;
    color:#97CB5F;
}
.category-list img {
    padding-bottom: 4px;
}
.category-list .cate-head.Mui-expanded {
    min-height: 24px!important;
}
.category-list .MuiExpansionPanelSummary-content.Mui-expanded {
    margin: 0px 0;
}
.category-list .MuiIconButton-edgeEnd span svg {
    display: none;
}
.novalue {
    font-size: 14px;
    text-align: center;
    color: #2f2e2e;
}
/*-- ads --*/
.vendor-ad-box {
    border: 1px solid #ddd;
    border-radius: 3px;
    margin-bottom: 15px;
}
.vendor-ad-box a:hover {
    text-decoration: none!important;
}
.vendor-ad-box .img-size-vad {
    text-align: center;
    width: 100%;
    margin: auto;
}
.vendor-ad-box img {
    border-radius: 2px;
}
.vendor-ad-box h6 {
    color: #58585a;
    margin: 7px auto 3px;
    text-align: center;
    font-size: 13.4px;
    display: none;
}
.remove-underline a {
    text-decoration: none!important;
}
@media only screen and (min-width: 768px) and (max-width: 996px) { 
    .category-vendor .MuiExpansionPanelSummary-content p {
        white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 150px;
    font-size: 14px;
    }
    .category-vendor .col-md-4 {
        padding: 0 15px 0 0 ;
        margin-bottom: 30px;
    }
    .vendorsearch-box input {
        width: 68%;
    }
    .vendor-ad-box .img-size-vad {
        width: 100%!important;
    }
    .category-vendor .heading .margin-b-3 {
        margin-bottom: 20px;
    }    
}
@media only screen and (min-width: 997px)  and (max-width: 1199px){
    .category-vendor .MuiExpansionPanelSummary-content p {
        white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 200px;
    font-size: 14px;
    }
    .category-vendor .col-md-4 {
        padding: 0 30px 0 0 ;
        margin-bottom: 30px;
    }
    .vendorsearch-box input {
        width: 76.2%;
    }
    .vendor-ad-box .img-size-vad {
        width: 100%!important;
    }
    .category-vendor .heading .margin-b-3 {
        margin-bottom: 20px;
    } 
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .category-vendor .category-list {
    margin: 15px 0;
  }
  .category-vendor {
    padding:0px 15px!important;
}
.vendorsearch-box .box-input-search, .vendorsearch-box p {
    width: 100%!important;
}
.vendorsearch-box input {
    width: 76%;
}
.vendorsearch-box button {
    padding: 7.2px 7px;
}
.vendorsearch-box button svg {
    display: none;
}
.section-cendordirectory container-fluid {
    padding: 10px 15px;
}
}
@media only screen and (min-width: 480px) and (max-width: 767px){
    .vendorsearch-box input {
        width: 64%!important;
    }
    .vendorsearch-box .box-input-search {
        width: 493px!important;
    }
    .vendorsearch-box p {
        width: 333px!important;
    }
    .vendor-ad-box .img-size-vad {
        width: 100%;
    }
    .category-vendor .heading h3 {
        text-transform: capitalize;
        font-size: 20px;
    }
}
@media only screen and (min-width: 1000px) and (max-width: 1030px){
    .vendorsearch-box input {
        width: 76.2%!important;
    }
}
@media only screen and (min-width: 1200px) and (max-width: 1250px){
    .vendorsearch-box input {
        width: 80.2%!important;
    }
}
@media only screen and (min-width: 1270px) and (max-width: 1289px){
    .vendorsearch-box input {
        width: 80.7%;
    }
}
@media only screen and (min-width: 1301px) and (max-width: 1310px){
    .vendorsearch-box input {
        width: 79.7%;
    }
}
@media only screen and (min-width: 1290px) and (max-width: 1300px){
    .vendorsearch-box input {
        width: 79.7%;
    }
}
@media only screen and (min-width: 1320px) and (max-width: 1330px){
    .vendorsearch-box input {
        width: 80.7%;
    }
}
@media only screen and (min-width: 1521px) and (max-width: 1599px){
    .vendorsearch-box .box-input-search {
        width: calc(918px + 2rem)!important;
    }
    .vendorsearch-box input {
        width: 84.7%;
    }
.vendorsearch-box p {
    margin: 4px auto;
    width: calc(918px + 2rem)!important;
}
}