@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
.errorMessage {
    position: absolute;
    margin-top: 0px;
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #dc3545;
}
.filewidth{
    position: relative;
    display: inline-block;
    width: 90px;
    color: #7f7f7f;
    overflow: hidden;
}
@-moz-document url-prefix() {
    .filewidth {
    width: 78px;
}
}
body {
    margin: 0;
    font-family: 'Roboto';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: 0px!important;
    width: 100%!important;
    overflow: auto!important;
   
}
h1, h2, h3, h4, h5, h6 {
    font-family: 'Lato', sans-serif!important;
}
html body {
    font-family: 'Roboto' !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.marginall-auto {
    margin: auto!important;
}

.cursor-pointer {
    cursor: pointer!important;
}

.disply-flex {
    display: inline-flex;
}
.margin-r-0 {
    margin-right: 0px!important;
}
.marign-t-8 {
    margin-top: 8px;
}
.marign-l-5 {
    margin-left: 5px;
}
.margin-b-9 {
    margin-bottom: 9px;
}
.margin-r-10 {
    margin-left: 10px;
}
.margin-l-10 {
    margin-left: 10px;
}
.margin-l-0 {
    margin-left: 0px!important;
}
.margin-t-15 {
    margin-top: 15px!important;
}
.margin-b-15 {
    margin-bottom: 15px;
}
.margin-t-10 {
    margin-top: 10px!important;
}
.margin-b-10 {
    margin-bottom: 10px!important;
}
.margin-t-20 {
    margin-top: 20px!important;
}
.margin-b-20 {
    margin-bottom: 20px;
}
.margin-t7 {
    margin-top:7px!important
}
.margin-t-35 {
    margin-top: 35px!important;
}
.margin-b-35 {
    margin-bottom: 35px;
}
.margin-t-30 {
    margin-top: 30px;
}
.margin-b-30 {
    margin-bottom: 30px;
}
.margin-t-5 {
    margin-top: 5px!important;
}
.margin-b-5 {
    margin-bottom: 5px;
}
.margin-t-12 {
    margin-top: 12px;
}
.margin-b-0 {
    margin-bottom: 0px!important;
}
.float-right {
    float: right;
}
.float-left {
    float: left;
}
.width-100 {
  width: 100%;
}
.height-100 {
  height: 100%;
}
.max-width-100 {
    max-width: 100%!important;
}
.padding-t40 {
    padding-top: 40px;
}
.padding-t-25 {
    padding-top: 125px!important;
}
.background-transparent {
    background-color: transparent;
}
.no-border {
    border: none!important;
}
/*-- no margin --*/

.no-bottom-margin {
    margin-bottom: 0px!important;
}
.no-top-margin {
    margin-top: 0px!important;
}
.no-left-margin {
  margin-left:0px!important;
}
.no-right-margin {
  margin-right:0px!important;
}
.margin-left-auto {
    margin-left: auto;
}
.margin-0 {
    margin: 0px!important;
}
.margin-b-32 {
    margin-bottom: 32px;
}
.margin-b25 {
    margin-bottom: 25px;
}
.margin-t25 {
    margin-top: 25px;
}
.margin-t40 {
    margin-top: 40px;
}
.margin-t-10 {
    margin-top: 10px;
}
.header-h3, .header-h1 {
    border: none;
    margin: calc(1rem - .14286em) 0 15px;
    padding: 0;
    color: #555759;
    font-size: 24.7px;
    font-weight: 500;
    text-transform: uppercase;
    font-family: 'Lato', sans-serif!important;
}
/*-- separator --*/

.leftColor-separator {
    height: 1px;
    position: relative;
    margin-top: 12px;
    background: #ebebeb;
}

.margin-b-3 {
    margin-bottom: 30px;
}
.margin-b-6 {
    margin-bottom: 6px!important;
}
.leftColor-separator:after {
    background: #EF5823;
    content: "";
    top: -1px;
    left: 0;
    bottom: 0;
    right: 0;
    width: 80px;
    height: 3px;
    position: absolute;
    z-index: 0;
}
.tablevertical {
    display: flex!important;
    vertical-align: initial!important;
    border-bottom: none!important;
    border-right: none!important;
}

/*-- separator title --*/
.title-separator1 {
    height: 1px;
    position: relative;
    margin: 0 auto;
    background: #ccc;
    z-index: 0;
    font-size: 13px;
}
.title-separator1:after {
    content: "";
    top: -1px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 80px;
    height: 3px;
    position: absolute;
    z-index: 1;
    background: #EF5823;
}
/*-- padding remove --*/

.padding-left-remove {
    padding-left: 0px!important;
}

.padding-right-remove {
    padding-right: 0px!important;
}
.padding-bottom-remove {
    padding-bottom: 0px!important;
}
.padding-remove {
    padding: 0px!important;
}
.padding-l-8 {
    padding-left: 8px!important;
}
.padding-r-8 {
    padding-right: 8px!important;
}
.padding-8 {
    padding: 0px 8px!important;
}
/*-- padding --*/
.padding-30 {
    padding: 30px;
}
/*-- text align --*/

.text-align-right {
    text-align: right!important;
}
.text-align-center {
    text-align: center!important;
}

/*-- button --*/

.save-right-button button {
    cursor: pointer;
    display: inline-block;
    outline: 0;
    border: none;
    vertical-align: initial;
    background: #97CB5F;
    color: #ffffff;
    font-size: 14px;
    padding: 8px 21px;
    font-weight: 700;
    border-radius: .28571429rem;
}

.save-right-button button:focus,
.save-right-button button:active,
.save-right-button button:hover {
    background-color: #97CB5F;
    box-shadow: none;
}

button.reset-btn {
    background-color: #565656!important;
    border: 1px solid #565656!important;
    padding: 6px 14px!important;
    border-radius: 3px;
    color: #ffffff!important;
    font-size: 13px;
    margin-right: 10px;
    font-weight: 500;
    margin-left: 10px;
    margin-top: 0px;
    box-shadow: none !important;
    cursor: pointer;
}
/*-- admin buttons --*/
.add-new-btn a,
.add-new-btn button,
.add-new-btn span {
    background-color:  #EF5823;
    padding: 8px 14px;
    border-radius: 2px;
    color: #ffffff;
    font-size: 13.5px;
    font-weight: 500;
    border: 1px solid #EF5823;
}
.add-new-btn a svg, .add-new-btn button svg, .add-new-btn span svg {
    margin-right: 5px;
}
.add-new-btn button {
    padding: 6px 8px!important;
}
.add-new-btn a:hover,
.add-new-btn a:active,
.add-new-btn a:focus,
.add-new-btn button:hover,
.add-new-btn button:active,
.add-new-btn button:focus,
.add-new-btn span:hover,
.add-new-btn span:focus,
.add-new-btn span:active {
    color: #ffffff;
    text-decoration: none;
    background-color: #EF5823!important;
    cursor: pointer;
    box-shadow: none!important;
    outline: 0!important;
    border: 1px solid #EF5823!important;
}
/*-- height --*/
.heigth-78 {
    height: 78px;
}
/*-- admin header --*/
.form-back-shadow {
    border: 1px solid #fff;
    margin: 0 15px 20px 15px;
    border-radius: 2px;
    padding: 10px 15px;
    background-color: #fff;
    box-shadow: 0px 3px 7px #ddd;
}
.header-name-box .col-sm-8 {
    display: flex;
}
.header-name-box h6 {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 15px;
    margin-left: 0px;
    color: #97CB5F;
    font-size: 16px;
    font-family: 'Roboto'!important;
}
.header-name-box .row.header-count {
    margin: 0px;
}
.header-name-box .row.header-count h5 {
    font-size: 13px;
    color: #000000;
}
.header-name-box .row.header-count h4 {
    font-size: 18px;
    color: #848484;
    margin-top: 20px;
    margin-bottom: 0px;
}
.header-name-box .row.header-count .counter-box {
    border-left: 1px solid #ddd;
    padding: 0px 10px;
    width: 120px;
    text-align: center;
}
.last-box-border {
    border-right: 1px solid #ddd;
}
.header-name-box .col-sm-4 {
    margin: auto;
}
.header-name-box .col-sm-4 .add-new-btn {
    float: right;
}
/*-- Fliter --*/
.filters-search form .col-sm-5 .row.marign-auto {
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
}
.filters-search form .form-group .form-control {
    height: calc(1.5em + .52rem + 2px);
    padding: 5px;
    font-size: 14px;
    background-color: #faf9f9;
    border: 1px solid #cacaca;
}
.filters-search form .form-group .form-control:focus {
    box-shadow: none;
    border: 1px solid #97CB5F;
}

.filters-search form .col-sm-5 .row.marign-auto .form-check-input {
    position: absolute;
    margin-top: .2rem;
    margin-left: -1.1rem;
}

.filters-search form .col-sm-5 .row.marign-auto span {
    margin-right: 15px;
}

.filters-search form .col-sm-5 .row.marign-auto .is-touched {
    color: #99c753;
    background-color: #99c753;
}

.filters-search label {
    display: inline-block;
    margin-bottom: .5px;
    font-size: 13px;
    color: #495057;
}

button.search-btn {
    background-color: #97CB5F;
    border: 1px solid #97CB5F;
    padding: 5px 10px;
    border-radius: 3px;
    color: #ffffff;
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
}

button.search-btn svg {
    width: 10px;
    margin: 2px 5px -1px 0px;
}

button.search-btn:hover,
button.search-btn:focus,
button.search-btn:active {
    background-color: #97CB5F!important;
    box-shadow: none!important;
    outline: 0;
    border: 1px solid #97CB5F!important;
}

button.next-btn {
    background-color: #EF5823;
    padding: 5px 10px;
    border-radius: 2px;
    color: #ffffff;
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
    border: 1px solid #EF5823;
    margin: 0px 10px;
}

button.next-btn svg {
    width: 10px;
    margin: 2px 5px -1px 0px;
}

button.next-btn:hover,
button.next-btn:focus,
button.next-btn:active, button.next-btn:not(:disabled):not(.disabled):active {
    background-color: #EF5823!important;
    box-shadow: none!important;
    outline: 0!important;
    border: 1px solid #EF5823!important;
}
button.button-back-cancel {
    background-color: #565656!important;
    border: 1px solid #565656!important;
    padding: 5px 10px;
    border-radius: 2px;
    color: #ffffff;
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
    color: #ffffff!important;
    margin: 0px 0px 0px 10px;
}
button.button-back-cancel:focus, button.button-back-cancel:active, button.button-back-cancel:hover {
    background-color: #565656!important;
    border: 1px solid #565656!important;
    outline: none!important;
    box-shadow: none!important;
}
button.select-btn {
    padding: 3px 9px;
    font-size: 12px;
    background-color: #97CB5F;
    border: 1px solid #97CB5F;
    border-radius: 3px;
}

button.select-btn:focus,
button.select-btn:active,
button.select-btn:hover {
    background-color: #97CB5F;
    border: 1px solid #97CB5F;
    box-shadow: none;
    outline: 0;
}

input.ok-btn {
    padding: 6px 22px;
    font-size: 12px;
    background-color: #EF5823;
    border: 1px solid #EF5823;
    border-radius: 3px;
    color: #ffffff;
    margin-top: 15px;
}

input.ok-btn:focus,
input.ok-btn:active,
input.ok-btn:hover {
    background-color: #EF5823;
    border: 1px solid #EF5823;
    box-shadow: none;
    outline: 0;
}


/*-- total count --*/
.total-numb p {
    text-align: left;
    font-size: 13px;
    font-weight: 500;
    color: #000000;
}

/*-- table --*/
.table-model table tr {
    border: 1px solid #ddd;
}

.table-model table thead tr th {
    background-color: #979797;
    padding: 5px 10px;
    color: #6f6d6d;
    font-weight: 500;
    font-size: 13px;
    line-height: normal;
}

.table-model table tbody tr td {
    padding: 6px 10px!important;
    font-size: 13px;
    line-height: initial;
}
.table-model table tbody tr td a{
    color: #ffffff;
}
.table-model table tbody tr td:last-child,
.table-model table thead tr th:last-child {
    border-right: 1px solid #dddddd;
}

.table-model table tbody tr:nth-child(odd) {
    background: rgb(250 249 249);
}

.table-model table tbody tr:nth-child(even) {
    background: #ffffff;
}

.table-model tfoot.MuiTableFooter-root button.MuiIconButton-root:focus {
    outline: none!important;
}

.table-model tbody tr td img {
    margin-left: 3px;
    margin-right: 5px;
    width: 20px;
}
.table-model table, .table-model table tfoot tr td {
    border-bottom: 0px!important;
}
.table-model table tfoot tr {
    border-left: none!important;
    border-right: none!important;
    border-bottom: 0px!important;
}
.table-model table tfoot tr td {
    text-align: right!important;
    padding-right: 0px!important;
}
.table-model table tfoot tr td .seaarch-pagination li button:focus {
    outline: 0px!important;
}
/*table-scroll*/

.table-scroll table tr {
    border: 1px solid #ddd;
    border-top: none!important;
}

.table-scroll table tbody tr td {
    padding: 6px 14px;
    vertical-align: middle;
    font-size: 13px;
    line-height: initial;
    word-break: break-all
}

.table-scroll table tbody tr:nth-child(odd) {
    background: rgb(250 249 249);
}

.table-scroll table, .table-scroll table tfoot tr td {
    border-bottom: 0px!important;
}
.table-scroll table tfoot tr {
    border-left: none!important;
    border-right: none!important;
    border-bottom: 0px!important;
}
.table-scroll table tfoot tr td {
    text-align: right!important;
    padding-right: 0px!important;
}

.table-scroll table tbody tr:nth-child(even) {
    background: #ffffff;
}

.table-scroll tbody {
    display: inline-block;
    max-height: 261px;
    overflow: auto;
}

.table-scroll thead,
.table-scroll tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.table-scroll thead {
    width: calc( 100% - 0em);
    background-color: rgb(173 172 172 / 81%);
    color: #ffffff;
}

.table-scroll thead tr th {
    background-color: rgb(173 172 172 / 81%);
    padding: 5px 14px;
    color: #ffffff;
    line-height: initial;
    font-weight: 500;
    font-size: 13px
}

.table-scroll tbody tr {
    display: table;
}
.table-scroll tbody tr td:last-child, .table-model thead tr th:last-child {
  border-right: 1px solid #ddd!important;
}
.table-scroll table, .table-scroll table tfoot tr td {
    border-bottom: 0px!important;
}
.table-scroll table tfoot tr {
    border-left: none!important;
    border-right: none!important;
    border-bottom: 0px!important;
}
.table-scroll table tfoot tr td {
    text-align: right!important;
    padding-right: 0px!important;
}
.table-scroll table tfoot tr td .seaarch-pagination li button:focus {
    outline: 0px!important;
}
/*-- error msg --*/
.error-msg {
  position: relative;
  color: #dc3545;
  font-size: 80%;
  width: 100%;
}
.table-scroll tbody tr td:last-child, .table-model thead tr th:last-child {
    border-right: 1px solid #ddd!important;
}
/*-- delete id --*/
#react-confirm-alert .react-confirm-alert-overlay {
    background: rgb(51 51 51 / 35%)!important;
}
#react-confirm-alert .react-confirm-alert-body {
    border-radius: 5px!important;
    text-align: center!important;
    font-size: 15px!important;
    font-family: 'Roboto'!important;
}
#react-confirm-alert .react-confirm-alert-button-group {
    justify-content: center!important;
}
/*-- about Investor--*/

.rvparkheadtext h3{
    margin-bottom: 10px;
    color: #000;
    font-size: 2.5rem;
    font-weight: 500;
    text-align: center;
}
.rvparkheadtext p{
    color: #58585a;
    padding: 20px 180px 20px 180px;
    text-align: center;
    font-size: 1rem;
}
.about-rv-in{
    padding: 70px 0px 0px 0px;
}
.about-rv-in h3{
    margin-bottom: 10px;
    color: #000;
    font-size: 2.5rem;
    font-weight: 500;
   
}
.about-rv-in h3 span{
    color: #58585a;
}

.about-rv-in h2{
    margin-bottom: 10px;
    color: #58585a;
    font-size: 24px;
}
.about-rv-in h4{
    margin-bottom: 10px;
    color: #58585a;
    font-size: 18px;
}
.about-rv-in p{
    margin-bottom: 15px;
    color: #58585a;

    font-size: 1rem;
}
.about-rv-in p span{
    color: #97cb5f;
}
.about-rv-in p a{
    color: #97cb5f;
    font-size: 14px;
    text-decoration: underline;
}
.about-rv-in p a:hover{
    color: #97cb5f;
    font-size: 14px;
    text-decoration: none;
}
.featured-cont.container
{
    padding-bottom: 0px !important;
}
.property-de-main
{
    display: flex;
}

.property-de-cont ul{
    margin: 0px;
    padding: 0px;
}
.property-de-cont ul li.propertydets{
    list-style: none;
    font-size: 14px;
    line-height: 18px;
    text-align: left;
    background-image: url("../src/Assets/Img/pz-amenities.png");
    background-repeat: no-repeat;
    background-size: 30px;
    padding: 7px 0 7px 40px;
    background-position: 0px 0px;
    color: #58585a;
    margin-bottom: 20px;
}
.property-de-cont ul li.average-pa{
    list-style: none;
    font-size: 14px;
    line-height: 18px;
    text-align: left;
    background-image: url("../src/Assets/Img/average-park.png");
    background-repeat: no-repeat;
    background-size: 30px;
    padding: 7px 0 7px 40px;
    background-position: 0px 0px;
    color: #58585a;
    margin-bottom: 20px;
}
.property-de-cont ul li.amenitie-icn{
    list-style: none;
    font-size: 14px;
    line-height: 18px;
    text-align: left;
    background-image: url("../src/Assets/Img/Amenities_icon.png");
    background-repeat: no-repeat;
    background-size: 30px;
    padding: 7px 0 7px 40px;
    background-position: 0px 0px;
    color: #58585a;
    margin-bottom: 20px;
}
.property-de-cont ul li.supply-icn{
    list-style: none;
    font-size: 14px;
    line-height: 18px;
    text-align: left;
    background-image: url("../src/Assets/Img/Supply.png");
    background-repeat: no-repeat;
    background-size: 30px;
    padding: 7px 0 7px 40px;
    background-position: 0px 4px;
    color: #58585a;
    margin-bottom: 20px;
}
.property-de-cont ul li.seasonality-icn
{
    list-style: none;
    font-size: 14px;
    line-height: 18px;
    text-align: left;
    background-image: url("../src/Assets/Img/seasonality_icon.png");
    background-repeat: no-repeat;
    background-size: 30px;
    padding: 7px 0 7px 40px;
    background-position: 0px 0px;
    color: #58585a;
    margin-bottom: 20px;
}
.property-de-cont ul li.valuation-icn
{
    list-style: none;
    font-size: 14px;
    line-height: 18px;
    text-align: left;
    background-image: url("../src/Assets/Img/Valuation_icon.png");
    background-repeat: no-repeat;
    background-size: 30px;
    padding: 7px 0 7px 40px;
    background-position: 0px 0px;
    color: #58585a;
    margin-bottom: 20px;
}
.about-in-img
{
padding: 0px 30px 30px 30px;
    width: 100%;
}
.about-in-img img{
    width: 100%;
}

/*-- no record found --*/
.no-records-found {
    text-align: center;
    color: #979797!important;
    font-family: 'Roboto'!important;
    font-size: 14px;
    padding: 22px;
    border-radius: 3px;
    margin: 30px 0px;
}
.text-danger label {
    color: #212121!important;
}
.no-bottom-border {
    border-bottom: none!important;
}
.width65 {
    width: 65px!important;
}
.width75 {
    width: 65px!important;
}
.active-d .MuiListItem-button{
    background-color: rgba(0, 0, 0, 0.04);
}
.lineheight25 {
    line-height: 25px;
}
.margin-t-23 {
    margin-top: 23px;
}
.margin-b-3 {
    margin-bottom: 3px!important;
}
.display-content {
    display: contents!important;
}
.uppercasebolder {
    text-transform: uppercase;
    font-weight: bolder;
}
.margintop-9px {
    margin-top: -9px;
}
input[type=checkbox], input[type=radio] {
    outline: none!important;
    cursor: pointer!important;
}
button, input, optgroup, select {
    cursor: pointer!important;
}
.displaynone{
    display :none !important;
  }
.text-trans-initial {
    text-transform: initial!important;
}
#react-confirm-alert .react-confirm-alert-button-group button:first-child {
    background-color: #EF5823;
}
#react-confirm-alert .react-confirm-alert-button-group button:last-child {
    background-color: #565656;
}
#react-confirm-alert .react-confirm-alert-button-group button:focus {
    outline: 0!important;
    border: none!important;
}
body.react-confirm-alert-body-element {
    overflow: auto!important;
}
.react-confirm-alert-overlay{
    z-index: 1055!important;
}
  /* Scrollbar styles */
  .modal-open .modal::-webkit-scrollbar {
    width: 1px;
    height: 1px;
    }
    
    .modal-open .modal::-webkit-scrollbar-track {
    background: #f5f5f5;
    border-radius: 10px;
    }
    
    .modal-open .modal::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #ccc;  
    }
    
    .modal-open .modal::-webkit-scrollbar-thumb:hover {
    background: #979797;  
    }
    .width-230 {
        width: 230px!important;
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: #97CB5F!important;
        border-width: 1px!important;
      }
 
  a.textdecoration {
    text-decoration: none!important;
}

img.map-view-img {
    width: 235px !important;
    height: 155px !important;
    margin-bottom: 15px !important;
    max-width: none;
}

.map-view a  h6 {
    font-size: 16.7px;
    color: #555759;
    font-weight: 700;
    width: 235px;
    font-family: 'Lato', sans-serif!important;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
a.textdecoration p {
    color: #555759 !important;
    width: 235px;
    margin-bottom: 3px;
    font-size: 13px;
}
.modal-dialog .listing-img-resize img{
    max-width: 100%;
}
.about-us-page{
    min-height: calc(100vh - 400px);
    background-color: #fff;
    padding-top: 122px;
    padding-bottom: 20px;
    border-top: solid 1px #ddd;
}
.about-us-page h3 {
    border: none;
    margin: calc(1rem - .14286em) 0 15px;
    padding: 0;
    color: #555759;
    font-size: 24.7px;
    font-weight: 500;
    text-transform: uppercase;
    font-family: "Lato",sans-serif!important;
}
.about-us-page .margin-b-3{
    margin-bottom: 20px !important;
}
.about-us-page p{
    font-size: 13px;
    color: #7d8080;
    font-family: "Lato",sans-serif!important;
    margin-bottom: 10px;
}
.about-us-page p a{

    color: #04D816;
}
.about-us-page .socialicon{
margin-top: 10px !important;
}

.header-top-bar-social li.dashboard-icon a{
    width: auto;
    padding: 13px 10px;
    height: 40px;
}
.header-top-bar-social li.dashboard-icon a svg{
    margin-right: 3px;
}
.clear{
    clear: both;
}
.inputbox-search .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot{
    padding-right: 0px;
}
.box-search-home{
    margin-top: 10px;
}
.home-content .blue-large-button{
    font-weight: normal;
    width: auto;
    padding: 10px 40px;
    margin: 0 auto;
    display: table;
    margin-top: 20px;
    background: #fff;
    color: #97CB5F;
    border: 1px solid;
}
.clear{
    clear: both;
}
.table-property-tag .property-name, .table-property-tag .property-type{
    font-weight: 600;
    text-align: center;
    font-size: 22px;
    font-family: "Lato",sans-serif!important;
    color: #555759;
    line-height: 1.28571429em;
}
.table-property-tag .property-name{
    margin-bottom: 5px;
}
.property-breadcrumb{
    margin-top: 15px;
}
.property-breadcrumb a{
    color: #04D816 ;
    font-size: 13px;
    text-decoration: underline ;
}
.property-breadcrumb a:hover{
    color: #04D816;
}
.property-v-description a{
    color: #04D816;
    text-decoration: underline ;
}
.property-v-description a:hover{
    color: #04D816;
}
.property-breadcrumb svg{
    margin-left: 5px;
    margin-right: 5px;
    color: #868585;
}
.table-propertydetails table tr{
    width: 49%;
    float: left;
    border: 1px solid #dee2e6;
    border-top: none;
}
body .table-propertydetails table tr td{
    width: 50%;
    display: block;
    float: left;
    border-bottom: none;
}
.table-propertydetails table tr:nth-child(odd){
    margin-right: 10px;
}
body .table-propertydetails table{
    border: none;    
}
.table-propertydetails table tr:first-child,
.table-propertydetails table tr:nth-child(2){
    border-top: 1px solid #dee2e6;
}
.Property-contact-box .contact-heading{
    font-size: 16px;
}
.home-content .home-property .carousel-root,
.property-details .home-property .carousel-root{
    height: 100% !important;
    width: 45%;
    float: left;
}
.home-content .home-property .carousel .thumbs-wrapper{
    margin: 0px;
}
.home-content .home-property .content-property{
    width: 55%;
    display: block;
    float: left;
    border: none;
    border-radius: 0px;
}
.home-content .tags-tss{
    right: 0px;
    left: 14px;
}
.home-content .img-home-property{
    height: 150px !important;
    background-size: cover;
    border-radius: 0px !important;
    width: 100% !important;
}
.home-content .home-property{
    border: 1px solid #dddddd;
}
.home-content .col-custom-padding{
    padding-right: 10px;
    padding-left: 10px;
}
@media (max-width: 1300px){
    .fixed-legend{
        bottom: 20px;
    }
}
@media (min-width: 1300px){
    .fixed-legend{
        bottom: 20px;
    }
}
@media (min-width: 1200px){
    .home-content .container{
        max-width: 1200px;
    }
    .banner-homepage .container{
        max-width: 1200px;
        padding-left: 0px;
        padding-right: 0px;
    }
    .home-content .tags-tss,
    .propertyview-hotlist .tags-tss{
        width: 40%;
        display: block;
    }
    .home-content .tags-tss div,
    .propertyview-hotlist .tags-tss div{
        margin-bottom: 5px;        
    }
}
.select-distance{
    font-size: 12px;
    padding: 4px 5px;
    margin-left: 7px;
    border: 1px solid #ccc;
    outline: none;
}
@media (min-width: 600px){
    .home-content .carousel .slide img{
        height: 124px;
    }    
}
@media (min-width: 767px){
    .gridviewproperty .property-img img{
        height: 150px;
    }
}
@media (min-width: 768px){
    .property-details-right .property-heading, .property-taxes-periods .description-heading,
    .desktop-insight-details .property-heading .events-none{
        pointer-events: none;
    }
    .property-details-right .property-heading svg, .property-taxes-periods .description-heading svg,
    .desktop-insight-details .property-heading svg{
        display: none;
    }
    .mobile-contact, .mobile-map, .mobile-details-insight{
        display: none;
    }
    .desktop-view-contact, .desktop-map, .desktop-insight-details{
        display: block;
    }
}
@media (max-width: 404px){
    body .real-market-data h3{
        font-size: 1.3rem;
    }
    body .real-market-data h5{
        font-size: 1rem;
    }
    body .real-market-data .view-pricing-link, body .real-market-data .compare-plans{
        font-size: 13px;
    }    
    body .marketview-platform-popup .view-sample-report{
        padding: 8px 8px;
        font-size: 13px;
    }
}
@media (max-width: 767px){
    .mobile-details-insight .property-heading svg{
        position: absolute;
        right: 10px;
        width: 0.5em;
        margin-top: -40px;
    }
    .faqpopup.marketview-platform-popup .modal-body ul.ten-miles{
        margin-bottom: 0px;
    }
    .faqpopup.marketview-platform-popup .modal-body ul.self-storage-ul{
        margin-top: 0px;
    }
    body .agent-contact{
        margin: 15px 0 1rem;
    }
    body .propertydetailsads{
        height: auto !important;
    }
    .property-details-right .property-heading{
        border-bottom: 1px solid rgb(225 225 225);
        padding-bottom: 5px;
    }
    .property-details-right .property-heading svg, .property-taxes-periods .description-heading svg{
        width: 0.6em;
        float: right;
        margin-right: 10px;
        margin-top: 5px;
    }
    .mobile-contact, .mobile-map, .mobile-details-insight{
        display: block;
    }
    .mobile-banner-three{
        margin-top: 25px;
    }
    body .Property-contact-box{
        margin-bottom: 15px;
    }
    .desktop-view-contact, .desktop-map, .desktop-insight-details, .mobile-details-insight .button-with-icon{
        display: none;
    }
    .mobile-view-property-market{
        padding: 7px 15px 8px 15px;
        background: #EF5823;
        color: #fff !important;
        font-size: 14px;
        border-radius: 3px;
        border-bottom: none !important;
        font-weight: normal;
        text-decoration: none !important;
        margin: 0 auto;
        display: table;
        margin-bottom: 10px;
    }
    .mobile-contact a{
        margin-bottom: 25px;
    }
    body .market-explorer-logo{
        margin-bottom: 15px;
    }
    body .lisiting-page-mobile{
        padding: 0px;
    }
    .mobile-details-insight .powered-by{
        display: block;
        margin-left: 0px;
        margin-top: 10px;
    }
    body .mobile-details-insight{
        padding-right: 0;
    }
    .mobile-details-insight .market-data-table, .mobile-details-insight .market-data-table tbody,
    .mobile-details-insight .market-data-table tbody tr{
        display: block;
    }
    .mobile-details-insight .market-data-table .market-data-label,
    .mobile-details-insight .market-data-table .market-data-value{
        display: block;
        width: 50%;
        float: left;
        border: none;
        border-top: 1px solid #dee2e6;
    }
    .mobile-details-insight .market-data-table tr:first-child td:first-child,
    .mobile-details-insight .market-data-table tr:first-child td:nth-child(2){
        border-top: none;
    }
    .mobile-details-insight .market-data-table tbody tr:last-child::after{
        content: '';
        clear: both;
        display: block;
    }
}
@media (max-width: 480px){
    body .market-explorer-logo span{
        display: block;
        margin-left: 0;
        top: 0px;
        margin-top: 5px;
    }
    .listrvparks .about-us-page{
        padding-left: 10px;
    }
}
@media (max-width: 766px){
    .gridviewproperty .property-img img{
        height: 150px;
    }
}
@media (max-width: 891px){
    body .table-propertydetails table tr{
        width: 100%;
    }
    body .table-propertydetails table tr:nth-child(odd){
        margin-right: 0px;
    }
    body .table-propertydetails table tr:nth-child(2){
        border-top: none;
    }
    .only-lss-logo img{
        width: 130px;
    }
    .only-mvp-logo img{
        width: 120px;
    }
}
@media (max-width: 340px){
    body .map-property a.view-property-market{
        padding: 10px 20px;
    }
}
.fixed-legend{
    position: absolute;
    z-index: 1;
    background: #fff;   
    left: 10px;
    padding: 10px;
    border-radius: 5px;
    font-size: 13px;
}
.fixed-legend label{
    font-weight: 500;
    margin-bottom: 5px;
}
.fixed-legend .map-legends span{
    width: 10px;
    height: 10px;
    display: inline-block;    
    margin-right: 5px;
    border-radius: 10px;
}
.fixed-legend .map-legends span.orange-label{
    background: #D90429;
}
.fixed-legend .map-legends span.dev-label{
    background: #274C77;
}
.fixed-legend .map-legends span.con-label{
    background: #01890a;
}

.fixed-legend .map-legends span.land-label{
    background: #7C6F02;
}
.fixed-legend .map-legends span.land-blue-label{
    background: #7c0187;
}
.search-page .searchresult-input .MuiAutocomplete-endAdornment .MuiAutocomplete-popupIndicator,
.last-field .MuiAutocomplete-endAdornment .MuiAutocomplete-popupIndicator, .town-city .MuiAutocomplete-endAdornment .MuiAutocomplete-popupIndicator{
    display: none;
}
.newsletters label{
    font-size: 13px !important;
}
.newsletters div.ctct-form-embed form.ctct-form-custom input.ctct-form-element{
    height: auto !important;
    padding: 5px 15px;
}
.newsletters div.ctct-form-embed form.ctct-form-custom div.ctct-form-field{
    width: 30%;
    float: left;
    margin-right: 20px;
}
.newsletters #gdpr_text{
    clear: both;
}
.newsletters .ctct-form-button{
    display: table !important;
    width: auto !important;
    margin: 0 auto !important;
    padding: 12px 20px !important;
}
.newsletters div.ctct-form-embed form.ctct-form-custom .ctct-form-errorMessage{
    font-size: 12px;
    position: absolute;
    margin-top: 35px;
}
.newsletters .ctct-form-footer{
    display: none;
}
.homepage .content-property h5,
.propertyview-hotlist .content-property h5{
    width: 100%;
}

/*news and events */
.newsandevents-page {
    padding: 25px 0px;
    min-height: calc(100vh - 400px);
    background-color: #ffffff;
}
.newsandevents-page .container, .newsandevents-page .col-sm-12 {
    padding: 0px;
}
.lead-story .carousel-status {
    display: none;
}
.lead-ad {
    flex: 0 0 32.5%;
    max-width: 32.5%;
    margin-left: 5px;
}
.lead-story .lead-story-size {
    width: 895px;
    height: 322px;
}

.lead-ad .lead-ad-size {
    width: 372px;
    height: 310px;
    border: 1px solid #dddddd;
}
.lead-story .carousel .slider-wrapper, .lead-story .carousel .slider-wrapper.axis-horizontal .slider .slide, .lead-story .carousel .slider-wrapper.axis-horizontal .slider .slide img {
    height: 310px;
}
.lead-story .carousel .slider-wrapper.axis-horizontal .slider .slide img {
    object-fit: cover;
    opacity: 0.9;
}
.lead-story .carousel .slider-wrapper.axis-horizontal .slider .slide div.slider-img {
    background: rgb(0 0 0 / 48%);
    overflow: hidden;
}
.slider-img p.para-height51 p {
    background-color: transparent!important;
}
.about-invest.container
{
padding-bottom: 10px !important;
}
.lead-story .carousel .slider-wrapper.axis-horizontal .slider .slide.selected {
    padding: 0px;
    font-size: 12px;
    background-color: #ffffff;
    border: none;
    border-radius: 3px;
    margin: 0px;
}
.lead-story .carousel:hover .slide .legend, .lead-story .carousel .slide .legend {
    opacity: 1;
}
.lead-story .carousel .slide .legend {
    border-radius: 3px;
    background: rgb(253 252 252 / 92%);
    padding: 25px;
    bottom: 40px;
    position: absolute;
    color: #030303;
    box-shadow: 0px 0px 3px 0px #9c9797;
}
.lead-story .carousel .control-dots {
    display: none;
}
.lead-story .carousel .slide .legend h4 {
    font-size:28px;
    font-weight: 600;
    color: #565656;
    margin-bottom: 13px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.lead-story .carousel .slide .legend p {
    text-align: left;
    font-size: 12px!important;
    font-family: 'Lato', sans-serif!important;
}
.lead-story .carousel .slide .legend p.margin-with-content {
    margin-bottom: 0px;
}
.lead-story .carousel .slide .legend p span {
    color: #97CB5F;
    font-weight: 500;
}
.lead-story .carousel .slide .legend p span svg {
    margin-right: 5px;
}
.lead-story .carousel .slide .legend p span.lead-viwers {
    margin-left: 15px;
}
/*-- static ads --*/
.ads-overlay {
    background: linear-gradient( to right, rgb(254 255 255 / 0%), rgb(254 255 255 / 0%)), url("../src/Assets/Img/ESS 524x354.jpg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    color: #fff;
    text-align: center;
}
.overlay-ads {
    position: relative;
}
.overlay {
    position: absolute; 
    bottom: 0; 
    background: rgba(0, 0, 0, 0.5); /* Black see-through */
    color: #f1f1f1; 
    width: 100%;
    font-size: 20px;
    padding: 4px 10px 4px;
    text-align: left;

  }
  .overlay1  {
    position: absolute;
    top: 0;
    width: 100%;
    width: 100%!important;
    height: 134px!important;
    border: 2px solid #dddddd;
    cursor: pointer;
    color: #f1f1f1;
  }
.overlay-ads h6 {
    font-size: 14px;
    margin-bottom: 4px;
    font-weight: bolder;
}
.overlay-ads p {
    font-size:13px;
    margin-bottom:0px;
}
/*-- dynamic story --*/
.overlay-story {
    position: relative;
}
.lead-story .overlay-story .center-box {
    display:flex;
    justify-content:center!important;
    align-items:center!important;
}
.lead-story .overlay-story .center-box .story-slider {
    background: #fdfcfcbf;
    padding: 25px;
    box-shadow: 0px 0px 3px 0px #9c9797;
    border-radius: 2px;
    position: absolute;
    top: 40px;
    justify-content:center!important;
    width: 801px;
    height: 248px;
}
.overlay-story .center-box .story-slider h4 {
    font-size:30px;
    font-weight: 600;
    color: #030303;
    margin-bottom: 15px;
    text-transform: uppercase;
}
.overlay-story .center-box .story-slider p span {
    color: #262262;
    font-weight: 500;
}
.overlay-story .center-box .story-slider p span svg {
    margin-right: 5px;
}
.overlay-story .center-box .story-slider p span.lead-viwers {
    margin-left: 15px;
}
.lead-story .category-name {
    width: max-content;
    padding: 2px 5px;
    font-size: 12px;
    border-radius: 3px;
    font-weight: 400;
    margin-right: 5px;
    margin-bottom: 14px;
}
.display-fullflex {
    display: flex;
}
.overlay-story .center-box .story-slider p.margin-with-content {
    margin-bottom: 0px;
    position: absolute;
    bottom: 25px;
}
/*-- news page --*/
.section-newsevents {
    background-color:#ffffff;
}
.section-newsevents .container {
    padding: 10px 0px 82px;
    display: block;
    background-color:#ffffff;
}
.page-header h3, .page-header h2 {
    text-align: center;
    font-size: 24.7px;
    color: #565656;
    text-transform: uppercase;
}
/*-- search box --*/
.search-box {
    display:flex;
    justify-content:center!important;
    align-items:center!important;
    margin-top:28px;
    margin-bottom: 10px;   
    background: #1e5699;
    padding: 25px;
    border-radius: 3px;
    background: linear-gradient( to right, rgb(41 146 185 / 78%), rgb(41 146 185 / 78%)), url("../src/Assets/Img/lss-banner-min.png");
    background-repeat: no-repeat;
}
.search-box form {
    display: flex;
    align-items: baseline;
    width: 100%;
    justify-content: center;
}
.search-box form .form-group {
    margin-bottom: 0px;
}
.search-box form input {
    width: 760px;
    height: 45.4px;
    border-radius: 1px!important;
}
.search-box form input.form-control {
    display: initial!important;
}
.search-box select {
    border-radius: 0px;
    background-color: #ebebeb;
    border-color: #ebebeb;
    cursor: pointer;
    height: 43.4px;
    width: 185px;
}
.search-box form input:focus, .search-box form input:hover, .search-box form input:active, .search-box select:focus, .search-box select:hover, .search-box select:active {
    box-shadow: none!important;
    outline: 0;
    border: 1px solid #EF5823;
}
.search-box select:focus, .search-box select:hover, .search-box select:active {
    background-color: #ebebeb;
}
.search-box button {
    border-radius: 2px;
    padding: 7.17px 35px 7px;
    background-color: #EF5823;
    border-color: #EF5823;
    color: #ffffff;
    font-weight: 500;
    height: 45.29px;
    cursor: pointer;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}
.search-box form input.inputbox-search-empty::placeholder {
    color: #a84e4c;
}
.search-box button svg {
    width: 13px!important;
    margin: 2px 3px -1px 0px;
}
.search-box button:focus, .search-box button:active, .search-box button:hover {
    box-shadow: none!important;
    outline: 0;
    color: #fff;
    background-color: #EF5823!important;
    border-color: #EF5823!important;
}
/*-- page best practics --*/
.bestpractises h5 {
    text-align: center;
    font-size: 22px;
    color: #58585a;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 18px;
    font-family: 'Lato', sans-serif!important;
}
.eventscontent .news-box {
    border: 2px solid #dddddd;
    margin-bottom: 10px;
    padding: 7px 7px 4px;
    border-radius: 2px;
}
.eventscontent .news-box:hover {
    background-color: rgb(245 245 245 / 52%);
    box-shadow: 0px 1px 4px 0px rgb(239 239 239);
    cursor: pointer!important;
}
.eventscontent .news-box h4 {
    color: #555759;
    font-size: 14.95px;
    margin-bottom: 5px;
    margin-top: 8px;
    font-weight: 700;
    font-family:'Roboto'!important;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.eventscontent .news-box p {
    font-size: 13px;
    color: rgba(0,0,0,.68);
    margin-bottom: 4px;
    word-break: break-word;
}
.eventscontent a:focus, .eventscontent a:active, .eventscontent a:hover {
    text-decoration: none;
    color: #58585a;
    cursor: pointer;
}
.news-section-r-border {
    border-right: 3px solid #dddddd;
}
.eventscontent .news-box .row.month-date {
    color: rgba(0,0,0,.4);
    font-size: 13px;
    font-weight: 500;
    margin-top: 5px;
    margin-bottom: 2px!important;
}
.eventscontent .news-box .month-date .col-sm-6:first-child {
   text-align: left;
}
.eventscontent .news-box .month-date .col-sm-6:last-child {
   text-align: left;
}
.month-date svg {
    color: #97CB5F!important;
    font-size: 12px;
    margin-right: 3px;
    margin-bottom: 1px;
}
/*-- ad  best-pratises --*/
.best-pratises-ad {
    flex: auto!important;
    max-width: max-content!important;
    margin-left: 0px!important;
}
.best-pratises-ad .lead-ad-size {
    width: 360px!important;
    height: 134px!important;
    margin-bottom: 10px;
}
.best-pratises-ad p {
    color: #ffffff!important;
}
.best-pratises-ad .ads-overlay {
    background: linear-gradient( to right, rgb(254 255 255 / 0%), rgb(254 255 255 / 0%))!important;
}
.best-pratises-ad .overlay {
    height: 46px;
}
/*-- ad2  best-pratises --*/
.best-pratises-ad2 {
    flex: auto!important;
    max-width: max-content!important;
    margin-left: 0px!important;
}
.best-pratises-ad2 .lead-ad-size {
    width: 360px!important;
    height: 134px!important;
    margin-bottom: 10px;
}
.best-pratises-ad2 p {
    color: #ffffff!important;
}
.best-pratises-ad2 .ads-overlay {
    background: linear-gradient( to right, rgb(254 255 255 / 0%), rgb(254 255 255 / 0%))!important;
}
.best-pratises-ad2 .overlay {
    height: 46px
}
.best-pratises-ad2 .overlay1 {
    right: 0px!important;
}
/*-- ad3  best-pratises --*/
.best-pratises-ad3 {
    flex: auto!important;
    max-width: max-content!important;
    margin-left: 0px!important;
}
.best-pratises-ad3 .lead-ad-size {
    width: 360px!important;
    height: 134px!important;
    margin-bottom: 10px;
}
.best-pratises-ad3 p {
    color: #ffffff!important;
}
.best-pratises-ad3 .ads-overlay {
    background: linear-gradient( to right, rgb(254 255 255 / 0%), rgb(254 255 255 / 0%))!important; 
}
.best-pratises-ad3 .overlay {
    height: 46px;
}
/*--- ad newsevents-ad --*/
.newsevents-ad {
    flex: auto!important;
    max-width: max-content!important;
    margin-left: 0px!important;
   
}
.newsevents-ad .lead-ad-size {
    width: 360px!important;
    height: 134px!important;
}
.newsevents-ad p {
    color: #ffffff!important;
}
.newsevents-ad .ads-overlay {
    background: linear-gradient( to right, rgb(254 255 255 / 0%), rgb(254 255 255 / 0%))!important; 
}
.newsevents-ad .overlay1 {
    position: absolute!important;
    left: 0px!important;
    right: auto;
}
.newsevents-ad .overlay {
    height: 46px;
}
/*-- retransaction-ad --*/
.retransaction-ad {
    flex: auto!important;
    max-width: max-content!important;
    margin-left: 0px!important;
}
.retransaction-ad .lead-ad-size {
    width: 360px!important;
    height: 134px!important;
}
.retransaction-ad p {
    color: #ffffff!important;
}
.retransaction-ad .ads-overlay {
    background: linear-gradient( to right, rgb(254 255 255 / 0%), rgb(254 255 255 / 0%))!important;
}
.retransaction-ad .overlay {
    height: 46px;
}
p.para-height51 {
    overflow: hidden;
    margin-bottom: 17px;
    font-size: 14px;
}
p.para-height51 p span {
    color: #565656!important;
    font-weight: 500;
    background: transparent!important;
}
p.para-height51 p strong {
    background: transparent!important;
}
.search-box .select-property-search {
    position: relative;
    top: -2px;
}
.search-box .select-property-search .dropdown-toggle::after {
    position: relative;
    right: -9px;
    top: 3px;
}
.search-box .select-property-search button:focus, .search-box .select-property-search button:active, .search-box .select-property-search button:hover {
    box-shadow: none!important;
    outline: 0;
    color: #fff;
    background-color:#ffffff!important;
    border-color: #ffffff!important;
}
.eventscontent .news-box p {
    background-color: transparent!important;
    font-family: 'Lato', sans-serif!important;
    font-size: 13px!important;
}
/*-- media query --*/
@media only screen and (min-width: 280px) and (max-width: 479px) {
    .section-newsevents container-fluid {
        padding: 10px 15px;
    }
    .search-box form input {
        width: 100%!important;
    }
    .search-box form {
        display: block;
    }
    .search-box form .select-property-search button.dropdown-toggle {
        width: 100%;
    }
    .search-box button.btn-secondary {
        width: 100%;
        text-align: center;
        display: inline-block;
    }
    .eventscontent.row, .section-newsevents .row {
        width: 100%;
        margin: auto;
    }

    .best-pratises-ad3 .overlay, .best-pratises-ad2 .overlay, .best-pratises-ad .overlay, .newsevents-ad .overlay, .retransaction-ad .overlay {
        height:61px;
    }
    .month-date .col-sm-6 {
        flex: 0 0 100%!important;
        max-width: 100%!important;
    }
    .news-para-height {
        height: 37px;
    }
   
    .newsandevents-page {
        padding: 25px 15px!important;
    }
    .overlay-story {
        margin-bottom: 15px;
    }
    .search-box button {
        display: flex;
        padding: 5px 6px;
    }
    .news-section-r-border {
        border-bottom: 2px solid #dddddd;
        border-right:none;
    }
    .lead-ad {
        flex: 0 0 100%!important;
        max-width: 100%!important;
        margin-bottom: 10px!important;
        margin-left: 0px!important;
    }
    .lead-ad .lead-ad-size {
        width: 100%;
    }
    .best-pratises-ad2 .lead-ad-size, .best-pratises-ad3 .lead-ad-size, .lead-ad .lead-ad-size {
        width: 100%!important;
    }
    .bestpractises h5 {
        margin-top: 10px;
    }
    .lead-story {
        flex: 0 0 100%;
        max-width: 100%;
        margin-right: 0px;
    }
    .lead-story .carousel .slide .legend {
        padding: 15px;
        bottom: 40px;
    }
 }
 @media only screen and (min-width: 375px) and (max-width: 380px) {
    .best-pratises-ad3 .overlay, .best-pratises-ad2 .overlay, .best-pratises-ad .overlay, .newsevents-ad .overlay, .retransaction-ad .overlay {
        height:57px!important;
    }
 }
 @media only screen and (min-width: 320px) and (max-width: 330px) {
    .best-pratises-ad3 .overlay, .best-pratises-ad2 .overlay, .best-pratises-ad .overlay, .newsevents-ad .overlay, .retransaction-ad .overlay {
        height:70px!important;
    }
 }
 @media only screen and (min-width: 411px) and (max-width: 420px) {
    .best-pratises-ad3 .overlay, .best-pratises-ad2 .overlay, .best-pratises-ad .overlay, .newsevents-ad .overlay, .retransaction-ad .overlay {
        height:48px!important;
    }
 }
 @media only screen and (min-width: 480px) and (max-width: 767px){
    .newsandevents-page {
        padding: 25px 25px!important;
    }
    .search-box form input {
        width: 100%;
        margin-top: 5px;
    }
    .search-box button.btn-secondary {
        width: 100%;
        text-align: center;
        display: inline-block;
        margin-top: 5px;
    }
    .search-box button {
        display: flex;
        padding: 5px 6px;
    }
    .news-section-r-border {
        border-bottom: 2px solid #dddddd;
    }
     .lead-story {
        flex: 0 0 100%;
        max-width: 100%;
        margin-right: 0px;
    }
    .lead-ad {
        flex: 0 0 100%;
        max-width: 100%;
        margin-left: 0px;
        margin-top: 15px;
    }
    .lead-ad .lead-ad-size {
        width: 100%;
        height: 310px;
    }
    .search-box form {
        display: block!important;
    }
    .select-property-search .dropdown button {
        width: 100%;
    }
    .best-pratises-ad2 .lead-ad-size {
        width: 100%!important;
        height: 129px!important;
        margin-bottom: 10px;
    }
    .best-pratises-ad3 .lead-ad-size {
        width: 100%!important;
        height:129px!important;
        margin-bottom: 10px;
    }
    .best-pratises-ad .lead-ad-size {
        width: 100%!important;
        height: 129px!important;
        margin-bottom: 10px;
    }
    .newsevents-ad .lead-ad-size {
        width: 100%!important;
        height: 129px!important;
    }
    .retransaction-ad .lead-ad-size {
        width: 100%!important;
        height: 129px!important;
    }
    .best-pratises-ad2, .best-pratises-ad , .newsevents-ad, .retransaction-ad, .best-pratises-ad3 {
        flex: 0 0 100%!important;
        max-width: 100%!important;
        margin-left: 0px!important;
    }
    .overlay1 img {
        width: 100%;
        height: 100%;
    }
    .best-pratises-ad3 .overlay, .best-pratises-ad2 .overlay, .best-pratises-ad .overlay, .newsevents-ad .overlay, .retransaction-ad .overlay {
        height: 90px;
    }
 }
 @media only screen and (min-width: 480px) and (max-width: 500px){
    .best-pratises-ad2 .lead-ad-size {
        width:100%!important;
        height: 195px!important;
        margin-bottom: 10px;
    }
    .best-pratises-ad3 .lead-ad-size {
        width: 100%!important;
        height: 195px!important;
        margin-bottom: 10px;
    }
    .best-pratises-ad .lead-ad-size {
        width: 100%!important;
        height: 195px!important;
        margin-bottom: 10px;
    }
    .newsevents-ad .lead-ad-size {
        width: 100%!important;
        height: 195px!important;
    }
    .retransaction-ad .lead-ad-size {
        width: 100%!important;
        height: 195px!important;
    }
    .best-pratises-ad3 .overlay, .best-pratises-ad2 .overlay, .best-pratises-ad .overlay, .newsevents-ad .overlay, .retransaction-ad .overlay {
        height: 44%!important;
    }
 }
 @media only screen and (min-width: 501px) and (max-width: 550px){
    .best-pratises-ad2 .lead-ad-size {
        width: 100%!important;
        height: 209px!important;
        margin-bottom: 10px;
    }
    .best-pratises-ad3 .lead-ad-size {
        width: 100%!important;
        height: 209px!important;
        margin-bottom: 10px;
    }
    .best-pratises-ad .lead-ad-size {
        width: 100%!important;
        height: 209px!important;
        margin-bottom: 10px;
    }
    .newsevents-ad .lead-ad-size {
        width: 100%!important;
        height: 209px!important;
    }
    .retransaction-ad .lead-ad-size {
        width: 100%!important;
        height: 209px!important;
    }
    .best-pratises-ad2, .best-pratises-ad , .newsevents-ad, .retransaction-ad, .best-pratises-ad3 {
        flex: 0 0 100%!important;
        max-width: 100%!important;
        margin-left: 0px!important;
    }
 }
 @media only screen and (min-width: 551px) and (max-width: 600px){
.overlay1 img {
    width: 100%;
    height: 100%;
}
.best-pratises-ad2 .lead-ad-size {
    width: 100%!important;
    height: 168px!important;
    margin-bottom: 10px;
}
.best-pratises-ad3 .lead-ad-size {
    width: 100%!important;
    height: 168px!important;
    margin-bottom: 10px;
}
.best-pratises-ad .lead-ad-size {
    width: 100%!important;
    height: 168px!important;
    margin-bottom: 10px;
}
.newsevents-ad .lead-ad-size {
    width: 100%!important;
    height: 168px!important;
}
.retransaction-ad .lead-ad-size {
    width: 100%!important;
    height: 168px!important;
}
.best-pratises-ad3 .overlay, .best-pratises-ad2 .overlay, .best-pratises-ad .overlay, .newsevents-ad .overlay, .retransaction-ad .overlay {
    height: 46px!important;
}
.best-pratises-ad2, .best-pratises-ad , .newsevents-ad, .retransaction-ad, .best-pratises-ad3 {
    flex: 0 0 100%!important;
    max-width: 100%!important;
    margin-left: 0px!important;
}
 }
 @media only screen and (min-width: 600px) and (max-width: 767px){
    .eventscontent .news-box {
        padding: 7px 5px 4px!important;
    }
    .eventscontent .news-box .row.month-date {
        width: 100%;
        margin: -13px auto;
    }
    .eventscontent .news-box .month-date .col-sm-6:first-child,.eventscontent .news-box .month-date .col-sm-6:last-child {
        padding: 0px!important;
    }
    .eventscontent .news-box .month-date .col-sm-6:last-child svg {
        display: none;
    }
 }
 @media only screen and (min-width: 768px) and (max-width: 990px){    
    .lead-ad {
        flex: 0 0 35.5%;
        max-width: 35.5%;
        margin-left: 5px;
    }
    .best-pratises-ad3 .overlay, .best-pratises-ad2 .overlay, .best-pratises-ad .overlay, .newsevents-ad .overlay, .retransaction-ad .overlay {
        height: 80px;
    }
    .lead-ad .lead-ad-size {
        width: 100%;
        height: 310px;
    }
    .search-box form input {
        width: 346px;
        height: 44.4px;
    }
    .best-pratises-ad2 .lead-ad-size {
        width: 220px!important;
    }
    .best-pratises-ad2 .overlay1 {
        right: 0px!important;
        width: 100%;
    }
    .best-pratises-ad2 p {
        height: 57px;
        overflow: hidden;
    }
    .best-pratises-ad2 .overlay1 img {
        width: 100%;
    }
    .best-pratises-ad3 .lead-ad-size {
        width: 220px!important;
    }
    .best-pratises-ad3 .overlay1 {
        right: 0px!important;
        width: 100%;
    }
    .best-pratises-ad3 p {
        height: 57px;
        overflow: hidden;
    }
    .eventscontent .news-box .month-date .col-sm-6:first-child {
        padding-right: 0px!important;
    }
    .eventscontent .news-box .month-date .col-sm-6:last-child {
        padding-left: 0px!important;
    }
    .best-pratises-ad3 .overlay1 img {
        width: 100%;
    }
    .best-pratises-ad .lead-ad-size {
        width: 220px!important;
    }
    .best-pratises-ad .overlay1 {
        width: 100%;
    }
    .best-pratises-ad .overlay1 img {
        width: 100%;
    }
    .best-pratises-ad p {
        height: 57px;
        overflow: hidden;
    }
    .best-pratises-ad {
        height: 263px;
    }
    .retransaction-ad .lead-ad-size {
        width: 220px!important;
    }
    .newsevents-ad .lead-ad-size {
        width: 220px!important;
    }
    .newsevents-ad .overlay1 {
        width: 100%!important;
    }
    .newsevents-ad .overlay1 img {
        width: 100%;
    }
    .retransaction-ad p {
        height: 52px;
        overflow: hidden;
    }
    .section-newsevents .container {
        padding: 10px 0px 30px;
    }
 }
 @media only screen and (min-width: 991px) and (max-width: 996px){
    .search-box form input {
        width: 351px!important;
    }
    .lead-ad {
        flex: 0 0 35.5%;
        max-width: 35.5%;
        margin-left: 5px;
    }
    .lead-ad .lead-ad-size {
        width: 100%;
        height: 310px;
    }
    .best-pratises-ad2 .lead-ad-size {
        width: 299px!important;
    }
    .best-pratises-ad2 .overlay1 {
        right: 0px!important;
        width: 100%;
    }
    .best-pratises-ad3 .overlay, .best-pratises-ad2 .overlay, .best-pratises-ad .overlay, .newsevents-ad .overlay, .retransaction-ad .overlay {
        height: 61px;
    }
    .best-pratises-ad2 p {
        height: 57px;
        overflow: hidden;
    }
    .best-pratises-ad2 .overlay1 img {
        width: 100%;
    }
    .best-pratises-ad3 .lead-ad-size {
        width: 299px!important;
    }
    .best-pratises-ad3 .overlay1 {
        right: 0px!important;
        width: 100%;
    }
    .best-pratises-ad3 p {
        height: 57px;
        overflow: hidden;
    }
    .eventscontent .news-box .month-date .col-sm-6:first-child {
        padding-right: 0px!important;
    }
    .eventscontent .news-box .month-date .col-sm-6:last-child {
        padding-left: 0px!important;
    }
    .best-pratises-ad3 .overlay1 img {
        width: 100%;
    }
    .best-pratises-ad .lead-ad-size {
        width: 299px!important;
    }
    .best-pratises-ad .overlay1 {
        width: 100%;
    }
    .best-pratises-ad .overlay1 img {
        width: 100%;
    }
    .best-pratises-ad p {
        height: 57px;
        overflow: hidden;
    }
    .retransaction-ad .lead-ad-size {
        width: 299px!important;
    }
    .newsevents-ad .lead-ad-size {
        width: 299px!important;
    }
    .newsevents-ad .overlay1 {
        width: 100%!important;
    }
    .newsevents-ad .overlay1 img {
        width: 100%;
    }
    .retransaction-ad p {
        height: 52px;
        overflow: hidden;
    }
    .section-newsevents .container {
        padding: 10px 0px 30px;
    }
 }
 @media only screen and (min-width: 997px) and (max-width: 1050px){
    .lead-ad .lead-ad-size {
        width: 311px;
        height: 310px;
    }
    .search-box form input {
        width: 586px;
    }
    .best-pratises-ad2 .lead-ad-size {
        width: 299px!important;
        margin-bottom: 10px;
    }
    .best-pratises-ad3 .lead-ad-size {
        width: 299px!important;
        margin-bottom: 10px;
    }
    .best-pratises-ad .lead-ad-size {
        width: 299px!important;
        margin-bottom: 10px;
    }
    .eventscontent .news-box .month-date .col-sm-6:last-child {
        padding-left: 0px!important;
    }
    .newsevents-ad .lead-ad-size {
        width: 299px!important;
    }
    .retransaction-ad .lead-ad-size {
        width: 299px!important;
    }
    .best-pratises-ad3 .overlay, .best-pratises-ad2 .overlay, .best-pratises-ad .overlay, .newsevents-ad .overlay, .retransaction-ad .overlay {
        height: 61px;
    }
 } 
 @media only screen and (min-width: 1051px) and (max-width: 1100px){
    .lead-ad {
        flex: 0 0 32%;
        max-width: 32%;
        margin-left: 5px;
    }
    .lead-ad .lead-ad-size {
        width: 100%;
        height: 310px;
    }
    .search-box form input {
        width: 584px;
        height: 45.4px;
        border-radius: 1px!important;
    }
    .best-pratises-ad2 .lead-ad-size {
        width: 299px!important;
        margin-bottom: 10px;
    }
    .best-pratises-ad3 .lead-ad-size {
        width: 299px!important;
        margin-bottom: 10px;
    }
    .overlay-ads p {
        font-size: 11px;
        margin-bottom: 0px;
    }
    .best-pratises-ad .lead-ad-size {
        width: 300px!important;
        margin-bottom: 10px;
    }
    .retransaction-ad .lead-ad-size {
        width: 300px!important;
    }
    .best-pratises-ad3 .overlay, .best-pratises-ad2 .overlay, .best-pratises-ad .overlay, .newsevents-ad .overlay, .retransaction-ad .overlay {
        height: 61px;
    }
    .eventscontent .news-box .month-date .col-sm-6:last-child {
        padding-left: 0px!important;
    }
    .newsevents-ad .lead-ad-size {
        width: 300px!important;
    }
 }
 @media only screen and (min-width: 1100px) and (max-width: 1136px){
    .lead-ad {
        flex: 0 0 32%;
        max-width: 32%;
        margin-left: 5px;
    }
    .lead-ad .lead-ad-size {
        width: 100%;
        height: 310px;
    }
    .search-box form input {
        width: 584px;
        height: 45.4px;
        border-radius: 1px!important;
    }
    .best-pratises-ad2 .lead-ad-size {
        width: 299px!important;
        margin-bottom: 10px;
    }
    .best-pratises-ad3 .lead-ad-size {
        width: 299px!important;
        margin-bottom: 10px;
    }
    .overlay-ads p {
        font-size: 11px;
        margin-bottom: 0px;
    }
    .best-pratises-ad .lead-ad-size {
        width: 300px!important;
        margin-bottom: 10px;
    }
    .retransaction-ad .lead-ad-size {
        width: 300px!important;
    }
    .newsevents-ad .lead-ad-size {
        width: 300px!important;
    }
    .best-pratises-ad3 .overlay, .best-pratises-ad2 .overlay, .best-pratises-ad .overlay, .newsevents-ad .overlay, .retransaction-ad .overlay {
        height: 61px;
    }
 }
 @media only screen and (min-width: 1500px) and (max-width: 1550px){
    .overlay-ads p {
        font-size: 12px;
    }
 }
 @media only screen and (min-width: 1190px) and (max-width: 1199px){
    .lead-ad {
        flex: 0 0 32%;
        max-width: 32%;
        margin-left: 5px;
    }
    .lead-ad .lead-ad-size {
        width: 100%;
        height: 310px;
    }
    .search-box form input {
        width: 584px;
        height: 45.4px;
        border-radius: 1px!important;
    }
    .best-pratises-ad2 .lead-ad-size {
        width: 299px!important;
        margin-bottom: 10px;
    }
    .best-pratises-ad3 .lead-ad-size {
        width: 299px!important;
        margin-bottom: 10px;
    }
    .overlay-ads p {
        font-size: 11px;
        margin-bottom: 0px;
    }
    .best-pratises-ad .lead-ad-size {
        width: 300px!important;
        margin-bottom: 10px;
    }
    .retransaction-ad .lead-ad-size {
        width: 300px!important;
    }
    .newsevents-ad .lead-ad-size {
        width: 300px!important;
    }
    .best-pratises-ad3 .overlay, .best-pratises-ad2 .overlay, .best-pratises-ad .overlay, .newsevents-ad .overlay, .retransaction-ad .overlay {
        height: 61px;
    }
 }
 /*-- Fliter --*/
.filters-search form .col-sm-5 .row.marign-auto {
    margin-left: auto;
    margin-right: auto;
    margin-top: 7px;
}
.filters-search form .form-group .form-control {
    height: calc(1.5em + .52rem + 2px);
    padding: 5px;
    font-size: 14px;
    background-color: #faf9f9;
    border: 1px solid #cacaca;
}
.filters-search form .form-group .form-control:focus {
    box-shadow: none;
    border: 1px solid #97CB5F;
}
.filters-search form .col-sm-5 .row.marign-auto .form-check-input {
    position: absolute;
    margin-top: .2rem;
    margin-left: -1.1rem;
}
.filters-search form .col-sm-5 .row.marign-auto span {
    margin-right: 15px;
}
.filters-search form .col-sm-5 .row.marign-auto .is-touched {
    color:#99c753 ;
    background-color: #99c753;
}
button.search-btn {
    background-color: #97CB5F;
    padding: 5px 7px;
    border-radius: 2px;
    color: #ffffff;
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
}
/*-- total count --*/
.total-numb p {
    text-align: left;
    font-size: 13px;
    font-weight: 500;
    color: #000000;
}
/*-- table --*/
.table-model table  tr {
    border: 1px solid #ddd;
}
.table-model table thead tr th {
    background-color: #979797;
    padding: 5px 10px;
    color: #fff;
    font-weight: 500;
    font-size:13px
}
.table-model table tbody tr td {
    padding: 6px 10px!important;
    font-size: 13px;
    line-height: initial;
}
.table-model table tbody tr td:last-child, .table-model table thead tr th:last-child  {
    border-right: 1px solid #dddddd;
}
.table-model table tbody tr:nth-child(odd) {
    background: #ececec;
}
.table-model table tbody tr:nth-child(even) {
    background: #ffffff;
}
.table-model tfoot.MuiTableFooter-root button.MuiIconButton-root:focus {
   outline: none!important;
}
.table-model tbody tr td img {
    margin-left: 3px;
    margin-right: 2px;
}
/*-- table width --*/
.newsandevents-table table thead tr th.date-width {
    width: 120px;
}
.newsandevents-table table thead tr th.artical-width {
    width: 380px;
}
.newsandevents-table table tbody tr td p {
    margin-bottom: 2px;
}
/*-- slide Button --*/
.active-in.slide-button {
    margin: 0px 4px!important;
    vertical-align: middle!important;
}
.slide-button .react-switch-bg {
    height: 20px!important;
    width: 51px!important;
    background: #EF5823!important;
    border-radius: 3px!important;
}
@-moz-document url-prefix() {
    .slide-button .react-switch-bg {
        height: 20.59px!important;
        width: 52px!important;
        border-radius: 3px!important;
    }
}
.slide-button .react-switch-bg div {
    height: 22px!important;
    width: 28px!important;
}
.slide-button .react-switch-handle {
    height: 18px!important;
    width: 21px!important;
    border: 1px solid #dddddd;
    border-radius: 2px!important;
    top: 1px!important;
}
.slide-button .react-switch-handle::before {
    font-weight: bold;
    color: #9d9d9d;
    margin: -2.2px 5px;
    content: "|||";
    display: block;
    padding: 2.69px 1px;
  }
  .property-details .fixed-legend{
    margin-left: 15px;
    box-shadow: 0 0 2px 0px #8c8c8c;
  }
  .property-details .fixed-legend .set-miles{
      color: #000;
  }
  .property-details .fixed-legend .map-legends span.store-label{
    background: #107ec8;
  }
  .co-broker .slide-button .react-switch-handle::before{
    margin: -2.2px 3px -2.2px 5px;
  }
  .popup-overflow.modal-body.overflow-scroll{
    max-height: calc(100vh - 160px);
    overflow-y: auto;
  }
  .popup-overflow.modal-body.overflow-scroll .user-management-form .form-group{
      width: 100%;
  }
  .popup-overflow.modal-body.overflow-scroll .user-management-form .form-group .form-control{
    height: calc(1.5em + 0.65rem + 2px)!important;
    padding: 5px;
    border-radius: 2px;
    font-size: 14px;
    background-color: #faf9f9;
    border: 1px solid #cacaca;
    width: 100%;
  }
  .market-data-table tr td, .market-data-table tr th{
    padding: 10px;
    font-size: 13px;
  }
  .market-data-table .market-data-value{
    font-weight: 600;
    border-left: none;
    text-align: right;
  }
  .market-data-table .market-data-label{
    border-right: none;
  }
  .market-explorer-logo{
      margin-top: 15px;
  }
  .market-explorer-logo img{
    width: 130px;
  }
  .market-explorer-logo span{
    margin-left: 15px;
    font-size: 13px;
    position: relative;
    top: 4px;
  }
  .property-address-right{
      font-size: 13px;
  }
  .property-address-right table{
      margin-top: 10px;
      margin-bottom: 25px;
  }
  .property-address-right table td{
      border-top: none;
      padding: 0;
  }
  .property-address-right table td:nth-child(even){
      font-weight: 600;
  }
  .property-address-right .address-show a{
    margin-left: 5px;
    text-decoration: underline !important;
    display: inline-block;
    color: #04D816 !important;
    cursor: pointer;
  }
  body .mobile-contact a{
    padding: 0.375rem 0.75rem;
    color: #fff;
    margin-bottom: 10px;
  }
  .property-address-right .contact-broker, .mobile-contact a{
    outline: 0;
    border: none !important;
    background-color: #97CB5F;
    width: 100%;
    font-size: 12px;    
    box-shadow: none !important;
    color: #fff;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    padding-left: 0;
    padding-right: 0;
    text-decoration: none !important;
    font-family: Lato,Helvetica Neue,Arial,Helvetica,sans-serif!important;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }
  .property-table-layout .col-md-6{
    border: 1px solid #dee2e6;
    border-bottom: none;
  }
  .property-table-layout .col-md-6:last-child{
    border-bottom: 1px solid #dee2e6;
  }
  .property-table-layout{
      font-size: 13px;
      margin-bottom: 20px;
  }
  .property-address-right .property-heading,
  .property-details-right .property-heading,
  .property-taxes-periods .description-heading{
    font-size: 20px;
  }
  .property-address-right table td:nth-child(odd),
  .market-data-table .market-data-label,
  .property-table-layout .col-md-6 .row .col:first-child{
    opacity: 0.7;
  }
  .property-table-layout .col-md-6 .row .col:last-child{
      text-align: right;
      font-weight: 600;
  }
  .property-table-layout .col-md-6 .row .col{
    padding: 8px 8px;
  }
  @media (max-width: 575px){
.demand-cont 
{
    padding-top: 30px;
}
  }
@media (min-width: 768px){
 
  .property-table-layout .col-md-6{
    flex: 0 0 49%;
    max-width: 49%;    
  }
  .property-table-layout .col-md-6:nth-child(odd){
    margin-right: 0.5rem;
  }
  .property-table-layout .col-md-6:nth-last-child(2){
    border-bottom: 1px solid #dee2e6;
  }
}
.property-address-right .property-heading,
.property-taxes-periods .description-heading{
    margin-bottom: 10px;
    border-bottom: 1px solid rgb(225 225 225);
    padding-bottom: 5px;
}
.property-details-right .property-heading{
    margin-bottom: 10px;
}
.property-address-right .updated-col{
    border-left: 1px solid #8d8e8f;
    padding-left: 8px;
}
.mapboxgl-ctrl-logo, .mapboxgl-ctrl-bottom-right {
    display: none !important;
}
.zoom-plus-minus{
    position: absolute;
    right: 10px;
    bottom: 25px;
}
.mapbox-full-zoom {
    position: absolute;
    right: 10px;
    top: 10px;
}
@media (max-width: 768px){
    .popup-login .modal-content
    {
        border: 0px !important; 
        filter: drop-shadow(rgba(0, 0, 0, 0.32) 0px 2px 8px);
    }
    .modal-dialog.popup-login
    {
        top: 224px !important; 
    }
    .popup-login .modal-body::before
    {
        background-color: transparent !important;
    }
}
@media (max-width: 919px){
    body .real-market-data .view-pricing-link{
        margin-left: 0px;
        display: table;
        margin-bottom: 5px;
    }
}
@media (max-width: 794px){
.featured-cont.container {
    padding-bottom: 60px !important;
}
}

@media (min-width: 768px){
    .navbar-expand-md .navbar-collapse {
        padding-top: 10px;
    } 
  
}
@media (max-width: 768px){
.navbar-collapse
{
    background-color: #fff;
    padding: 15px;
    -webkit-border-bottom-right-radius: 4px;
-webkit-border-bottom-left-radius: 4px;
-moz-border-radius-bottomright: 4px;
-moz-border-radius-bottomleft: 4px;
border-bottom-right-radius: 4px;
border-bottom-left-radius: 4px;
}
}
.search-page .search-resultdropdown .dropdown-menu.show{
    max-height: 300px;
    overflow-y: auto;
}
.login-fields-popup .errorMessage{
    margin-top: -1rem;
}

.text-1 {
    font-size:40px;
    line-height: normal;
}
.listrvparks .pag {
    padding: 20px 170px;
    font-size: 14px;
    color: #5A6E82;
}
.listrvparks .color-prg{
    font-size: 14px;
    color: #5A6E82;
}
.listrvparks .left-side-text-instant{
    font-size: 25px;
    font-weight: 500;
    color: #2E3336;
  }
.listrvparks .icon-maindiv{
    display: flex;
    gap: 1rem;
}
.listrvparks .icon-img{
    border: 1px solid #94C75E;
    border-radius: 5px;
    text-align: center;
    padding: 5px;
}
/* .icon-maindiv:hover .icon-img {
    background: #94c75e;
    cursor: pointer;
    color: #EF5C29;
} */

.listrvparks .icon-img img{
    height: 40px;
    padding: 2px;
}
.listrvparks .icon-img-text{
    font-size: 14px;
    font-family: 'Roboto';
    align-content: center;
    line-height: 1.2;
    color: #5A6E82;
}
/* .fluid-col .instant-ama {
    width: calc(50vw - 120px);
    text-align: right;
} */
.instant-ama img {
    width: 100%
}

.listrvparks .two-btn {
    display: flex;
    gap: 2rem;
    margin-top: 65px;
}
.listrvparks .color-white{
    color: #fff;
    text-decoration: none;
}
.listrvparks .two-btn .instant-ama-btn {
    background: #94c75e;
    border: 1px solid #94c75e;
    padding: 12px;
    font-weight: 500;
    width: 40%;
    border-radius: 6px;
    text-align: center;
    text-decoration: none;
    font-size: 1rem;
    cursor: pointer;
}
.listrvparks .two-btn .discoverbtn {
    background: none;
    border: 1px solid #94c75e;
    color: #94c75e;
    border-radius: 6px;
    text-align: center;
    padding: 12px;
    font-weight: 500;
    width: 40%;
    font-size: 1rem;
    cursor: pointer;
    text-decoration: none;
} 
.listrvparks .marketreportnew-section {
    background: #01890a;
    margin-top: 8%;
    margin-bottom: 8%;
    border-radius: 50px;
}
.listrvparks .marketreportnew-content {
    padding: 66px 0px 66px 50px;
    color: #fff;
}
.listrvparks .marketreportnew-content .marketreportnew-content-p {
    font-size: 16px;
    margin-bottom: 10px;
}
.listrvparks .marketreportnew-section .marketreportnew-content .prvmkt-btn {
    padding-top: 30px;
    margin-left: 0px;
}
.listrvparks .marketreportnew-section .reportcover {
    position: absolute;
    margin-top: -85px;
}
.listrvparks .marketreportnew-content h4 {
    font-size: 30px;
    margin-bottom: 25px;
}
.listrvparks .marketreportnew-content .previewmrt {
    border-radius: 6px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    padding: 13px 35px 13px 35px;
    text-align: center;
    margin-left: 0px;
    border: none;
    color: #01890a;
    background: #fff;
    text-decoration: none;
}
.rvparkiq-color{
    color: #94C75E;
    font-weight: 600;
}
@media screen and (max-width:1024px){
    .listrvparks .head-login-btn span{
      display: block;
    }
    }
.listrvparks .homepage .home-content{
    margin-bottom: 45px;
}
.listrvparks .profile-vendordirectory, 
.listrvparks .property-details{
    padding-top: 125px;
}
.listrvparks .alllisting{
    padding-top: 30px;
}
.listrvparks .map-relative{
    padding-top: 25px;
}
.listrvparks .custom-container{
    max-width: 1200px;
    padding-left: 0px;
    padding-right: 0px;
}
.listrvparks .pass-c-signin svg{
    padding-right: 3px;
}