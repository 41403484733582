.childDisable{
    display: none !important;
}
.vendor-categories .modal-content .modal-header h5 {
    color: #262262;
    font-size: 16px;
}
.vendor-categories .modal-content .modal-header {
    padding: 12px 15px;
}
.vendor-categories button.close:focus, .vendor-categories button.close:active, .vendor-categories button.close:hover  {
    border: none;
    outline: 0;
    box-shadow: none;
}
.categories-vendor p {
    padding: 7px 10px;
    color: #565656;
    font-size: 13px;
    margin-bottom: 0px;
}
.categories-vendor p:hover {
    color: #97CB5F;
    cursor: pointer;
}
.categories-vendor p svg {
    margin: 0px 5px ;
}
.vendor-category-two p {
    padding: 5px 10px;
    cursor: pointer;
    font-size: 13px;
    margin: 0px;
    display: flex;
}
.vendor-category-two p input {
    margin: 0px 7px 0px 16px;
    cursor: pointer;
    position: relative;
    top: 2px;
}
.vendor-category-two p.no-data {
    text-align: center;
}
.table-header-blue table thead tr th {
    background-color: #2292b9!important;
}
.scroll-model-table table tbody {
    height: 397px!important;
    overflow-y: auto;
    display: block;
    width: 100%;
}
.scroll-model-table table tbody tr {
    display: block;
    width: 100%;
}
.categories-vendor2 p {
    margin: 7px 10px;
    cursor: pointer;
    display: flex;
}
.categories-vendor2 input {
    margin: 0px 7px 0px 0px;
    cursor: pointer;
    position: relative;
    top: 3px;
}