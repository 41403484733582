.addpadding-property {
    padding: 28px 15px!important;
}
.property-table table thead tr th.property-width {
    width: 140px;
}
.width-135 {
    width: 135px!important;
}
.property-table table thead tr th.property-width-city,
.property-list-table .property-table table tbody tr td.property-width-city {
    width: 75px!important;
}
.property-list-table .property-table table tbody tr td,
.property-list-table .property-table table thead tr th {
    padding: 6px 5px!important;
}
.brokername-break {
    width: 102px;
    word-break: break-all;
}
.icon-table{
    height: 24px;
    width: auto !important;
}