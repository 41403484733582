.childDisable{
    display: none !important;
}
.header-category-box .col-sm-8 {
    display: flex;
}
.header-category-box h6 {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 15px;
    margin-left: 0px;
    color: #97CB5F;
    font-size: 16px;
    font-family: 'Roboto'!important;
}
.header-category-box .row.header-count {
    margin: 0px;
}
.header-category-box .row.header-count h5 {
    font-size: 13px;
    color: #000000;
}
.header-category-box .row.header-count h4 {
    font-size: 18px;
    color: #848484;
    margin-top: 20px;
    margin-bottom: 0px;
}
.header-category-box .row.header-count .counter-box {
    border-left: 1px solid #ddd;
    padding: 0px 10px;
    width: 108px;
}
.last-box-border {
    border-right: 1px solid #ddd;
}
.header-category-box .col-sm-4 {
    margin: auto;
}
.header-category-box .col-sm-4 .add-new-btn {
    float: right;
}
/*-- table --*/
.parent-category table td.first-category {
    cursor: pointer;
}
.parent-category table .first-category p {
    margin-bottom: 0px;
}
.parent-category table .first-category p:hover {
    color: #002d73;
    cursor: pointer;
}
.parent-category table .first-category p svg {
    margin-right: 10px;
}
.parent-category table tbody tr:nth-child(odd) {
    background: #f5f5f5!important;
}
.parent-category table tbody tr:nth-child(odd):hover {
    background: #ececec!important;
}
.parent-category table tbody tr td.first-category-buttons img {
    margin-left: 3px!important;
    margin-right: 20px!important;
}
.parent-category table tbody tr td.second-category p  {
    margin-left: 15px;
    margin-bottom: 8px;
    padding: 7px 5px;
    width: 300px!important;
}
.parent-category table tbody tr td.second-category p.category-new-change
{
    margin-bottom:0px;
}
.category-edit-new
{
    padding: 3px 0px;
}
.parent-category table tbody tr p a {
color: #565656;
display: block;
width: 100%;
}
.parent-category table tbody tr p a:hover {
    color: #2292b9;
    text-decoration: none;
}
.vendor-category-two p:hover {
    background: #ececec!important;
}
/*-- Pop-up-model-smal --*/
.pop-up-model .modal-content .modal-header h5 {
    color: #262262;
    font-size: 16px;
}
.pop-up-model .modal-content .modal-header {
    padding: 12px 15px;
}
.pop-up-model .modal-content .category-form form .row.width-100 .form-group:nth-child(3), .pop-up-model .modal-content .category-form form .row textarea  {
    width: 100%;
}
.pop-up-model .modal-content .category-form .row.widthsamll .form-group {
    width: 100%;
}
.pop-up-model .modal-content .category-form .row .form-group .invalid-feedback {
    margin: auto;
    position: absolute;
}
.pop-up-model .modal-content form label {
    display: block;
    margin: 0 0 .28571429rem;
    color: rgba(0,0,0,.87);
    font-size: .92857143em;
    font-weight: 700;
    text-transform: none;
  }
.pop-up-model .modal-content .category-form form .form-control, .pop-up-model .modal-content .category-form form textarea {
    height: calc(1.5em + .52rem + 2px);
    padding: 5px;
    font-size: 14px;
    background-color: #faf9f9;
    border: 1px solid #cacaca;
}
.pop-up-model .modal-content .category-form form .form-control:focus, .pop-up-model .modal-content .category-form form textarea:focus {
    box-shadow: none;
    border: 1px solid #97CB5F;
}
.pop-up-model .modal-content .category-form form textarea:focus {
    outline: 0px;
}
.pop-up-model .modal-content .category-form form textarea {
    height:calc(4.5em + .52rem + 2px) ;
    width: 100%;
    margin-bottom: 10px;
}
.pop-up-model button.close:focus, .pop-up-model button.close:active, .pop-up-model button.close:hover  {
    border: none;
    outline: 0;
    box-shadow: none;
}
/*-- simple table --*/
table.noborder-table tr td {
    border: none;
    padding: 0px;
}