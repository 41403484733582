.vendor-assign-table .modal-content .modal-header h5 {
    color: #97CB5F;
    font-size: 16px;
}
.vendor-assign-table .modal-content .modal-header {
    padding: 12px 15px;
}
.vendor-assign-table button.close:focus, .vendor-assign-table button.close:active, .vendor-assign-table button.close:hover  {
    border: none;
    outline: 0;
    box-shadow: none;
}
@media (min-width: 576px) {
.vendor-assign-table.modal-dialog {
    max-width: 600px;
    margin: 1.75rem auto;
}
}
.vendor-popup-table thead tr th {
    padding: 4px 9px!important;
}
.vendor-popup-table tbody tr td {
    padding: 6px 6px!important;
}
.vendor-popup-table thead tr th:first-child, .vendor-popup-table tbody tr td:first-child {
    width: 75px;
} 
.vendor-popup-table thead tr th:nth-child(2), .vendor-popup-table tbody tr td:nth-child(2), .vendor-popup-table thead tr th:nth-child(3), .vendor-popup-table tbody tr td:nth-child(3)  {
    width: 130px;
    word-break: break-word;
}
.vendor-popup-table thead tr th:nth-child(2) {
    padding-left: 3px!important;
    padding-right: 4px!important;
}
.vendor-popup-table thead tr th:nth-child(3) {
    padding-left: 0px!important;
}
.vendor-popup-table thead tr th:last-child, .vendor-popup-table tbody tr td:last-child {
    width: 215px;
    word-break: break-word;
}
.selected {
    padding: 3px 9px;
    font-size: 12px;
    background-color: #ffffff;
    border: 1px solid #0d71bb;
    border-radius: 3px;
    margin: 0px;
}